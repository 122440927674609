import React, { useEffect, useState, useRef, useMemo } from "react";
import { Col, Row, Form, FormGroup, Label, Input, Button } from "reactstrap";
import "../common.scss";
import he from "he";
import { stripHtmlTags } from "../utility/commonDecodeHtmlBody";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Field, Formik } from "formik";
import { Card, Select, Skeleton } from "antd";
import moment from "moment";
import {
  createBooking,
  getAllTrustDetail,
  updateBookingApi,
  callDeleteBooking,
  getSevaById,
} from "../utility/commonApi";
import Swal from "sweetalert2";
import backIcon from "../assets/icons/back.svg";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import kalashIcon from "../assets/icons/kalash.png";
import { useSelector } from "react-redux";
import PaymentHelper from "../components/DonationPayment/paymentHelper";
import { Utils } from "../constants";
import { AtomPayment } from "../Helper/AtomPayment";
import apiCaller from "../Helper/WebServices/apiCaller";
import { useTranslation } from "react-i18next";
import { usePayment } from "../components/DonationPayment/paymentContext";
import Payment from "../Helper/WebServices/Payment";
import Meta from "antd/es/card/Meta";
import Wrapper from "../views/common/commonWrapper";
import { toast } from "react-toastify";
import { fetchImage } from "../utility/commonDownloadS3Image";

function SevaBooking(
  {
    // setSevaList,
    // setBookingSeva,
    // selectedSeva,
  }
) {
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();
  const { id } = useParams();
  const { data, loading } = useQuery({
    queryKey: ["serviceById"],
    queryFn: () => getSevaById(id),
    keepPreviousData: true,
  });
  const selectedSevaData = useMemo(() => data?.data?.result ?? null, [data]);
  // const [selectedSevaData, setSelectedSevaData] = useState(null);
  const trustId = localStorage.getItem("trustId");
  const userDetails = useSelector((state) => state.auth.user);
  const queryClient = useQueryClient();
  const paymentWindowRef = useRef(null);
  const timeoutRef = useRef(null);

  const [paymentStatusLoader, setPaymentStatusLoader] = useState(
    Payment.Status.not_init
  );
  const [transactionId, setTransactionId] = useState("");
  const [bookingId, setBookingId] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [allowRetry, setAllowRetry] = useState(false);

  useEffect(() => {
    // setSelectedSevaData(location.state);
    localStorage.setItem("serviceId", location.state?._id);
  }, [location.state]);

  const handleBack = () => {
    navigate(`/seva?page=sevaList`);
    localStorage.removeItem("serviceId");
    // setSevaList(true);
    // setBookingSeva(false);
  };

  const decodedDescription = selectedSevaData
    ? he.decode(selectedSevaData.description)
    : "";

  const description = stripHtmlTags(decodedDescription);

  const [availableDates, setAvailableDates] = useState([]);
  const [selectedDates, setSelectedDates] = useState([]);

  const handleDateChange = (dates) => {
    setSelectedDates(dates);
  };

  useEffect(() => {
    if (selectedSevaData && selectedSevaData.dates) {
      const today = moment().startOf("day"); // Get today's date without time

      const upcomingDates = selectedSevaData.dates
        .filter((date) => moment(date).isSameOrAfter(today)) // Filter upcoming or current dates
        .map((date) => moment(date).format("DD MMM YYYY"));
      setAvailableDates(upcomingDates);
      setSelectedDates(selectedSevaData.dates);
    }
  }, [selectedSevaData]);

  const startTimeout = () => {
    timeoutRef.current = setTimeout(() => {
      handleSessionTimeout();
    }, 900000);
  };

  useEffect(() => {
    if (transactionId) {
      startTimeout();
    }
    return () => clearTimeout(timeoutRef.current);
  }, [transactionId]);

  const handleSessionTimeout = () => {
    console.log("Payment session has timed out.");
    Utils.showErrorToast("Your payment session has expired. Please try again.");
    deleteBooking(bookingId, "");
    setIsLoading(false);
    setAllowRetry(true);
    setPaymentStatusLoader(Payment.Status.not_init);
  };

  function handlePaymentFailure(bookingId, txnId, result) {
    console.log("In handlePaymentFailure:", result);
    setIsLoading(false);
    if (result?.message) {
      Utils.showErrorToast(result?.message);
    }
    if (bookingId) {
      callDeleteBooking(bookingId);
    } else {
      console.error("bookingId is not set, unable to delete booking.");
    }
    if (result?.error) {
      Utils.showSuccessToast(result.error);
    }

    setTimeout(() => {
      console.log("Timeout triggered");
      setIsLoading(false);
      setAllowRetry(true);
      setPaymentStatusLoader(Payment.Status.not_init);
    }, 5000);
  }

  function updateBooking(bookingId, status, pgOrderId, trustId) {
    if (navigator.onLine) {
      setPaymentStatusLoader(Payment.Status.start_payment_api);

      let params = {
        bookingId: bookingId,
        status: status,
        pgOrderId: pgOrderId,
        trustId: trustId,
      };

      console.log("Update Booking Params:", params);

      updateBookingApi(params, async (statusCode, response) => {
        const responseData = response?.data?.data;
        if (statusCode === 200 || statusCode === 201) {
          setPaymentStatusLoader(Payment.Status.completed_payment_api);
          queryClient.invalidateQueries(["Masters"]);
        } else {
          setPaymentStatusLoader(Payment.Status.failed_payment_api);
        }
      });
    } else {
      Utils.showErrorToast(t("noInternetMessage"));
    }
  }

  function deleteBooking(bookingId, trustId) {
    if (navigator.onLine) {
      console.log("Delete Booking:", bookingId);

      callDeleteBooking(bookingId, trustId, async (statusCode, response) => {
        if (statusCode === 200 || statusCode === 201) {
          console.log("Booking deleted successfully:", bookingId);
        } else {
          console.error("Failed to delete booking:", bookingId);
        }
      });
    } else {
      console.error("Could not process booking deletion:", bookingId);
    }
  }

  const { updatePaymentResultCallback } = usePayment();

  useEffect(() => {
    const handlePaymentMessage = (event) => {
      if (event.data.type === "PAYMENT_RESPONSE") {
        const result = event.data.data;
        clearTimeout(timeoutRef.current);

        if (result.ndpsResponse) {
          console.log("Received ndpsResponse:", result.ndpsResponse);
          updateBooking(bookingId, "Processing", "", trustId);
          return;
        }

        if (result.success) {
          console.log("Payment succeeded:", result.message);
          updateBooking(bookingId, "Paid", "", trustId);
          Utils.showSuccessToast(t("api_success_payment_success"));
        } else {
          if (result.message === "Payment has been cancelled by the user!") {
            Utils.showErrorToast("Payment was cancelled");
          } else if (result.message === "Payment session timed out") {
            Utils.showErrorToast("Payment session timed out");
          } else {
            handlePaymentFailure(transactionId, result);
          }
        }

        if (paymentWindowRef.current && !paymentWindowRef.current.closed) {
          paymentWindowRef.current.close();
        }
      }
    };

    window.addEventListener("message", handlePaymentMessage);
    return () => window.removeEventListener("message", handlePaymentMessage);
  }, [transactionId, bookingId, trustId]);

  useEffect(() => {
    return () => {
      localStorage.removeItem("currentPaymentTxnId");
      localStorage.removeItem("currentPaymentBookingId");
    };
  }, []);

  async function initiatePayment(booking, totalAmount) {
    try {
      setPaymentStatusLoader(Payment.Status.creating_payment_order);
      const ndps = new PaymentHelper();

      // Generate transaction ID
      let txnId =
        Date.now().toString(36) + Math.random().toString(36).substr(2, 5);
      setTransactionId(txnId);

      // Set up payment details
      let amount = totalAmount.toString();
      let userEmailId = userDetails?.email;
      let userContactNo = userDetails?.mobileNumber;

      const trustDetails = await getAllTrustDetail({ trustId });
      if (!trustDetails || !trustDetails.data || !trustDetails.data.result) {
        throw new Error("Failed to get trust details");
      }

      const trustData = trustDetails.data.result;

      // Prepare API parameters
      const apiParams = {
        customerId: userDetails?.userId ?? userDetails?.id,
        customerPhone: userContactNo.toString(),
        customerEmail: userEmailId,
        orderAmount: amount,
        orderCurrency: "INR",
        vendorId: trustData?.vendorId,
        orderId: txnId,
        amount: totalAmount,
        trustId: trustId,
        bookingId: booking.bookingId,
        userName: userDetails.name,
        transactionId: txnId,
      };

      const merchantDetails = {
        merchantId: trustData?.vendorId,
        password: trustData?.pgConfig?.password,
        productId: trustData?.pgConfig?.productId,
        req_enc_key: trustData?.pgConfig?.req_enc_key,
        res_enc_key: trustData?.pgConfig?.res_enc_key,
        response_hash_key: trustData?.pgConfig?.response_hash_key,
        custFirstName: trustData?.name?.trim(),
        custEmailId: userEmailId,
        custMobileNumber: userContactNo.toString(),
        txnDate: new Date().toISOString(),
        merchTxnId: txnId,
        amount: amount,
        udf1: "udf1",
        udf2: "udf2",
        udf3: "udf3",
        udf4: "udf4",
        udf5: "udf5",
        paymentMode: "NB",
        mode: "uat",
      };

      console.log("Initiating Atom Payment...");
      setIsLoading(true);
      setPaymentStatusLoader(Payment.Status.creating_payment_order);

      const { atomTokenId } = await AtomPayment.createOrder(apiParams);

      if (atomTokenId) {
        setPaymentStatusLoader(Payment.Status.created_payment_order);

        const htmlPage = ndps.openAipayPopUp(atomTokenId, merchantDetails);

        updatePaymentResultCallback((result) => {
          clearTimeout(timeoutRef.current);

          if (result.success) {
            console.log("Payment succeeded:", result.message);
            updateBooking(booking._id, "Paid", "", trustId);
            Utils.showSuccessToast(t("api_success_payment_success"));
          } else {
            handlePaymentFailure(booking._id, txnId, result);
          }
        });

        // Start payment timeout
        startTimeout();

        // Store necessary data in localStorage for payment page
        localStorage.setItem("currentPaymentTxnId", txnId);
        localStorage.setItem("currentPaymentBookingId", booking._id);

        // Navigate to payment page with only required data
        navigate("/payment", {
          state: {
            htmlPage,
            merchantDetails: {
              ...merchantDetails,
              res_enc_key: merchantDetails.res_enc_key,
              response_hash_key: merchantDetails.response_hash_key,
            },
            origin: "seva",
          },
        });
      } else {
        throw new Error("Failed to get atom token");
      }

      setIsLoading(false);
      setAllowRetry(true);
      setPaymentStatusLoader(Payment.Status.not_init);
    } catch (error) {
      console.error("Failed to create Atom order:", error.message);
      Utils.showErrorToastNoAutoHide("Failed to initiate payment");

      if (transactionId) {
        handlePaymentFailure(transactionId, {
          success: false,
          message: error.message || "Payment initialization failed",
        });
      }

      setIsLoading(false);
      setAllowRetry(true);
      setPaymentStatusLoader(Payment.Status.not_init);
    }
  }

  const CustomDateSelectComponent = ({
    dates,
    value = [],
    onChange,
    availability,
    ...props
  }) => {
    const handleDateChange = (selectedDates) => {
      if (!selectedDates) return;

      let validDates = [];

      selectedDates.forEach((selectedDate) => {
        const selectedAvailability = availability?.find(
          (item) => item.date === selectedDate
        );

        if (selectedAvailability) {
          const { totalCapacity, booked, locked } = selectedAvailability;

          if (totalCapacity > 0 && locked === 1) {
            toast.warning(
              `We are trying to make service available for you, please try after some time. (Please try some other service or wait for the same.)`
            );
          } else {
            validDates.push(selectedDate);
          }
        } else {
          validDates.push(selectedDate); // Allow removing previously selected dates
        }
      });

      onChange(validDates); // Ensure user can remove dates freely
    };

    return (
      <Select
        {...props}
        value={value}
        onChange={handleDateChange}
        mode="multiple"
        placeholder="Select Dates"
        style={{ width: "100%", height: "auto" }}
        allowClear
      >
        {dates
          .filter((date) => {
            const dateAvailability = availability?.find(
              (item) => item.date === moment(date).format("DD MMM YYYY")
            );
            return !(
              dateAvailability?.totalCapacity === dateAvailability?.booked
            ); // Hide fully booked dates
          })
          .map((date, index) => (
            <Select.Option
              key={index}
              value={moment(date).format("DD MMM YYYY")}
            >
              {moment(date).format("DD MMM YYYY")}
            </Select.Option>
          ))}
      </Select>
    );
  };
  // console.log(selectedSevaData, location);
  const [imageUrls, setImageUrls] = useState([]);
  const [imageLoading, setImageLoading] = useState(true);

  useEffect(() => {
    if (selectedSevaData) {
      const loadImages = async () => {
        const urls = await Promise.all(
          selectedSevaData.images?.map(async (item) => {
            const url = await fetchImage(item.name);
            return url;
          })
        );
        setImageUrls(urls);
        setImageLoading(false);
      };

      loadImages();
    }
  }, [selectedSevaData]);

  // console.log(imageUrls);
  return (
    <Wrapper>
      {/* <Row gutter={[16, 16]} className="mb-1">
        <Col span={24}>
          <Card className="userCardContainer">
            <Meta
              title={`Welcome, ${userDetails.name}`}
              // description={`Email: ${user.email} | Mobile: ${user.mobileNumber}`}
            />
          </Card>
        </Col>
      </Row> */}
      {/* <div className="back-button mb-0 mt-0">
        <img
          onClick={handleBack}
          className="back-button-link"
          src={backIcon}
          width={40}
          height={40}
        />
      </div> */}

      {/* Desktop View */}
      <Card className="desktopView commonCardShadow mb-5">
        <div className="card-header">
          <img
            onClick={handleBack}
            className="back-button-link"
            src={backIcon}
            width={40}
            height={40}
          />
        </div>
        <Row>
          {/* First Column: Image, Title, and Description */}
          <Col md={4} sm={12}>
            <Row>
              <Col xs={12}>
                {imageLoading  ? (
                  <div className="punyarJakSkeleton">
                    <Skeleton.Image active />
                  </div>
                ) : (
                  <img
                    // src={
                    //   (selectedSevaData &&
                    //     imageUrls[selectedSevaData.images[0]] &&
                    //     imageUrls[selectedSevaData.images[0]?._id]) ||
                    //   kalashIcon
                    // }
                    src={(selectedSevaData && imageUrls[0]) || kalashIcon}
                    alt={
                      selectedSevaData
                        ? selectedSevaData.name
                        : "" || "Seva Image"
                    }
                    className="image-column sevaBookingImage"
                  />
                )}
              </Col>
              <Col xs={12} className="mt-3">
                <h2 className="title">
                  {selectedSevaData
                    ? selectedSevaData.name
                    : "" || "No Title Available"}
                </h2>
              </Col>
              <Col xs={12} className="mt-2">
                <div className="description">{description}</div>
              </Col>
            </Row>
          </Col>

          {/* Second Column: Booking Form */}
          <Col md={8} sm={12}>
            <h3 className="booking-title">Book Your Seva</h3>
            <Formik
              initialValues={{
                count: 1,
                amount: selectedSevaData ? selectedSevaData.amount : "",
                selectedDates: [],
              }}
              enableReinitialize={true}
              onSubmit={async (values, { setSubmitting }) => {
                const totalAmount = values.amount * values.count;
                const payload = {
                  serviceId: selectedSevaData ? selectedSevaData._id : "",
                  count: values.count,
                  dates: values.selectedDates,
                };

                try {
                  // First create the booking
                  const response = await createBooking(payload);
                  console.log(
                    "🚀🚀🚀 ~ onSubmit={ ~ response:",
                    response,
                    response.data.result._id
                  );
                  if (response?.code === 201 && response?.status === true) {
                    // Set the booking ID for further use
                    setBookingId(response.data.result._id);

                    // Initiate payment
                    await initiatePayment(response.data.result, totalAmount);
                  } else {
                    throw new Error(
                      response?.message ||
                        "Something went wrong. Please try again."
                    );
                  }
                } catch (error) {
                  Swal.fire({
                    title: "Booking Failed",
                    text:
                      error?.message ||
                      "An unexpected error occurred. Please try again.",
                    icon: "error",
                    confirmButtonText: "Close",
                  });
                  console.error("Error creating booking:", error);
                } finally {
                  setSubmitting(false);
                }
              }}
            >
              {({ values, handleSubmit, isSubmitting, setFieldValue }) => (
                <Form onSubmit={handleSubmit}>
                  <Row form>
                    <Col sm={6}>
                      <FormGroup>
                        <Label for="count">Count</Label>
                        <Field
                          name="count"
                          type="number"
                          className="form-control"
                          min="1"
                          value={values.count}
                          onChange={(e) => {
                            const newCount = parseInt(e.target.value) || 1;
                            setFieldValue("count", newCount);
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col sm={6}>
                      <FormGroup>
                        <Label for="dates">Available Dates</Label>
                        <div id="sevaBooking">
                          <CustomDateSelectComponent
                            dates={availableDates}
                            value={values.selectedDates}
                            onChange={(dates) =>
                              setFieldValue("selectedDates", dates)
                            }
                            availability={selectedSevaData?.availability || []}
                          />
                        </div>
                      </FormGroup>
                    </Col>
                    <Col sm={6}>
                      <FormGroup>
                        <Label for="amount">Amount Per Unit</Label>
                        <Field
                          name="amount"
                          value={values.amount}
                          type="number"
                          className="form-control"
                          readOnly
                        />
                      </FormGroup>
                    </Col>
                    <Col sm={6}>
                      <FormGroup>
                        <Label for="totalAmount">Total Amount</Label>
                        <Input
                          value={(
                            values.amount *
                            values.count *
                            (values.selectedDates?.length || 1)
                          ).toFixed(2)}
                          type="number"
                          className="form-control"
                          readOnly
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Button
                    color="primary"
                    type="submit"
                    disabled={
                      isSubmitting || isLoading || !values.selectedDates.length
                    }
                  >
                    {isSubmitting || isLoading ? "Processing..." : "Pay Now"}
                  </Button>
                </Form>
              )}
            </Formik>
          </Col>
        </Row>
      </Card>

      {/* Mobile View */}
      <div className="mobileView">
        <Card className="commonCardShadow">
          <Row>
            <Col xs={12} sm={4} className="text-center mb-3">
              <img
                src={
                  selectedSevaData && selectedSevaData.images?.length > 0
                    ? selectedSevaData.images[0]?.presignedUrl
                    : kalashIcon
                }
                alt={selectedSevaData ? selectedSevaData.name : "Seva Image"}
                className="sevaBookingImage"
              />
            </Col>

            {/* Column for Title and Description */}
            <Col xs={12} sm={8} className="text-center mb-3">
              <h5 className="commonTextColor commonFont">
                {selectedSevaData ? selectedSevaData.name : "Service Name"}
              </h5>
              <p className="commonTextColor commonFont">{description}</p>
            </Col>

            <Col xs={12} className="form-container">
              <Formik
                initialValues={{
                  count: 1,
                  amount: selectedSevaData ? selectedSevaData.amount : "",
                  selectedDates: [],
                }}
                enableReinitialize={true}
                onSubmit={async (values, { setSubmitting }) => {
                  const totalAmount = values.amount * values.count;
                  const payload = {
                    serviceId: selectedSevaData ? selectedSevaData._id : "",
                    count: values.count,
                    dates: values.selectedDates,
                  };

                  try {
                    // First create the booking
                    const response = await createBooking(payload);
                    if (response?.code === 201 && response?.status === true) {
                      console.log("Booking Id:", response.data.result);

                      // Set the booking ID for further use
                      setBookingId(response.data.result._id);

                      // Initiate payment
                      await initiatePayment(response.data.result, totalAmount);
                    } else {
                      throw new Error(
                        response?.message ||
                          "Something went wrong. Please try again."
                      );
                    }
                  } catch (error) {
                    Swal.fire({
                      title: "Booking Failed",
                      text:
                        error?.message ||
                        "An unexpected error occurred. Please try again.",
                      icon: "error",
                      confirmButtonText: "Close",
                    });
                    console.error("Error creating booking:", error);
                  } finally {
                    setSubmitting(false);
                  }
                }}
              >
                {({ values, handleSubmit, isSubmitting, setFieldValue }) => (
                  <Form onSubmit={handleSubmit}>
                    <FormGroup>
                      <Label for="count">Count</Label>
                      <Field
                        name="count"
                        type="number"
                        className="form-control"
                        min="1"
                        value={values.count}
                        onChange={(e) => {
                          const newCount = parseInt(e.target.value) || 1;
                          setFieldValue("count", newCount);
                        }}
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label for="dates">Available Dates</Label>
                      <CustomDateSelectComponent
                        dates={availableDates}
                        value={values.selectedDates}
                        onChange={(dates) =>
                          setFieldValue("selectedDates", dates)
                        }
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label for="amount">Amount Per Unit</Label>
                      <Field
                        name="amount"
                        value={values.amount}
                        type="number"
                        className="form-control"
                        readOnly
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label for="totalAmount">Total Amount</Label>
                      <Input
                        value={(
                          values.amount *
                          values.count *
                          (values.selectedDates?.length || 1)
                        ).toFixed(2)}
                        type="number"
                        className="form-control"
                        readOnly
                      />
                    </FormGroup>
                    <Button
                      color="primary"
                      type="submit"
                      disabled={
                        isSubmitting ||
                        isLoading ||
                        !values.selectedDates.length
                      }
                    >
                      {isSubmitting || isLoading ? "Processing..." : "Pay Now"}
                    </Button>
                  </Form>
                )}
              </Formik>
            </Col>
          </Row>
        </Card>
      </div>
    </Wrapper>
  );
}

export default SevaBooking;
