// src/context/PaymentContext.js
import React, { createContext, useContext, useState } from "react";

// Create context
const PaymentContext = createContext();

// Custom hook to use payment context
export const usePayment = () => useContext(PaymentContext);

// Provider component to wrap the app
export const PaymentProvider = ({ children }) => {
  const [onPaymentResult, setOnPaymentResult] = useState(null);

  // Update callback function
  const updatePaymentResultCallback = (callback) => {
    setOnPaymentResult(() => callback); // Set the function in state
  };

  return (
    <PaymentContext.Provider value={{ onPaymentResult, updatePaymentResultCallback }}>
      {children}
    </PaymentContext.Provider>
  );
};
