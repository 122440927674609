import React from "react";
import { Col, Container, Row } from "reactstrap";
import logo from "../assets/logo/main-logo.png";
import facebook from "../assets/icons/facebook.png";
import { useNavigate } from "react-router-dom";

const Footer = () => {
  const navigate = useNavigate();

  return (
    <Container
      fluid
      className="bg-footer"
      style={{ backgroundColor: "#FF8744", padding: "20px 0" }}
    >
      <Container>
        <Row className="justify-content-between align-items-center">
          {/* Left Section */}
          <Col sm={9}>
            <div
              className="logo"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "start",
                width: "auto",
              }}
            >
              <div
                className="copyright_text"
                style={{ color: "#fff", fontSize: "14px" }}
              >
                Powered By
              </div>
              <img
                src={logo}
                alt="Website Logo"
                style={{ height: "40px", width: "auto" }}
              />
            </div>
            <div
              className="copyright_text"
              style={{ color: "#fff", fontSize: "14px" }}
            >
              Copyright © 2025 Dharm Prabhavna Technologies LLP. All Rights
              Reserved.
              <div style={{ marginTop: "0px" }}>
                <span
                  style={{
                    paddingRight: "1rem",
                    borderRight: "2px solid #ffffff",
                    cursor: "pointer",
                  }}
                  onClick={() => navigate("/privacy-policy")}
                >
                  Privacy Policy
                </span>
                <span
                  style={{
                    padding: "0 1rem",
                    borderRight: "2px solid #ffffff",
                    cursor: "pointer",
                  }}
                  onClick={() => navigate("/terms-conditions")}
                >
                  Terms & conditions
                </span>
                <span
                  onClick={() => navigate("/refund-policy")}
                  style={{ paddingLeft: "1rem", cursor: "pointer" }}
                >
                  Cancellation & Refund Policy
                </span>
              </div>
            </div>
          </Col>

          {/* Right Section */}
          <Col md={2} sm={3} className="align-self-center">
            <div className="d-flex justify-content-sm-between justify-content-evenly">
              <a
                href="https://www.facebook.com/"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={facebook}
                  width="20"
                  height="20"
                  className="cursor-pointer"
                  alt="Facebook"
                  style={{ filter: "invert(1)" }}
                />
              </a>
            </div>
          </Col>
        </Row>
      </Container>
    </Container>
  );
};

export default Footer;
