import React, { useEffect, useRef, useState } from "react";
import { Col, Row, Form, FormGroup, Label, Input, Button } from "reactstrap";
import "../common.scss";
import { useSelector } from "react-redux";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { Formik, Field } from "formik";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import backIcon from "../assets/icons/back.svg";
import { Card, Tag } from "antd";
import {
  createDonation,
  getAllMasterCategories,
  getAllSubCategories,
  getAllTrustDetail,
} from "../utility/commonApi";
import Payment from "../Helper/WebServices/Payment";
import PaymentHelper from "../components/DonationPayment/paymentHelper";
import { Utils } from "../constants";
import { AtomPayment } from "../Helper/AtomPayment";
import apiCaller from "../Helper/WebServices/apiCaller";
import { useTranslation } from "react-i18next";
import { usePayment } from "./DonationPayment/paymentContext";

function AddDonation() {
  const trustId = localStorage.getItem("trustId");
  const { t } = useTranslation();
  const navigate = useNavigate();
  const paymentWindowRef = useRef(null);
  const handleBack = () => {
    navigate(`/seva`);
  };
  const queryClient = useQueryClient();
  const userDetails = useSelector((state) => state.auth.user);
  const languageId = "6332cbba8054b2cac94da3d1";
  const { data } = useQuery({
    queryKey: ["MasterCategory", languageId],
    queryFn: () =>
      getAllSubCategories({
        languageId: languageId,
      }),
    keepPreviousData: true,
  });
  const { data: trustData } = useQuery({
    queryKey: ["trustData", languageId],
    queryFn: () =>
      getAllTrustDetail({
        languageId: languageId,
        trustId: trustId,
      }),
    keepPreviousData: true,
  });
  // const categories = data ? data.data.results : [];
  const categories = [];
  const trustDetails = trustData ? trustData.data.result : [];
  const combinedAddress = `${userDetails?.addLine1 || ""}, ${
    userDetails?.addLine2 || ""
  }, ${userDetails?.city || ""}, ${userDetails?.district || ""}, ${
    userDetails?.state || ""
  }, ${userDetails?.country || ""}, ${userDetails?.pin || ""}`;

  // payment logic
  const [paymentStatusLoader, setPaymentStatusLoader] = useState(
    Payment.Status.not_init
  );
  const [transactionId, setTransactionId] = useState("");

  const [donationPrice, setDonationPrice] = useState("");
  const [selectedSubcategory, setSelectedSubcategory] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [allowRetry, setAllowRetry] = useState(false);
  const timeoutRef = useRef(null);

  const startTimeout = () => {
    // Set a timeout for 15 minutes (900000 milliseconds)
    timeoutRef.current = setTimeout(() => {
      handleSessionTimeout();
    }, 900000); // 15 minutes
  };

  useEffect(() => {
    // Start the timeout when the component mounts
    if (transactionId) {
      startTimeout();
    }
    // Clear the timeout if the component unmounts or payment completes
    return () => clearTimeout(timeoutRef.current);
  }, [transactionId]);

  const handleSessionTimeout = () => {
    console.log("Payment session has timed out.");
    Utils.showErrorToast("Your payment session has expired. Please try again.");
    //Donation is marked as deleted.
    //In callback function we will mark isDeleted false for Successful Transaction.
    deleteDonation(transactionId, "");
    // Optionally navigate back or reset the payment process
    navigate(-1);

    setIsLoading(false);
    setAllowRetry(true);
    setPaymentStatusLoader(Payment.Status.not_init);
  };

  function handlePaymentFailure(txnId, result) {
    console.log("In handlePaymentFailure:", result);
    setIsLoading(false);
    if (result?.message) {
      Utils.showErrorToast(result?.message);
    }
    // Ensure transactionId is set before calling deleteDonation
    if (txnId) {
      deleteDonation(txnId, "");
    } else {
      console.error("transactionId is not set, unable to delete donation.");
    }
    if (result?.error) {
      Utils.showSuccessToast(result.error);
    }

    setTimeout(() => {
      //navigation.goBack();
      console.log("Timeout triggered");
      setIsLoading(false);
      setAllowRetry(true);
      setPaymentStatusLoader(Payment.Status.not_init);
    }, 5000);
  }

  function UpdateDonation(transactionId, status, pgOrderId, trustId) {
    // Check for internet connection using navigator.onLine
    if (navigator.onLine) {
      setPaymentStatusLoader(Payment.Status.start_payment_api);

      // Prepare parameters for the donation update
      let parms = {
        transactionId: transactionId,
        status: status,
        pgOrderId: pgOrderId,
        trustId: trustId,
      };

      console.log(parms);

      // Include category ID if selectedSubcategory exists
      if (selectedSubcategory) {
        parms.categoryId = selectedSubcategory;
      }

      // Call the API to update the donation
      apiCaller.callUpdateDonation(parms, async (statusCode, response) => {
        const responseData = response?.data?.data;
        if (statusCode === 200 || statusCode === 201) {
          setPaymentStatusLoader(Payment.Status.completed_payment_api);
        } else {
          setPaymentStatusLoader(Payment.Status.failed_payment_api);
        }
      });
    } else {
      // Show error toast if there's no internet connection
      Utils.showErrorToast(t("noInternetMessage"));
    }
  }

  function deleteDonation(transactionId, trustId) {
    if (navigator.onLine) {
      let params = {
        transactionId: transactionId,
        trustId: trustId,
      };
      console.log(params);

      apiCaller.callMarkDeleteDonation(params, async (statusCode, response) => {
        const responseData = response?.data?.data;
        if (statusCode === 200 || statusCode === 201) {
          console.log("marked deleted successfully:", transactionId);
        } else {
          console.error(
            "Failed to mark donation deleted successfully:",
            transactionId
          );
        }
      });
    } else {
      console.error(
        "Could not process to mark donation deletion successfully:",
        transactionId
      );
    }
  }

  const { updatePaymentResultCallback } = usePayment();

  useEffect(() => {
    const handlePaymentMessage = (event) => {
      if (event.data.type === "PAYMENT_RESPONSE") {
        const result = event.data.data;
        clearTimeout(timeoutRef.current);

        // Handle ndpsResponse specially for successful payments
        if (result.ndpsResponse) {
          console.log("Received ndpsResponse:", result.ndpsResponse);
          // The Payment component will handle decryption and validation
          // We just need to update our UI here
          UpdateDonation(transactionId, "Processing", "", trustDetails?._id);
          return;
        }

        if (result.success) {
          console.log("Payment succeeded:", result.message);
          UpdateDonation(transactionId, "Paid", "", trustDetails?._id);
          Utils.showSuccessToast(t("api_success_donation_payment_success"));
        } else {
          // Handle different error cases
          if (result.message === "Payment has been cancelled by the user!") {
            Utils.showErrorToast("Payment was cancelled");
          } else if (result.message === "Payment session timed out") {
            Utils.showErrorToast("Payment session timed out");
          } else {
            handlePaymentFailure(transactionId, result);
          }
        }

        // Close payment window if it's still open
        if (paymentWindowRef.current && !paymentWindowRef.current.closed) {
          paymentWindowRef.current.close();
        }
      }
    };

    window.addEventListener("message", handlePaymentMessage);
    return () => window.removeEventListener("message", handlePaymentMessage);
  }, [transactionId, trustDetails]);

  useEffect(() => {
    return () => {
      // Clean up localStorage on unmount
      localStorage.removeItem("currentPaymentTxnId");
      localStorage.removeItem("currentPaymentTrustId");
    };
  }, []);

  async function initiateNTTPayment() {
    try {
      setPaymentStatusLoader(Payment.Status.creating_payment_order);
      const ndps = new PaymentHelper();

      // Generate transaction ID
      let txnId =
        Date.now().toString(36) + Math.random().toString(36).substr(2, 5);
      setTransactionId(txnId);

      // Set up payment details
      let amount = donationPrice.toString();
      let userEmailId = userDetails?.email;
      let userContactNo = userDetails?.mobileNumber;

      // Prepare API parameters
      const apiParams = {
        customerId: userDetails?.userId ?? userDetails?.id,
        customerPhone: userContactNo.toString(),
        customerEmail: userEmailId,
        orderAmount: amount,
        orderCurrency: "INR",
        vendorId: trustDetails?.vendorId,
        orderId: txnId,
        amount: donationPrice,
        trustId: trustDetails?._id,
        donarName: userDetails.name,
        transactionId: txnId,
      };

      // Add category ID if selected
      if (selectedSubcategory) {
        apiParams.categoryId = selectedSubcategory;
      }

      // Prepare merchant details
      const merchantDetails = {
        merchantId: trustDetails?.vendorId,
        password: trustDetails?.pgConfig?.password,
        productId: trustDetails?.pgConfig?.productId,
        req_enc_key: trustDetails?.pgConfig?.req_enc_key,
        res_enc_key: trustDetails?.pgConfig?.res_enc_key,
        response_hash_key: trustDetails?.pgConfig?.response_hash_key,
        custFirstName: trustDetails?.name?.trim(),
        custEmailId: userEmailId,
        custMobileNumber: userContactNo.toString(),
        txnDate: new Date().toISOString(),
        merchTxnId: txnId,
        amount: amount,
        udf1: "udf1",
        udf2: "udf2",
        udf3: "udf3",
        udf4: "udf4",
        udf5: "udf5",
        paymentMode: "NB",
        mode: "uat",
      };

      console.log("Initiating Atom Payment...");
      setIsLoading(true);
      setPaymentStatusLoader(Payment.Status.creating_payment_order);

      // Create payment order
      const { atomTokenId, donationId } = await AtomPayment.createOrder(
        apiParams
      );

      if (atomTokenId) {
        setPaymentStatusLoader(Payment.Status.created_payment_order);

        // Generate payment HTML content
        const htmlPage = ndps.openAipayPopUp(atomTokenId, merchantDetails);

        // Set up payment callback through context
        updatePaymentResultCallback((result) => {
          clearTimeout(timeoutRef.current);

          if (result.success) {
            console.log("Payment succeeded:", result.message);
            UpdateDonation(txnId, "Paid", "", trustDetails?._id);
            Utils.showSuccessToast(t("api_success_donation_payment_success"));
          } else {
            handlePaymentFailure(txnId, result);
          }
        });

        // Start payment timeout
        startTimeout();

        // Store necessary data in localStorage for payment page
        localStorage.setItem("currentPaymentTxnId", txnId);
        localStorage.setItem("currentPaymentTrustId", trustDetails?._id);

        // Navigate to payment page with only required data
        navigate("/payment", {
          state: {
            htmlPage,
            merchantDetails: {
              ...merchantDetails,
              res_enc_key: merchantDetails.res_enc_key,
              response_hash_key: merchantDetails.response_hash_key,
            },
            origin: "donation",
          },
        });
      } else {
        throw new Error("Failed to get atom token");
      }

      setIsLoading(false);
      setAllowRetry(true);
      setPaymentStatusLoader(Payment.Status.not_init);
    } catch (error) {
      console.error("Failed to create Atom order:", error.message);
      Utils.showErrorToastNoAutoHide("Failed to initiate payment");

      if (transactionId) {
        handlePaymentFailure(transactionId, {
          success: false,
          message: error.message || "Payment initialization failed",
        });
      }

      setIsLoading(false);
      setAllowRetry(true);
      setPaymentStatusLoader(Payment.Status.not_init);
    }
  }

  return (
    <div>
      {/* <div className="back-button mb-0 mt-0">
        <img
          onClick={handleBack}
          className="back-button-link"
          src={backIcon}
          width={40}
          height={40}
        />
      </div> */}

      <Card className="commonCardShadow mb-5">
        <div className="card-header">
          <img
            onClick={handleBack}
            className="back-button-link"
            src={backIcon}
            width={40}
            height={40}
          />
        </div>
        <Row>
          <Col>
            <div className="d-flex justify-content-between align-items-center mb-3">
              <h3 className="booking-title">Add Donation</h3>
              <Tag color="orange" className="cardTitle">
                <span>Username: {userDetails ? userDetails.name : ""}</span>
              </Tag>
            </div>

            <Formik
              initialValues={{
                mobileNumber: userDetails?.mobileNumber || "",
                donorName: userDetails?.name || "",
                masterCategoryId: "",
                amount: "",
                address: combinedAddress,
                panNumber: userDetails?.panNum || "",
              }}
              onSubmit={async (values, { setSubmitting }) => {
                initiateNTTPayment();
              }}
            >
              {({
                values,
                handleSubmit,
                isSubmitting,
                setFieldValue,
                handleChange,
              }) => (
                <Form onSubmit={handleSubmit}>
                  <Row form>
                    {/* Donor Name Field */}
                    <Col xs={12} sm={6} md={6} lg={3}>
                      <FormGroup>
                        <Label for="donorName">Donor Name</Label>
                        <Field
                          name="donorName"
                          type="text"
                          className="form-control commonTextColor"
                          placeholder="Enter donor name"
                        />
                      </FormGroup>
                    </Col>

                    {/* Mobile Number Field */}
                    <Col xs={12} sm={6} md={6} lg={3}>
                      <FormGroup>
                        <Label for="mobileNumber">Mobile Number</Label>
                        <Field
                          name="mobileNumber"
                          type="text"
                          className="form-control commonTextColor"
                          readOnly
                          value={values.mobileNumber}
                        />
                      </FormGroup>
                    </Col>

                    {/* Username Field */}
                    {/* <Col xs={12} sm={6} md={6} lg={3}>
                      <FormGroup>
                        <Label for="username">Username</Label>
                        <Field
                          name="username"
                          type="text"
                          className="form-control commonTextColor"
                          readOnly
                          value={values.username}
                        />
                      </FormGroup>
                    </Col> */}

                    {/* Category Field */}
                    <Col xs={12} sm={6} md={6} lg={3}>
                      <FormGroup>
                        <Label for="masterCategoryId">Category</Label>
                        <Field
                          name="masterCategoryId"
                          as="select"
                          className="form-control commonTextColor"
                          onChange={(e) => {
                            setFieldValue("masterCategoryId", e.target.value);
                            setSelectedSubcategory(e.target.value);
                          }}
                        >
                          <option value="">Select option</option>
                          {categories.map((cat) => (
                            <option key={cat.id} value={cat.id}>
                              {cat.name}
                            </option>
                          ))}
                        </Field>
                      </FormGroup>
                    </Col>

                    {/* Amount Field */}
                    <Col xs={12} sm={6} md={6} lg={3}>
                      <FormGroup>
                        <Label for="amount">Amount</Label>
                        <Field
                          name="amount"
                          type="number"
                          className="form-control commonTextColor"
                          placeholder="Enter amount"
                          onChange={(e) => {
                            setFieldValue("amount", e.target.value);
                            setDonationPrice(e.target.value);
                          }}
                        />
                      </FormGroup>
                    </Col>
                    {/* PAN Number */}
                    <Col xs={12} sm={6} md={6} lg={3}>
                      <FormGroup>
                        <Label for="panNumber">PAN Number</Label>
                        <Field
                          name="panNumber"
                          type="text"
                          className="form-control commonTextColor"
                          placeholder="Enter PAN number"
                        />
                      </FormGroup>
                    </Col>
                    {/* Address */}
                    <Col xs={12} sm={12} md={6} lg={6}>
                      <FormGroup>
                        <Label for="address">Address</Label>
                        <Field
                          name="address"
                          type="text"
                          className="form-control commonTextColor"
                          readOnly
                          value={values.address}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <div className="d-flex justify-content-end">
                    <Button
                      color="primary"
                      type="submit"
                      disabled={isSubmitting}
                    >
                      {isSubmitting ? "Submitting..." : "Donate Now"}
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          </Col>
        </Row>
      </Card>
    </div>
  );
}

export default AddDonation;
