// Define the Status enum with various stages of the payment process
export const Status = {
  not_init: 'NOT_INIT',

  creating_payment_order: 'CREATING_PAYMENT_ORDER',
  created_payment_order: 'CREATED_PAYMENT_ORDER',
  payment_retry: 'PAYMENT_RETRY',

  start_cashfree_payment_process: 'Start_cashfree_payment_process',
  completed_cashfree_payment_process: 'Completed_Cashfree_Payment_Process',

  start_payment_api: 'START_PAYMENT_API',
  completed_payment_api: 'COMPLETED_PAYMENT_API',

  failed_creating_order: 'FAILD_CREATING_ORDER',
  failed_payment_api: 'FAILD_PAYMENT',
  failed_cashfree_payment_process: 'Failed_Cashfree_Payment_Process',
};

// Export the Status enum for use in other files
export default {
  Status,
};
