import React, { useEffect, useState } from "react";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import { Card, Row, Col, Typography, Button } from "antd";
import "../App.css";

const { Title, Text } = Typography;

const containerStyle = {
  width: "100%",
  height: "250px", // Adjust height as needed
};

// Default location (New Delhi) if no location is found in local storage
const defaultLocation = {
  address: "Location not found",
  lat: 28.6139,
  lng: 77.209,
};

const GoogleMapWidget = () => {
  const [location, setLocation] = useState(defaultLocation);

  // Function to fetch location from local storage
  const fetchLocation = () => {
    const trustData = localStorage.getItem("trustData");
    if (trustData) {
      try {
        const parsedData = JSON.parse(trustData);
        if (
          parsedData.location &&
          parsedData.latitude &&
          parsedData.longitude &&
          parsedData.mobileNumber
        ) {
          setLocation({
            address: parsedData.location || "Unknown Address",
            lat: parseFloat(parsedData.latitude),
            lng: parseFloat(parsedData.longitude),
            mobile: parsedData.mobileNumber || "",
          });
        }
      } catch (error) {
        console.error("Error parsing trustData:", error);
      }
    }
  };

  // Load location from local storage on component mount
  useEffect(() => {
    fetchLocation();
  }, []);

  // Common text styles
  const textStyle = {
    fontFamily: "Noto Sans, sans-serif",
    color: "#583703",
  };

  return (
    <>
      <div className="heading_div">Location</div>
      <Card
        title="Address & Contact"
        style={{ maxWidth: "100%", margin: "auto" }}
        headStyle={{
          font: "normal normal 700 20px / 38px Noto Sans",
          paddingBottom: "10px",
          color: "#583703",
        }}
      >
        <Row gutter={[16, 16]} justify="center">
          {/* Left Side - Address Details */}
          <Col xs={24} sm={24} md={12}>
            <Title level={5} style={textStyle}>
              Address
            </Title>
            <Text style={textStyle}>{location.address}</Text>
            <br />
            <br />

            <Title level={5} style={textStyle}>
              Contact Us (Mobile Number)
            </Title>
            <Text style={textStyle}>{location.mobile}</Text>
            <br />

            {/* <Text strong>Latitude:</Text> <Text>{location.lat}</Text>
          <br />
          <Text strong>Longitude:</Text> <Text>{location.lng}</Text>
          <br />
          <Button
            type="primary"
            onClick={fetchLocation}
            style={{ marginTop: 10 }}
          >
            Refresh Location
          </Button> */}
          </Col>

          {/* Right Side - Google Map */}
          <Col xs={24} sm={24} md={12}>
            <LoadScript
              googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
            >
              <GoogleMap
                mapContainerStyle={containerStyle}
                center={{ lat: location.lat, lng: location.lng }}
                zoom={15}
              >
                <Marker position={{ lat: location.lat, lng: location.lng }} />
              </GoogleMap>
            </LoadScript>
          </Col>
        </Row>
      </Card>
    </>
  );
};

export default GoogleMapWidget;
