import React, { useEffect, useState } from "react";
import { Card, Row, Col, Empty, Tabs, Typography } from "antd";
import { Button } from "reactstrap";
import Meta from "antd/es/card/Meta";
import { useLocation, useNavigate } from "react-router-dom";
import Wrapper from "../views/common/commonWrapper";
import moment from "moment";
import Loader from "../views/common/loader";
import "../common.scss";
import kalashIcon from "../assets/icons/kalash.png";
import donationIcon from "../assets/icons/donation.png";
import dharmshalaIcon from "../assets/icons/dharmshala.png";
import { useDispatch } from "react-redux";
import { logOut } from "../redux/slices/authSlice";
import ServiceList from "./serviceList";
import SevaBooking from "./sevaBooking";
import BookingHistory from "./bookingHistory";
import UserProfile from "./userProfile";
import AddDonation from "./addDonation";
import AddAccommodation from "./addAccommodation";
import BookingTable from "./bookingTable";
import DonationReceiptsTable from "./donationReciepts";

const homeData = [
  {
    _id: "1",
    title: "Seva/Pooja",
    buttonName: "View",
    // description: "Description for Seva 1.",
    image: kalashIcon,
    key: "seva",
  },
  {
    _id: "2",
    title: "Accommodation",
    buttonName: "Book",
    // description: "Description for Seva 2.",
    image: dharmshalaIcon,
    key: "accommodation",
  },
  {
    _id: "3",
    title: "Donation",
    buttonName: "Donation",
    // description: "Description for Seva 2.",
    image: donationIcon,
    key: "donation",
  },
  // Add more Seva data here
];

function SevaList() {
  const [user, setUser] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState("home");
  const [sevaList, setSevaList] = useState(false);
  const [bookingSeva, setBookingSeva] = useState(false);
  const [selectedSeva, setSelectedSeva] = useState(null);
  const [accommodationList, setAccommodationList] = useState(false);
  const [donationList, setDonationList] = useState(false);
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const page = queryParams.get("page");

    if (page === "sevaList") {
      setActiveTab("home");
      setSevaList(true);
      setAccommodationList(false);
      setDonationList(false);
    } else if (page === "bookSeva") {
      setActiveTab("home");
      setBookingSeva(true);
      setSevaList(false);
      setAccommodationList(false);
      setDonationList(false);
    } else if (page === "accommodation") {
      setActiveTab("home");
      setAccommodationList(true);
      setSevaList(false);
      setDonationList(false);
    } else if (page === "donation") {
      setActiveTab("home");
      setDonationList(true);
      setSevaList(false);
      setAccommodationList(false);
    } else if (page === "bookingHistory") {
      setActiveTab("bookingHistory");
    } else if (page === "account") {
      setActiveTab("account");
    } else if (page === "bookings") {
      setActiveTab("bookingTable");
    } else if (page === "donationReciepts") {
      setActiveTab("donationReciepts");
    } else {
      setActiveTab("home");
      setBookingSeva(false);
      setAccommodationList(false);
      setDonationList(false);
    }
  }, [location.search]);
  useEffect(() => {
    // Fetching user data from localStorage
    const userData = JSON.parse(localStorage.getItem("user"));
    setUser(userData);
  }, []);
  const handleButtonClick = (key, id) => {
    if (key === "seva") {
      setSevaList(true);
      setAccommodationList(false);
      setDonationList(false);
      navigate(`/seva?page=sevaList`);
    } else if (key === "accommodation") {
      setAccommodationList(true);
      setSevaList(false);
      setDonationList(false);
      navigate(`/seva?page=accommodation`);
    } else if (key === "donation") {
      setDonationList(true);
      setSevaList(false);
      setAccommodationList(false);
      navigate(`/seva?page=donation`);
    }
  };
  // If no user data, show Seva list
  if (!user) {
    return (
      <Wrapper>
        <ServiceList
          hasUser={false}
          setSevaList={setSevaList}
          setBookingSeva={setBookingSeva}
        />
      </Wrapper>
    );
  }

  // Tabs content
  const tabs = [
    { key: "home", label: "Home" },
    { key: "bookingHistory", label: "Booked Services" },
    { key: "bookingTable", label: "Booked Accommodations" },
    { key: "donationReciepts", label: "Donation Reciepts" },
    { key: "account", label: "Account" },
  ];

  return (
    <Wrapper>
      {/* {!sevaList && !bookingSeva && !donationList && !accommodationList && ( */}
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Card className="userCardContainer mb-2">
            <Meta
              title={`Welcome, ${user.name}`}
              // description={`Email: ${user.email} | Mobile: ${user.mobileNumber}`}
            />
          </Card>
        </Col>
      </Row>
      {/* )} */}
      {/* Tabs Section */}
      <Tabs
        id="sevaListTab"
        activeKey={activeTab}
        defaultActiveKey="home"
        onChange={(key) => {
          setActiveTab(key);
          if (key === "bookingHistory") {
            navigate("/seva?page=bookingHistory");
          } else if (key === "bookingTable") {
            navigate("/seva?page=bookings");
          } else if (key === "home") {
            navigate("/seva");
          } else if (key === "account") {
            navigate("/seva?page=account");
          } else if (key === "donationReciepts") {
            navigate("/seva?page=donationReciepts");
          }
        }}
        onTabClick={(key) => {
          if (key == "home") {
            navigate("/seva");
            setActiveTab("home");
            setSevaList(false);
            setAccommodationList(false);
            setDonationList(false);
          }
        }}
      >
        {tabs.map((tab) => (
          <Tabs.TabPane tab={tab.label} key={tab.key}>
            {/* Show content for each tab */}
            {tab.key === "home" && (
              <>
                {sevaList ? (
                  <ServiceList
                    setSevaList={setSevaList}
                    setBookingSeva={setBookingSeva}
                    setSelectedSeva={setSelectedSeva}
                  />
                ) : bookingSeva ? (
                  <SevaBooking
                    setSevaList={setSevaList}
                    setBookingSeva={setBookingSeva}
                    selectedSeva={selectedSeva}
                  />
                ) : donationList ? (
                  <AddDonation />
                ) : accommodationList ? (
                  <AddAccommodation />
                ) : (
                  <Row
                    gutter={[{ xs: 16, sm: 16, md: 24, lg: 32 }, 16]}
                    // className="mx-auto"
                  >
                    {homeData.map((item) => (
                      <Col
                        xs={24}
                        sm={12}
                        md={8}
                        lg={6}
                        key={item._id}
                        className="cardColumn"
                      >
                        <Card
                          className="cardContainer"
                          hoverable
                          style={{ width: "100%", marginLeft: "0px" }}
                        >
                          <div className="cardTitle1">{item.title}</div>

                          <div className="d-flex flex-column justify-content-between">
                            {/* Image */}
                            <div
                              className="d-flex justify-content-center align-items-center"
                              style={{ height: "200px" }}
                            >
                              <img
                                className="cardImage1"
                                alt={item.title}
                                src={item.image}
                              />
                            </div>

                            <Button
                              color="primary"
                              onClick={() =>
                                handleButtonClick(item.key, item._id)
                              }
                            >
                              {item.buttonName}
                            </Button>
                          </div>
                        </Card>
                      </Col>
                    ))}
                  </Row>
                )}
              </>
            )}

            {/* Add content for other tabs if needed */}
            {tab.key === "bookingHistory" && (
              <div>
                <BookingHistory />
              </div>
            )}
            {tab.key === "bookingTable" && (
              <div>
                <BookingTable />
              </div>
            )}
            {tab.key === "account" && (
              <div>
                <UserProfile />{" "}
              </div>
            )}
            {tab.key === "donationReciepts" && (
              <div>
                <DonationReceiptsTable />{" "}
              </div>
            )}
          </Tabs.TabPane>
        ))}
      </Tabs>
    </Wrapper>
  );
}

export default SevaList;
