import ApiManager from './ApiManager';
import Endpoint from './Endpoint';

const callRegisterUserAPI = async (params, completion) => {
  ApiManager.callAPI(
    ApiManager.RequestType.post,
    Endpoint.Url.user_register,
    params,
    completion,
  );
};

const callVerifyMobileNumberAPI = async (params, completion) => {
  ApiManager.callAPI(
    ApiManager.RequestType.post,
    Endpoint.Url.verifyMobileNumber,
    params,
    completion,
  );
};

const callLoginUserAPI = async (params, completion) => {
  ApiManager.callAPI(
    ApiManager.RequestType.post,
    Endpoint.Url.user_login,
    params,
    completion,
  );
};

const callLogoutUserAPI = async (params, completion) => {
  ApiManager.callAPI(
    ApiManager.RequestType.post,
    Endpoint.Url.user_logout,
    params,
    completion,
  );
};

const callRefreshTokenAPI = async (params, completion) => {
  ApiManager.callAPI(
    ApiManager.RequestType.post,
    Endpoint.Url.refreshToken,
    params,
    completion,
  );
};

const callRefreshTokenForFCMAPI = async (params, fcm_token, completion) => {
  ApiManager.callAPI(
    ApiManager.RequestType.post,
    Endpoint.Url.refreshToken,
    params,
    completion,
    fcm_token,
  );
};

const callGetTrustListApiAPI = async (params, completion) => {
  ApiManager.callAPI(
    ApiManager.RequestType.post,
    Endpoint.Url.trustList,
    params,
    completion,
  );
};

const callGetLanguageList = async completion => {
  ApiManager.callAPI(
    ApiManager.RequestType.get,
    Endpoint.Url.languageList,
    {},
    completion,
  );
};

const callSetUserLanguageAPI = async (params, completion) => {
  ApiManager.callAPI(
    ApiManager.RequestType.post,
    Endpoint.Url.setUserLanguage,
    params,
    completion,
  );
};

const callGetUserDetailsAPI = async completion => {
  ApiManager.callAPI(
    ApiManager.RequestType.get,
    Endpoint.Url.getUserDetails,
    {},
    completion,
  );
};

const callGetAllNews = async (params, completion) => {
  ApiManager.callAPI(
    ApiManager.RequestType.post,
    Endpoint.Url.getAllNews,
    params,
    completion,
  );
};

const callGetTodayExclusive = async (params, completion) => {
  ApiManager.callAPI(
    ApiManager.RequestType.post,
    Endpoint.Url.getTodayExclusive,
    params,
    completion,
  );
};

const getTodayExclusiveRandom = async (params, completion) => {
  ApiManager.callAPI(
    ApiManager.RequestType.post,
    Endpoint.Url.getTodayExclusiveRandom,
    params,
    completion,
  );
};

const callSubscribe = async (params, completion) => {
  ApiManager.callAPI(
    ApiManager.RequestType.post,
    Endpoint.Url.subscribe,
    params,
    completion,
  );
};

const callUnsubscribe = async (params, completion) => {
  ApiManager.callAPI(
    ApiManager.RequestType.post,
    Endpoint.Url.unSubscribe,
    params,
    completion,
  );
};
const callDispute = async (params, completion) => {
  ApiManager.callAPI(
    ApiManager.RequestType.post,
    Endpoint.Url.dispute,
    params,
    completion,
  );
};

const callAddPancard = async (params, completion) => {
  ApiManager.callAPI(
    ApiManager.RequestType.post,
    Endpoint.Url.addPanCard,
    params,
    completion,
  );
};

const callUpdatePreference = async (params, completion) => {
  ApiManager.callAPI(
    ApiManager.RequestType.post,
    Endpoint.Url.updatePreference,
    params,
    completion,
  );
};
const callMakeDonation = async (params, completion) => {
  ApiManager.callAPI(
    ApiManager.RequestType.post,
    Endpoint.Url.makeDonation,
    params,
    completion,
  );
};

const callUpdateDonation = async (params, completion) => {
  ApiManager.callAPI(
    ApiManager.RequestType.post,
    Endpoint.Url.updateDonation,
    params,
    completion,
  );
};

const callMarkDeleteDonation = async (params, completion) => {
  ApiManager.callAPI(
    ApiManager.RequestType.post,
    Endpoint.Url.deleteDonation,
    params,
    completion,
  );
};

const callCategoryDonation = async (params, completion) => {
  ApiManager.callAPI(
    ApiManager.RequestType.post,
    Endpoint.Url.masterCategory,
    params,
    completion,
  );
};

const callGetSubCategoryListApi = async (params, completion) => {
  ApiManager.callAPI(
    ApiManager.RequestType.post,
    Endpoint.Url.subcategory,
    params,
    completion,
  );
};

const callInsightTotalDonationAmount = async (params, completion) => {
  ApiManager.callAPI(
    ApiManager.RequestType.get,
    Endpoint.Url.insightTotalDonationAmount,
    params,
    completion,
  );
};

const callGetTrustSubscriptionsList = async (params, completion) => {
  ApiManager.callAPI(
    ApiManager.RequestType.post,
    Endpoint.Url.getTrustSubscriptionsList,
    params,
    completion,
  );
};
const callGetTodaysExclusive = async (params, completion) => {
  ApiManager.callAPI(
    ApiManager.RequestType.post,
    Endpoint.Url.getTodaysExclusive,
    params,
    completion,
  );
};
const callGetCommitmentList = async (params, completion) => {
  ApiManager.callAPI(
    ApiManager.RequestType.post,
    Endpoint.Url.getCommitmentList,
    params,
    completion,
  );
};
const callSaveUserProfileDetails = async (params, completion) => {
  ApiManager.callAPI(
    ApiManager.RequestType.post,
    Endpoint.Url.saveUserProfileDetails,
    params,
    completion,
  );
};

const getEventList = async (params, completion) => {
  ApiManager.callAPI(
    ApiManager.RequestType.post,
    Endpoint.Url.getEventList,
    params,
    completion,
  );
};

const callGetNoticesList = async (params, completion) => {
  ApiManager.callAPI(
    ApiManager.RequestType.post,
    Endpoint.Url.getNotices,
    params,
    completion,
  );
};
const callGetTrustNews = async (params, completion) => {
  ApiManager.callAPI(
    ApiManager.RequestType.post,
    Endpoint.Url.getTrustNews,
    params,
    completion,
  );
};

const callGetTrustEvents = async (params, completion) => {
  ApiManager.callAPI(
    ApiManager.RequestType.post,
    Endpoint.Url.getTrustEvents,
    params,
    completion,
  );
};

const callGetDonatedTrustListApi = async (params, completion) => {
  ApiManager.callAPI(
    ApiManager.RequestType.post,
    Endpoint.Url.donatedTrusts,
    params,
    completion,
  );
};
const callGetAdvertisement = async (params, completion) => {
  ApiManager.callAPI(
    ApiManager.RequestType.post,
    Endpoint.Url.getAdvertisement,
    params,
    completion,
  );
};

const callGetAdvertisementDetails = async (params, completion) => {
  ApiManager.callAPI(
    ApiManager.RequestType.post,
    Endpoint.Url.getAdvertisementDetails,
    params,
    completion,
  );
};
const callGetApnamandirGodList = async (params, completion) => {
  ApiManager.callAPI(
    ApiManager.RequestType.post,
    Endpoint.Url.apnamandir_godList,
    params,
    completion,
  );
};

const callApnamandirFrames = async (params, completion) => {
  ApiManager.callAPI(
    ApiManager.RequestType.post,
    Endpoint.Url.apnamandir_frames,
    params,
    completion,
  );
};
const callCreateApnamandir = async (params, completion) => {
  ApiManager.callAPI(
    ApiManager.RequestType.post,
    Endpoint.Url.create_apnamandir,
    params,
    completion,
  );
};
const callGetUserMandir = async (params, completion) => {
  ApiManager.callAPI(
    ApiManager.RequestType.post,
    Endpoint.Url.get_user_mandir,
    params,
    completion,
  );
};
const callGetMediaVideos = async (params, completion) => {
  ApiManager.callAPI(
    ApiManager.RequestType.post,
    Endpoint.Url.getMediaVideos,
    params,
    completion,
  );
};
const callGetMediaAudio = async (params, completion) => {
  ApiManager.callAPI(
    ApiManager.RequestType.post,
    Endpoint.Url.getMediaAudio,
    params,
    completion,
  );
};

const callGetAllEvent = async (params, completion) => {
  ApiManager.callAPI(
    ApiManager.RequestType.post,
    Endpoint.Url.allEvent,
    params,
    completion,
  );
};
const callCityAndState = async (params, completion) => {
  ApiManager.callAPI(
    ApiManager.RequestType.get,
    Endpoint.Url.state_city,
    params,
    completion,
  );
};

const callGetTrustDetail = async (params, completion) => {
  ApiManager.callAPI(
    ApiManager.RequestType.post,
    Endpoint.Url.getTrustDetail,
    params,
    completion,
  );
};
const callMakeCommitmentPayment = async (params, completion) => {
  ApiManager.callAPI(
    ApiManager.RequestType.post,
    Endpoint.Url.makeCommitmentPayment,
    params,
    completion,
  );
};

const callGetEventDates = async (params, completion) => {
  ApiManager.callAPI(
    ApiManager.RequestType.post,
    Endpoint.Url.getEventDates,
    params,
    completion,
  );
};

const callJinvaniCategoriesList = async (params, completion) => {
  ApiManager.callAPI(
    ApiManager.RequestType.get,
    Endpoint.Url.jinvaniCategoriesList,
    params,
    completion,
  );
};
const callJinvaniList = async (params, completion) => {
  ApiManager.callAPI(
    ApiManager.RequestType.post,
    Endpoint.Url.jinvaniList,
    params,
    completion,
  );
};

const callJinvaniChaptersList = async (params, completion) => {
  ApiManager.callAPI(
    ApiManager.RequestType.post,
    Endpoint.Url.jinvaniChaptersList,
    params,
    completion,
  );
};

const callJinvaniChaptersPageList = async (params, completion) => {
  ApiManager.callAPI(
    ApiManager.RequestType.post,
    Endpoint.Url.jinvaniChaptersPageList,
    params,
    completion,
  );
};

const callCreateInquiry = async (params, completion) => {
  ApiManager.callAPI(
    ApiManager.RequestType.post,
    Endpoint.Url.createInquiry,
    params,
    completion,
  );
};

const callCreateFeedback = async (params, completion) => {
  ApiManager.callAPI(
    ApiManager.RequestType.post,
    Endpoint.Url.createFeedback,
    params,
    completion,
  );
};

const callCreateGetHelpRequest = async (params, completion) => {
  ApiManager.callAPI(
    ApiManager.RequestType.post,
    Endpoint.Url.createGetHelpRequest,
    params,
    completion,
  );
};

const callDeleteAccount = async (params, completion) => {
  ApiManager.callAPI(
    ApiManager.RequestType.delete,
    Endpoint.Url.deleteAccount,
    params,
    completion,
  );
};

const callGlobalSearch = async (params, completion) => {
  ApiManager.callAPI(
    ApiManager.RequestType.post,
    Endpoint.Url.globalSearch,
    params,
    completion,
  );
};

const callUpdateProfileImage = async (params, completion) => {
  ApiManager.callAPI(
    ApiManager.RequestType.post,
    Endpoint.Url.updateProfileImage,
    params,
    completion,
  );
};
const getPunyarjak = async (params, completion) => {
  ApiManager.callAPI(
    ApiManager.RequestType.post,
    Endpoint.Url.punyarjak,
    params,
    completion,
  );
};

const getEventDetails = async (params, completion) => {
  ApiManager.callAPI(
    ApiManager.RequestType.post,
    Endpoint.Url.eventDetails,
    params,
    completion,
  );
};

const getNewsDetails = async (params, completion) => {
  ApiManager.callAPI(
    ApiManager.RequestType.post,
    Endpoint.Url.newsDetails,
    params,
    completion,
  );
};
const getExclusiveDetails = async (params, completion) => {
  ApiManager.callAPI(
    ApiManager.RequestType.post,
    Endpoint.Url.exclusiveDetails,
    params,
    completion,
  );
};

const getTrustType = async (params, completion) => {
  ApiManager.callAPI(
    ApiManager.RequestType.post,
    Endpoint.Url.getTrustType,
    params,
    completion,
  );
};

const getJinvaniCategory = async (params, completion) => {
  ApiManager.callAPI(
    ApiManager.RequestType.post,
    Endpoint.Url.jinvani_category,
    params,
    completion,
  );
};

const getAppPreferenceAPI = async (params, completion) => {
  ApiManager.callAPI(
    ApiManager.RequestType.get,
    Endpoint.Url.app_preference,
    params,
    completion,
  );
};

const getPieChartTotalDonationAPI = async (params, completion) => {
  ApiManager.callAPI(
    ApiManager.RequestType.post,
    Endpoint.Url.pie_chart_total_donation,
    params,
    completion,
  );
};

const getBarChartTotalDonationAPI = async (params, completion) => {
  ApiManager.callAPI(
    ApiManager.RequestType.get,
    Endpoint.Url.bar_chart_total_donation,
    params,
    completion,
  );
};

const getTopDonars = async (params, completion) => {
  ApiManager.callAPI(
    ApiManager.RequestType.post,
    Endpoint.Url.get_top_donars,
    params,
    completion,
  );
};

const switch_all_notification = async (params, completion) => {
  ApiManager.callAPI(
    ApiManager.RequestType.get,
    Endpoint.Url.switch_all_notification,
    params,
    completion,
  );
};

const switch_account_notification = async (params, completion) => {
  ApiManager.callAPI(
    ApiManager.RequestType.get,
    Endpoint.Url.switch_account_notification,
    params,
    completion,
  );
};

const switch_promotional_notification = async (params, completion) => {
  ApiManager.callAPI(
    ApiManager.RequestType.get,
    Endpoint.Url.switch_promotional_notification,
    params,
    completion,
  );
};

const getNotificationList = async (params, completion) => {
  ApiManager.callAPI(
    ApiManager.RequestType.post,
    Endpoint.Url.notification_list,
    params,
    completion,
  );
};

const notificationSeen = async (params, completion) => {
  ApiManager.callAPI(
    ApiManager.RequestType.post,
    Endpoint.Url.notification_seen,
    params,
    completion,
  );
};

const forceUpdate = async (params, completion) => {
  ApiManager.callAPI(
    ApiManager.RequestType.post,
    Endpoint.Url.force_update,
    params,
    completion,
  );
};

const verifyMail = async (params, completion) => {
  ApiManager.callAPI(
    ApiManager.RequestType.get,
    Endpoint.Url.verify_mail,
    params,
    completion,
  );
};

const getTrustFacility = async (params, completion) => {
  ApiManager.callAPI(
    ApiManager.RequestType.post,
    Endpoint.Url.get_trust_facility,
    params,
    completion,
  );
};

const getBusinessTypes = async (params, completion) => {
  ApiManager.callAPI(
    ApiManager.RequestType.get,
    Endpoint.Url.get_business_types,
    params,
    completion,
  );
};

const getTrustNotice = async (params, completion) => {
  ApiManager.callAPI(
    ApiManager.RequestType.post,
    Endpoint.Url.get_trust_notice,
    params,
    completion,
  );
};

const getPunyarjakDetail = async (params, completion) => {
  ApiManager.callAPI(
    ApiManager.RequestType.post,
    Endpoint.Url.punyarjak_detail,
    params,
    completion,
  );
};

const deleteAccount = async (params, completion) => {
  ApiManager.callAPI(
    ApiManager.RequestType.delete,
    Endpoint.Url.delete_account,
    params,
    completion,
  );
};

const sociaLogin = async (params, completion) => {
  ApiManager.callAPI(
    ApiManager.RequestType.post,
    Endpoint.Url.social_login,
    params,
    completion,
  );
};

const ownHinduCalendar = async (params, completion) => {
  ApiManager.callAPI(
    ApiManager.RequestType.post,
    Endpoint.Url.own_hindu_calendar,
    params,
    completion,
  );
};

const getHinduMonthCalendr = async (params, completion) => {
  ApiManager.callAPI(
    ApiManager.RequestType.post,
    Endpoint.Url.get_month_hindu_calendar,
    params,
    completion,
  );
};

const fetchReceipt = async (url, params, completion) => {
  ApiManager.callAPI(ApiManager.RequestType.get, url, params, completion);
};

//---CashFree
const cashfreeCreateOrder = async (params, completion) => {
  ApiManager.callAPI(
    ApiManager.RequestType.post,
    Endpoint.Url.pg_create_order,
    params,
    completion,
  );
};

const atomCreateOrder = async (params, completion) => {
  ApiManager.callAPI(
    ApiManager.RequestType.post,
    Endpoint.Url.pg_create_order,
    params,
    completion,
  );
};

export default {
  callVerifyMobileNumberAPI,
  getPieChartTotalDonationAPI,
  getBarChartTotalDonationAPI,
  callRefreshTokenForFCMAPI,
  getBusinessTypes,
  callLoginUserAPI,
  getTopDonars,
  cashfreeCreateOrder,
  getNotificationList,
  notificationSeen,
  callLogoutUserAPI,
  getTrustFacility,
  getTrustType,
  callRegisterUserAPI,
  callRefreshTokenAPI,
  callGetTrustListApiAPI,
  callGetLanguageList,
  callSetUserLanguageAPI,
  callGetUserDetailsAPI,
  callCategoryDonation,
  callGetAllNews,
  callGetSubCategoryListApi,
  callCreateInquiry,
  getExclusiveDetails,
  getNewsDetails,
  callJinvaniChaptersPageList,
  callApnamandirFrames,
  callJinvaniChaptersList,
  callCreateApnamandir,
  callUpdateProfileImage,
  callGetMediaAudio,
  callGetApnamandirGodList,
  getTodayExclusiveRandom,
  callGetTodayExclusive,
  callGetAllEvent,
  callSubscribe,
  callJinvaniList,
  getEventDetails,
  callGlobalSearch,
  getPunyarjak,
  callDeleteAccount,
  callCreateGetHelpRequest,
  callCreateFeedback,
  callGetAdvertisementDetails,
  callGetAdvertisement,
  callGetDonatedTrustListApi,
  getEventList,
  callGetNoticesList,
  callGetTrustNews,
  callGetTrustEvents,
  callUnsubscribe,
  callDispute,
  callAddPancard,
  callUpdatePreference,
  callMakeCommitmentPayment,
  callGetEventDates,
  callJinvaniCategoriesList,
  callCityAndState,
  callGetTrustDetail,
  callMakeDonation,
  callUpdateDonation,
  callMarkDeleteDonation,
  callInsightTotalDonationAmount,
  callGetTrustSubscriptionsList,
  callGetMediaVideos,
  callGetUserMandir,
  callGetTodaysExclusive,
  callGetCommitmentList,
  callSaveUserProfileDetails,
  getJinvaniCategory,
  getAppPreferenceAPI,
  switch_all_notification,
  switch_account_notification,
  switch_promotional_notification,
  getTrustNotice,
  getPunyarjakDetail,
  sociaLogin,
  ownHinduCalendar,
  deleteAccount,
  getHinduMonthCalendr,
  fetchReceipt,
  forceUpdate,
  verifyMail,
  atomCreateOrder,
};
