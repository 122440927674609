import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import { english } from './LanguagesText/EnglishStrings';
import { hindi } from './LanguagesText/HindiStrings';
import { gujarati } from './LanguagesText/GujaratiStrings';
import { marathi } from './LanguagesText/MarathiStrings';
import { tamil } from './LanguagesText/TamilStrings';
import { kannada } from './LanguagesText/KannadaStrings';

const resources = {
  en: { translation: english },
  hi: { translation: hindi },
  gu: { translation: gujarati },
  mr: { translation: marathi },
  ta: { translation: tamil },
  kn: { translation: kannada },
};

i18n
  .use(LanguageDetector) // Automatically detect user language
  .use(initReactI18next) // Bind i18next to React
  .init({
    resources,
    fallbackLng: 'en', // Default language
    interpolation: {
      escapeValue: false, // React already escapes by default
    },
  });

export default i18n;
