import { AuthService } from "../services/authService";
import store from "../redux/store";
import {
  setTokens,
  logOut,
  selectRefreshToken,
  selectAccessToken,
} from "../redux/slices/authSlice";
import { toast } from "react-toastify";
import axios from "axios";
import { API_AUTH_URL } from "./authApiInstance";
const trustId = localStorage.getItem("trustId");

export const extractDataFromResponse = ({
  response,
  successCode = 200,
  showSuccessToast = true,
  showErrorToast = true,
}) => {
  if (response.status === successCode || response.status === 201) {
    if (showSuccessToast && response.data.message) {
      toast.success(response.data.message);
    }
    return response.data;
  }

  if (showErrorToast) {
    toast.error(response.data.message || "An error occurred");
  }

  return { error: true };
};
export const refreshTokenRequest = async ({ refreshToken, axiosInstance }) => {
  try {
    const response = await axiosInstance.post(
      `${API_AUTH_URL}auth/refresh-token`,
      { refreshToken }
    );
    return extractDataFromResponse({
      response,
      showErrorToast: false,
      showSuccessToast: false,
    });
  } catch (error) {
    return parseApiErrorResponse({
      error,
      showToast: false,
    });
  }
};
// Parse API error response
export const parseApiErrorResponse = ({ error, showToast = true }) => {
  if (showToast) {
    const errorMessage =
      error.response?.data?.message || "An unexpected error occurred";
    toast.error(errorMessage);
  }
  return { error: true };
};

const fcm_token = localStorage.getItem("fcm_token");
export const defaultHeaders = {
  "Access-Control-Allow-Origin": "*",
  "device-type": "android",
  "device-name": "1234567890",
  "device-token": fcm_token,
  "is-debug": "0",
  "device-id": "12345678912",
  "app-version": "1.0",
  "device-os-version": "10",
  environment: "development",
  "locale-code": "en",
  tm: "",
  "app-signature": "",
  merchant: "623d6ab8cb6c0f2dab19d390",
  location: "623d6ab8cb6c0f2dab19d391",
  "os-version": "11.2",
};
// Main API call handler with token refresh
export const callApi = async ({
  requestFunction,
  successCode = 200,
  showToastOnSuccess = true,
  showToastOnError = true,
  callRefreshTokenOnAuthError = true,
  refreshSuccessRestFail = false,
}) => {
  const state = store.getState();
  const accessToken = state.auth.accessToken;

  const headers = {
    Authorization: `Bearer ${accessToken}`,
    "x-dharmshala-id": trustId
  };

  const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL_APP,
    headers,
    timeout: 10000,
  });

  try {
    const response = await requestFunction(axiosInstance);
    return extractDataFromResponse({
      response,
      successCode,
      showSuccessToast: showToastOnSuccess,
      showErrorToast: showToastOnError,
    });
  } catch (error) {
    // Handle network errors
    if (error.code === "ECONNABORTED") {
      toast.error("Please check your internet connection");
      return { error: true };
    }

    // Handle authentication errors
    if (
      error.response &&
      (error.response.status === 401 || error.response.data.code === 401)
    ) {
      if (callRefreshTokenOnAuthError) {
        const refreshToken = selectRefreshToken(store.getState());

        const refreshTokenResponseData = await refreshTokenRequest({
          axiosInstance,
          refreshToken,
        });
        if (refreshTokenResponseData.error) {
          store.dispatch(logOut());
          return { error: true };
        }
        const newAccessToken = refreshTokenResponseData?.tokens?.access?.token;
        const newRefreshToken =
          refreshTokenResponseData?.tokens?.refresh?.token;
        if (newAccessToken && newRefreshToken) {
          store.dispatch(
            setTokens({
              accessToken: newAccessToken,
              refreshToken: newRefreshToken,
            })
          );

          return callApi({
            requestFunction,
            successCode,
            showToastOnSuccess,
            showToastOnError,
            callRefreshTokenOnAuthError: false,
            refreshSuccessRestFail:
              newAccessToken &&
              newRefreshToken &&
              error?.response?.data?.code === 401,
          });
        }

        return { error: true };
      } else if (refreshSuccessRestFail) {
        store.dispatch(logOut());
        return { error: true };
      }
      return { error: true };
    }

    // Handle other errors
    return parseApiErrorResponse({
      error,
      showToast: showToastOnError,
    });
  }
};
export const callDharmshalaApi = async ({
  requestFunction,
  successCode = 200,
  showToastOnSuccess = true,
  showToastOnError = true,
  callRefreshTokenOnAuthError = true,
  refreshSuccessRestFail = false,
  authErrorCode = 401,
}) => {
  const accessToken = selectAccessToken(store.getState());

  const trustId = localStorage.getItem("trustId");

  const headers = {
    ...defaultHeaders,
    // 'Content-Type': 'application/json',
    Authorization: `Bearer ${accessToken}`,
    "x-dharmshala-id": trustId,
  };
  const axiosInstance = axios.create({
    baseURL: `${process.env.REACT_APP_DHARMSHALA_BASEURL}`,
    headers,
    responseType: "json",
    timeout: 10000,
  });
  const ApiTimeOutToast = {
    toastId: "ApiTimeout",
  };
  if (requestFunction) {
    try {
      const response = await requestFunction(axiosInstance);

      return extractDataFromResponse({
        response,
        successCode,
        showSuccessToast: showToastOnSuccess,
        showErrorToast: showToastOnError,
      });
    } catch (error) {

      if (error.code === "ECONNABORTED") {
        toast.error("Please check your internet connection.", {
          ...ApiTimeOutToast,
        });
      }
      if (error.response) {
        if (
          error.response.status === authErrorCode ||
          error.response.data.code === authErrorCode
        ) {
          if (callRefreshTokenOnAuthError) {
            const refreshToken = selectRefreshToken(store.getState());

            const refreshTokenResponseData = await refreshTokenRequest({
              axiosInstance,
              refreshToken,
            });
            if (refreshTokenResponseData.error) {
              store.dispatch(logOut());
              return { error: true };
            }
            const newAccessToken =
              refreshTokenResponseData?.tokens?.access?.token;
            const newRefreshToken =
              refreshTokenResponseData?.tokens?.refresh?.token;
            if (newAccessToken && newRefreshToken) {
              store.dispatch(
                setTokens({
                  accessToken: newAccessToken,
                  refreshToken: newRefreshToken,
                })
              );

              return callApi({
                requestFunction,
                successCode,
                showToastOnSuccess,
                showToastOnError,
                callRefreshTokenOnAuthError: false,
                refreshSuccessRestFail:
                  newAccessToken &&
                  newRefreshToken &&
                  error?.response?.data?.code === 401,
              });
            }

            return { error: true };
          } else if (refreshSuccessRestFail) {
            store.dispatch(logOut());
            return { error: true };
          }
          return { error: true };
        }

        return parseApiErrorResponse({
          error,
          showToast: showToastOnError,
        });
      }
    }
  }
  return { error: true };
};