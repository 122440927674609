import React, { useEffect, useMemo, useState } from "react";
import { Table, Typography, Spin, Empty, Modal, Card, Pagination } from "antd";
import { useMutation, useQuery } from "@tanstack/react-query";
import { getAllDonation, getDonation } from "../utility/commonApi";
import dayjs from "dayjs";
import { useSelector } from "react-redux";
import { ConvertFirstLatterToCapital } from "../utility";
import moment from "moment";
import { useTranslation } from "react-i18next";
import avtarIcon from "../assets/icons/defaultAvatar.svg";
import receiptIcon from "../assets/icons/receiptIcon.svg";
import whatsappIcon from "../assets/icons/whatsappIcon.svg";
import { toast } from "react-toastify";
import { Spinner, Button } from "reactstrap";
import { downloadFile } from "../utility/commonDownloadS3Image";

const { Title } = Typography;

const DonationReceiptsTable = () => {
  const REACT_APP_BASEURL_PUBLIC = process.env.REACT_APP_API_BASE_URL_PUBLIC;
  const { t } = useTranslation();
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [isIconLoading, setIsIconLoading] = useState(false);
  const selectedLang = useSelector((state) => state.auth.selectLang);
  const loggedTemple = useSelector((state) => state.auth.trustDetail);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [modalVisible, setModalVisible] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);
  const [pagination, setPagination] = useState({
    page: 1,
    limit: 10,
  });
  useEffect(() => {
    setPagination((prev) => ({
      ...prev,
      page: 1,
    }));
  }, []);
  const permissions = useSelector(
    (state) => state.auth.userDetail?.permissions
  );
  const allPermissions = permissions?.find(
    (permissionName) => permissionName.name === "all"
  );
  const subPermissions = permissions?.find(
    (permissionName) => permissionName.name === "donation"
  );

  const subPermission = subPermissions?.subpermissions?.map(
    (item) => item.name
  );
  const { data, isLoading } = useQuery({
    queryKey: ["donationReceipts", pagination.page, pagination.limit],
    queryFn: () => getAllDonation({ ...pagination }),
    keepPreviousData: true,
  });

  const donationReceiptsData = useMemo(() => {
    return data?.data?.results || [];
  }, [data]);
  const totalItems = data ? data?.data?.totalResult : 0;

  const shareReceiptOnWhatsApp = (item, receiptLink) => {
    const message = `Hello ${
      item.donarName
    }, thank you for your donation of ₹${item.amount.toLocaleString(
      "en-IN"
    )} to ${loggedTemple?.name}. Here is your receipt: ${receiptLink}`;
    const phoneNumber = `${item.user?.countryCode?.replace("+", "") || ""}${
      item.user?.mobileNumber || ""
    }`;
    window.open(
      `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`,
      "_blank"
    );
  };

  // const handleWhatsAppShare = (item) => {
  //   setIsIconLoading(item._id);
  //   getReceiptForWhatsApp.mutate({
  //     donationId: item._id,
  //     languageId: selectedLang.id,
  //   });
  // };

  // const getReceiptForWhatsApp = useMutation({
  //   mutationFn: (payload) => {
  //     return getDonation(payload);
  //   },
  //   onSuccess: (data, variables) => {
  //     setIsIconLoading(false);
  //     if (data.result && data.result.receiptLink) {
  //       if (data.result._id === variables.donationId) {
  //         shareReceiptOnWhatsApp(data.result, data.result.receiptLink);
  //       } else {
  //         toast.error("Donation not found for the provided donation ID");
  //       }
  //     } else {
  //       toast.error("Receipt link not available at this moment");
  //     }
  //   },

  //   onError: () => {
  //     setIsIconLoading(false);
  //     toast.error("Failed to get receipt link");
  //   },
  // });

  const downloadReceiptz = useMutation({
    mutationFn: (payload) => {
      return getDonation(payload);
    },
    onSuccess: (data) => {
      if (!data.error) {
        setIsIconLoading(false);
        if (data.result.receiptLink) {
          window.open(`${data.result.receiptLink}`, "_blank");
        } else {
          toast.error("Receipt link not available at this moment");
        }
      }
    },
  });
  const downloadReceipt = useMutation({
    mutationFn: async (item) => {
      if (!item.receiptName) {
        throw new Error("Receipt file path is missing");
      }
      return downloadFile(`donation/${item.receiptName}`);
    },
    onSuccess: (blob) => {
      const url = window.URL.createObjectURL(
        new Blob([blob], { type: "application/pdf" })
      );
      window.open(url, "_blank");
      toast.success("Receipt opened successfully");
    },
    onError: () => {
      toast.error("Failed to open the receipt");
    },
  });
  const handleWhatsAppShare = async (item) => {
    if (!item.receiptName) {
      toast.error("Receipt file path is missing");
      return;
    }

    setIsIconLoading(item._id);

    try {
      const receiptDownloadLink = `${REACT_APP_BASEURL_PUBLIC}storage/download/donation/${item.receiptName}`;

      const message = `Hello ${
        item.donarName
      }, thank you for your donation of ₹${item.amount.toLocaleString(
        "en-IN"
      )} to ${
        loggedTemple?.name
      }. You can download your receipt here: ${receiptDownloadLink}`;

      const phoneNumber = `${item.user?.countryCode?.replace("+", "") || ""}${
        item.user?.mobileNumber || ""
      }`;

      window.open(
        `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`,
        "_blank"
      );

      setIsIconLoading(false);
    } catch (error) {
      console.error("Error sharing receipt:", error);
      toast.error("Failed to send receipt via WhatsApp");
      setIsIconLoading(false);
    }
  };
  const Donatio_data = useMemo(() => {
    return donationReceiptsData.map((item, idx) => {
      return {
        id: idx + 1,
        username: (
          <div className="d-flex align-items-center">
            <div style={{ position: "relative", display: "inline-block" }}>
              <img
                src={
                  item?.user?.profilePhoto !== "" && item?.user?.profilePhoto
                    ? item?.user?.profilePhoto
                    : avtarIcon
                }
                style={{
                  marginRight: "5px",
                  width: "30px",
                  height: "30px",
                }}
                className="rounded-circle"
              />
              <span
                style={{
                  position: "absolute",
                  top: "0",
                  left: "0",
                  width: "10px",
                  height: "10px",
                  borderRadius: "50%",
                  backgroundColor:
                    item?.paidStatus === "Paid"
                      ? "#79BB43"
                      : item?.paidStatus === "Pending"
                      ? "#F3B64B"
                      : "#EC5B52",
                }}
              ></span>
            </div>
            <div>{ConvertFirstLatterToCapital(item?.user?.name ?? "")}</div>
          </div>
        ),
        mobileNumber: `+${item?.user?.countryCode?.replace("+", "") ?? "91"} ${
          item?.user?.mobileNumber
        }`,
        donarName: ConvertFirstLatterToCapital(
          item?.donarName ?? item.user?.name
        ),
        category: (
          <div>
            {ConvertFirstLatterToCapital(item?.masterCategory?.name ?? "-")}
            {/* {item?.subCategory && `(${item?.subCategory?.name ?? ""})`} */}
          </div>
        ),
        subCategory: ConvertFirstLatterToCapital(item?.category?.name ?? "-"),
        dateTime: moment(item.createdAt ?? item?.updatedAt).format(
          " DD MMM YYYY,hh:mm A"
        ),
        originalAmount: (
          <div>
            {item?.originalAmount
              ? `₹${item?.originalAmount.toLocaleString("en-IN")}`
              : "-"}
          </div>
        ),
        amount: <div>₹{item?.amount.toLocaleString("en-IN")}</div>,
        commitmentID: item.commitmentId
          ? item.commitmentId < 10
            ? `0${item.commitmentId}`
            : `${item.commitmentId}`
          : "_",
        receiptNo: `${item.receiptNo}`,
        createdBy: ConvertFirstLatterToCapital(item?.createdBy?.name ?? "-"),
        modeOfPayment: ConvertFirstLatterToCapital(item?.paymentMethod ?? "-"),
        bankName: ConvertFirstLatterToCapital(item?.bankName ?? "-"),
        chequeNum: ConvertFirstLatterToCapital(item?.chequeNum ?? "-"),
        chequeDate: item.chequeDate
          ? moment(item.chequeDate).format(" DD MMM YYYY,hh:mm A")
          : "-",
        chequeStatus: ConvertFirstLatterToCapital(item?.chequeStatus ?? "-"),
        bankNarration: ConvertFirstLatterToCapital(item?.bankNarration ?? "-"),
        articleType: ConvertFirstLatterToCapital(item?.articleType ?? "-"),
        articleItem: ConvertFirstLatterToCapital(item?.articleItem ?? "-"),
        articleWeight: ConvertFirstLatterToCapital(item?.articleWeight ?? "-"),
        articleUnit: ConvertFirstLatterToCapital(item?.articleUnit ?? "-"),
        articleQuantity: ConvertFirstLatterToCapital(
          item?.articleQuantity ?? "-"
        ),
        articleRemark: ConvertFirstLatterToCapital(item?.articleRemark ?? "-"),
        receipt: (
          <div className="d-flex align-items-center">
            {isIconLoading === item?._id ? (
              <Spinner color="success" />
            ) : (
              <img
                src={receiptIcon}
                width={25}
                className="cursor-pointer me-2"
                style={{cursor:"pointer"}}
                onClick={() => downloadReceipt.mutate(item)}
              />
            )}
            <img
              src={whatsappIcon}
              width={25}
              className="cursor-pointer"
              style={{cursor:"pointer"}}
              onClick={() => handleWhatsAppShare(item)}
            />
          </div>
        ),
      };
    });
  }, [donationReceiptsData, isLoading]);
  if (isLoading) {
    return (
      <div className="d-flex justify-content-center mt-5">
        <Spin tip="Loading donation receipts..." />
      </div>
    );
  }

  if (!donationReceiptsData.length) {
    return <Empty description="No Donation Receipts Available" />;
  }

  const columns = [
    {
      title: "Username",
      dataIndex: "username",
      key: "username",
      render: (text) => text,
      onCell: () => ({
        style: {
          font: "normal normal 700 13px/20px Noto Sans !important",
        },
      }),
      width: !isMobile ? 200 : 170,
      fixed: "left",
    },
    {
      title: "Donor Number",
      dataIndex: "mobileNumber",
      key: "mobileNumber",
      render: (text) => text,
      width: 150,
    },
    {
      title: "Donor Name",
      dataIndex: "donarName",
      key: "donarName",
      render: (text) => text,
      width: 150,
    },
    {
      title: "Receipt No",
      dataIndex: "receiptNo",
      key: "receiptNo",
      render: (text) => text,
      width: 250,
    },
    {
      title: "Category",
      dataIndex: "category",
      key: "category",
      render: (text) => text,
      width: !isMobile ? 150 : 120,
    },
    {
      title: "Sub Category",
      dataIndex: "subCategory",
      key: "subCategory",
      render: (text) => text,
      width: 150,
    },
    {
      title: "Donor Date",
      dataIndex: "dateTime",
      key: "dateTime",
      render: (text) => text,
      width: 180,
    },
    {
      title: "Original Amount",
      key: "originalAmount",
      render: (text) => text,
      width: 150,
    },
    {
      title: "Estimate Amount",
      dataIndex: "amount",
      key: "amount",
      render: (text) => text,
      width: 150,
    },
    {
      title: "Commitment ID",
      dataIndex: "commitmentID",
      key: "commitmentID",
      render: (text) => text,
      width: 180,
    },
    {
      title: "Created By",
      dataIndex: "createdBy",
      key: "createdBy",
      render: (text) => text,
      width: !isMobile ? 150 : 120,
    },
    {
      title: "Mode of Payment",
      dataIndex: "modeOfPayment",
      key: "modeOfPayment",
      render: (text) => text,
      width: 150,
    },
    {
      title: "Bank Name",
      dataIndex: "bankName",
      key: "bankName",
      render: (text) => text,
      width: 180,
    },
    {
      title: "Cheque No",
      dataIndex: "chequeNum",
      key: "chequeNum",
      render: (text) => text,
      width: 180,
    },
    {
      title: "Cheque Date",
      dataIndex: "chequeDate",
      key: "chequeDate",
      render: (text) => text,
      width: 180,
    },
    {
      title: "Cheque Status",
      dataIndex: "chequeStatus",
      key: "chequeStatus",
      render: (text) => text,
      width: 220,
    },
    {
      title: "Bank Narration",
      dataIndex: "bankNarration",
      key: "bankNarration",
      render: (text) => text,
      width: 180,
    },
    {
      title: "Article Type",
      dataIndex: "articleType",
      key: "articleType",
      render: (text) => text,
      width: 180,
    },
    {
      title: "Article Item",
      dataIndex: "articleItem",
      key: "articleItem",
      render: (text) => text,
      width: 180,
    },
    {
      title: "Article Weight",
      dataIndex: "articleWeight",
      key: "articleWeight",
      render: (text) => text,
      width: 180,
    },
    {
      title: "Article Unit",
      dataIndex: "articleUnit",
      key: "articleUnit",
      render: (text) => text,
      width: 180,
    },
    {
      title: "Article Quantity",
      dataIndex: "articleQuantity",
      key: "articleQuantity",
      render: (text) => text,
      width: 180,
    },
    {
      title: "Article Remark",
      dataIndex: "articleRemark",
      key: "articleRemark",
      render: (text) => text,
      width: 180,
    },
    {
      title: "Receipt",
      dataIndex: "receipt",
      key: "receipt",
      render: (text) => text,
      fixed: !isMobile && "right",
      width: 120,
    },
  ];

  const openModal = (record) => {
    setSelectedRecord(record);
    setModalVisible(true);
  };

  // Close Modal
  const closeModal = () => {
    setModalVisible(false);
    setSelectedRecord(null);
  };
  return (
    <div>
      <Title level={3} className="commonTextColor">
        Donation Receipts
      </Title>

      {!isMobile ? (
        // **Desktop View - Table**
        <Table
          dataSource={Donatio_data}
          columns={columns}
          className="commonListTable"
          scroll={{ x: 1200, y: 400 }}
          sticky={{ offsetHeader: 64 }}
          bordered
          pagination={{
            current: pagination.page,
            pageSize: pagination.limit,
            total: totalItems,
            onChange: (page) => setPagination((prev) => ({ ...prev, page })),
            onShowSizeChange: (current, size) =>
              setPagination((prev) => ({
                ...prev,
                limit: size,
                page: 1,
              })),
          }}
        />
      ) : (
        // **Mobile View - Cards**
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            marginBottom: "100px",
          }}
          className="px-3"
        >
          {Donatio_data.map((item) => (
            <>
              <Card
                key={item.id}
                style={{
                  borderRadius: "10px",
                  boxShadow: "0px 2px 8px rgba(0,0,0,0.1)",
                }}
              >
                <p className="d-flex align-items-center">
                  <span className="commonFontSizeColor me-1">Username:</span>
                  {""}
                  <span className="commonTextColor">{item.username}</span>
                </p>
                <p>
                  <span className="commonFontSizeColor">Donor Name:</span>{" "}
                  <span className="commonTextColor">{item.donarName}</span>
                </p>
                <p className="d-flex align-items-center">
                  <span className="commonFontSizeColor me-2">Amount:</span>{" "}
                  <span className="fs-4 bookingIdColor fw-bold">
                    {item.amount}
                  </span>
                </p>
                <div className="d-flex justify-content-center">
                  <Button color="primary" onClick={() => openModal(item)}>
                    Show More
                  </Button>
                </div>
              </Card>
              {isMobile && totalItems > 0 && (
                <Card className="pagination-card">
                  <Pagination
                    current={pagination.page}
                    pageSize={pagination.limit}
                    total={totalItems}
                    onChange={(page) =>
                      setPagination((prev) => ({ ...prev, page }))
                    }
                    onShowSizeChange={(current, size) =>
                      setPagination((prev) => ({
                        ...prev,
                        limit: size,
                        page: 1,
                      }))
                    }
                  />
                </Card>
              )}
            </>
          ))}
        </div>
      )}

      {/* **Mobile View - Modal for Full Details** */}
      <Modal
        open={modalVisible}
        onCancel={closeModal}
        footer={null}
        title={<h3 className="commonTextColor">Donation Details</h3>}
        className="px-3"
        centered
      >
        {selectedRecord && (
          <div>
            <p className="d-flex align-items-center">
              <span className="commonFontSizeColor me-1">Username:</span>
              <span className="commonTextColor commonWordWrap">
                {selectedRecord.username}
              </span>
            </p>

            <p>
              <span className="commonFontSizeColor">Donor Number:</span>{" "}
              <span className="commonTextColor">
                {selectedRecord.mobileNumber}
              </span>
            </p>
            <p>
              <span className="commonFontSizeColor">Donor Name:</span>{" "}
              <span className="commonTextColor commonWordWrap">
                {selectedRecord.donarName}
              </span>
            </p>
            <p className="d-flex  align-items-center">
              <span className="commonFontSizeColor me-1">Amount:</span>
              {""}
              <span className="bookingIdColor fs-4 fw-bold">
                {selectedRecord.amount}
              </span>
            </p>
            <p>
              <span className="commonFontSizeColor">Receipt No:</span>{" "}
              <span className="commonTextColor commonWordWrap">
                {selectedRecord.receiptNo}
              </span>
            </p>
            <p>
              <span className="commonFontSizeColor">Mode of Payment:</span>{" "}
              <span className="commonTextColor">
                {selectedRecord.modeOfPayment}
              </span>
            </p>

            {/* Action Buttons */}
            <div className="d-flex align-items-center justify-content-center mt-3">
              {selectedRecord.receipt}
            </div>
          </div>
        )}
      </Modal>
    </div>
  );
};

export default DonationReceiptsTable;
