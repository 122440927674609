import React, { useEffect, useRef, useState } from "react";
import { Carousel, Card, Button, Row, Col, Skeleton } from "antd";
import moment from "moment";
import "antd/dist/reset.css";
import he from "he";
import { stripHtmlTags } from "./commonDecodeHtmlBody";
import { fetchImage } from "./commonDownloadS3Image";
import placeholderImage from "../assets/icons/placeholderimage.webp";
import "../common.scss";
import calendarImg from "../assets/icons/calendar.svg";
import Meta from "antd/es/card/Meta";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
const NewsCarousel = ({ newsData }) => {
  const carouselRef = useRef();

  if (!newsData || newsData.length === 0) {
    return null; // Don't render anything if there's no data
  }

  return (
    <div className="newsCarouselContainer">
      {newsData.length > 1 && (
        <Button
          type="text"
          shape="circle"
          icon={<LeftOutlined />}
          onClick={() => carouselRef.current.prev()}
          className="carouselButton carouselLeft"
        />
      )}
      <Carousel
        autoplay
        ref={carouselRef}
        slidesToShow={Math.min(4, newsData.length)} // Adjust slidesToShow dynamically
        slidesToScroll={1}
        dotPosition="bottom"
        infinite={newsData.length > 4} // Prevent infinite scroll if there are less than 4 items
        responsive={[
          {
            breakpoint: 1200,
            settings: { slidesToShow: Math.min(3, newsData.length) },
          },
          {
            breakpoint: 992,
            settings: { slidesToShow: Math.min(2, newsData.length) },
          },
          {
            breakpoint: 768,
            settings: { slidesToShow: 1 },
          },
        ]}
      >
        {newsData.map((news) => (
          <div key={news.id}>
            <NewsCard news={news} id={news.id} />
          </div>
        ))}
      </Carousel>
      {newsData.length > 1 && (
        <Button
          type="text"
          shape="circle"
          icon={<RightOutlined />}
          onClick={() => carouselRef.current.next()}
          className="carouselButton carouselRight"
        />
      )}
    </div>
  );
};

const NewsCard = ({ news, id }) => {
  const navigate = useNavigate();

  const { title, body, publishDate } = news;
  const decodedBody = he.decode(body);
  const strippedBody = stripHtmlTags(decodedBody);
  const truncatedBody =
    strippedBody.length > 100
      ? strippedBody.substring(0, 100) + "..."
      : strippedBody;
  const [imageUrls, setImageUrls] = useState({});
  const [isLoading, setIsLoading] = useState(true); // Track loading state

  useEffect(() => {
    if (news && news.images && news.images.length > 0) {
      const loadImages = async () => {
        const urls = await Promise.all(
          news.images.map(async (image) => {
            const url = await fetchImage(image.name);
            return url;
          })
        );
        setImageUrls((prev) => ({ ...prev, [news.id]: urls }));
      };

      loadImages().then(() => setIsLoading(false)); // Stop loading once images are fetched
    } else {
      setIsLoading(false); // No images, stop loading
    }
  }, [news]);
  return (
    <div className="me-3 w-100">
      <Card
        className="cardContainer"
        hoverable
        style={{ height: "250px" }}
        cover={
          isLoading ? (
            <Skeleton.Image active />
          ) : (
            <img
              className="cardImage"
              alt={title}
              src={
                imageUrls[id]?.[0] // If image exists, use it
                  ? imageUrls[id][0]
                  : placeholderImage // Otherwise, use placeholder
              }
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = placeholderImage; // Ensure fallback to placeholder on error
              }}
            />
          )
        }
      >
        <Meta
          title={<div className="cardTitle">{title}</div>}
          description={
            <div className="cardDescription">
              <div>{truncatedBody}</div>
              {strippedBody.length > 100 && (
                <span
                  className="cardReadMore"
                  onClick={() => {
                    const imageSrc = imageUrls[id] && imageUrls[id][0];

                    navigate(`/detail/news/${id}`, {
                      state: { imageSrc },
                    });
                  }}
                >
                  Read more
                </span>
              )}
            </div>
          }
        />
        <div className="cardDate">
          <img src={calendarImg} width={15} height={15} className="me-1" />
          <span>
            {`Publish Date: ${moment(publishDate).format("DD MMM YYYY")}`}
          </span>
        </div>
      </Card>
    </div>
  );
};

export default NewsCarousel;
