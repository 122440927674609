import React, { useEffect, useCallback } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import { usePayment } from "./paymentContext";

const Payment = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const serviceId = localStorage.getItem('serviceId')
  const { htmlPage, merchantDetails, origin = "seva" } = location.state || {};
  const { onPaymentResult } = usePayment();

  const decryptFinalResponse = useCallback(
    async (encData, merchId) => {
      let paymentResult = { success: false, message: "" };
      console.log("Inside decryptFinalResponse with encData");

      if (!encData || encData === "cancelTransaction") {
        console.log("Transaction has been cancelled");
        paymentResult = { success: false, message: "Transaction cancelled" };
        return paymentResult;
      }

      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL || 'https://api.apnadharm.dev'}/v1/app/paymentNew/payment-response`,
          { encData, merchId },
          {
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${localStorage.getItem('token')}`,
              'device-id': 'WEB',
              'device-type': 'web',
              'app-version': '1.0.0',
              'app-environment': process.env.NODE_ENV || 'development',
              'time-zone': new Date().getTimezoneOffset() / -60,
              'language-code': 'en',
              'os-version': navigator.userAgent
            }
          }
        );

        if (response.data.success) {
          const parsedResponse = response.data.data;
          
          if (
            parsedResponse.payInstrument.responseDetails.statusCode === "OTS0000" ||
            parsedResponse.payInstrument.responseDetails.statusCode === "OTS0551"
          ) {
            paymentResult = { 
              success: true, 
              message: "Transaction success",
              data: parsedResponse
            };
          } else {
            paymentResult = { 
              success: false, 
              message: parsedResponse.payInstrument.responseDetails.statusDesc || "Transaction failed",
              error: parsedResponse.payInstrument.responseDetails.statusCode
            };
          }
        } else {
          paymentResult = { success: false, message: response.data.message || "Decryption failed" };
        }
      } catch (e) {
        console.error("Decryption API call failed:", e);
        paymentResult = { 
          success: false, 
          message: "Decryption failed", 
          error: e.message 
        };
      }

      return paymentResult;
    },
    []
  );

  const getNavigationPath = (isSuccess, origin) => {
    console.log(origin)
    if (isSuccess) {
      if(origin==="seva"){
      return "/seva?page=bookingHistory";}
      else if(origin==="donation"){
        return "/seva?page=donation";}
        else if(origin==="accomodation"){
          return "/seva?page=bookings";}
    }

    switch (origin) {
      case "seva":
        return `/seva-book/${serviceId}`;
      case "donation":
        return "/donation";
      case "accomodation":
        return "/seva?page=bookings"; 
    }
  };

  useEffect(() => {
    const handleMessage = async (event) => {
      if (event.data?.type === 'PAYMENT_RESPONSE') {
        const result = event.data.data;
        
        if (result.ndpsResponse) {
          console.log("Processing ndpsResponse:", result.ndpsResponse);
          const decryptResult = await decryptFinalResponse(
            result.ndpsResponse.encData, 
            result.ndpsResponse.merchId
          );
          
          const transactionId = localStorage.getItem('currentPaymentTxnId');
          const trustId = localStorage.getItem('trustId');
          
          if (onPaymentResult) {
            onPaymentResult(decryptResult);
          }
          
          navigate(getNavigationPath(decryptResult.success, origin));
          return;
        }
        
        if (onPaymentResult) {
          onPaymentResult(result);
          
          navigate(getNavigationPath(result.success, origin));
        }
      }
    };

    window.addEventListener('message', handleMessage);
    
    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, [decryptFinalResponse, navigate, onPaymentResult, origin]);

  if (!htmlPage) {
    return (
      <div className="d-flex justify-content-center align-items-center" style={{ height: "100vh" }}>
        <p>Payment gateway not available. Please try again.</p>
      </div>
    );
  }

  return (
    <div style={{ height: "100vh", backgroundColor: "white" }}>
      <iframe
        style={{ width: "100%", height: "100%", border: "none" }}
        srcDoc={htmlPage}
        title="Payment Gateway"
        sandbox="allow-forms allow-scripts allow-same-origin allow-popups allow-popups-to-escape-sandbox allow-top-navigation allow-top-navigation-by-user-activation"
      />
    </div>
  );
};

export default Payment;