// Define the Type enum with the possible environments
export const Type = {
  development: 'DEVELOPMENT',
  staging: 'STAGING',
  production: 'PRODUCTION',
  local: 'LOCAL',
};

// Set the current environment value
export const current = Type.development;  // Example: currently set to development

// Export Type and current for use in other files
export default {
  Type,
  current,
};
