import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useQuery } from "@tanstack/react-query";
import { getPaymentMethods } from "../utility/commonApi";
import Wrapper from "../views/common/commonWrapper";
import { Card, Row, Col, Typography, Spin, QRCode } from "antd";
import icon from "../assets/icons/main-logo-small.png";
import { Button, CardFooter } from "reactstrap";
import { Link, useNavigate } from "react-router-dom";
import { fetchImage } from "../utility/commonDownloadS3Image";
import he from "he";
const { Text } = Typography;
function DonationPage() {
  const userDetails = useSelector((state) => state.auth);
  const donationViaApp = userDetails?.trustData?.donationViaApp;
  const isPaymentPaused = userDetails?.trustData?.isPaymentPaused;
  const navigate = useNavigate();
  const handleDonateClick = () => {
    if (userDetails?.user) {
      navigate("/seva"); // Redirect to seva if logged in
    } else {
      navigate("/login", { state: { from: "/seva" } }); // Redirect to login first
    }
  };
  const trustId = localStorage.getItem("trustId");
  const { data, isLoading } = useQuery({
    queryKey: ["paymentMethods"],
    queryFn: () => getPaymentMethods(),
  });
  const resultData = data?.data?.result || [];
  const [imageUrls, setImageUrls] = useState({});
  useEffect(() => {
    if (resultData && resultData.length > 0) {
      const loadImages = async () => {
        const urls = {};
        await Promise.all(
          resultData.map(async (item) => {
            if (item.image_url) {
              const url = await fetchImage(item.image_url); // ✅ Fetch directly as it's a string
              urls[item._id] = url;
            }
          })
        );
        setImageUrls(urls);
      };

      loadImages();
    }
  }, [resultData]);
  return (
    <Wrapper>
      {donationViaApp ? (
        <div className="container mx-auto mt-5">
          {isLoading ? (
            <div className="flex justify-center items-center min-h-[200px]">
              <Spin size="large" />
            </div>
          ) : (
            <Row gutter={[16, 16]} justify="center">
              {/* Map the first payment method card */}
              {data?.data?.result?.length > 0 && (
                <Col
                  xs={24}
                  sm={12}
                  md={8}
                  lg={6}
                  key={data.data.result[0]._id}
                >
                  <Card
                    className="commonCardShadow flex flex-col h-full commonDonationCard"
                    title={data.data.result[0].method_name}
                    bordered={true}
                    style={{ borderRadius: "10px", height: "100%" }}
                    hoverable
                  >
                    <div className="flex-grow">
                      {/* Bank, UPI, Card, or Wallet Details */}
                      {data.data.result[0].method_type === "BANK" && (
                        <>
                          <Text strong>Account Holder:</Text>
                          <p>{data.data.result[0].account_holder}</p>
                          <Text strong>Bank:</Text>
                          <p>{data.data.result[0].bank_name}</p>
                          <Text strong>Account Number:</Text>
                          <p>{data.data.result[0].account_number}</p>
                          <Text strong>IFS Code:</Text>
                          <p>{data.data.result[0].ifsc_code}</p>
                          <Text strong>Branch:</Text>
                          <p>{data.data.result[0].branch_name}</p>
                        </>
                      )}

                      {/* UPI Details */}
                      {data.data.result[0].method_type === "UPI" && (
                        <>
                          {data.data.result[0].image_url ? (
                            <img
                              src={
                                imageUrls[data.data.result[0]._id]
                                  ? imageUrls[data.data.result[0]._id]
                                  : ""
                              }
                              alt="QR Code"
                              style={{
                                width: "100%",
                                height: "100%",
                                // maxHeight: "150px",
                                objectFit: "contain",
                              }}
                            />
                          ) : (
                            <QRCode
                              value={data.data.result[0].upi_vpa}
                              icon={icon}
                              bordered={false}
                              style={{
                                width: "100%",
                                height: "100%",
                                objectFit: "contain",
                              }}
                              color="black"
                            />
                          )}
                          <Text strong>UPI ID:</Text>
                          <p
                            style={{
                              wordWrap: "break-word",
                              overflowWrap: "break-word",
                            }}
                          >
                            {data.data.result[0].upi_vpa}
                          </p>
                        </>
                      )}

                      {/* CARD Details */}
                      {data.data.result[0].method_type === "CARD" && (
                        <>
                          <Text strong>Card Network:</Text>
                          <p>{data.data.result[0].card_network}</p>
                          <Text strong>Last 4 Digits:</Text>
                          <p>
                            **** **** ****{" "}
                            {data.data.result[0].card_number_last4}
                          </p>
                        </>
                      )}

                      {/* WALLET Details */}
                      {data.data.result[0].method_type === "WALLET" && (
                        <>
                          <Text strong>Wallet Provider:</Text>
                          <p>{data.data.result[0].wallet_provider}</p>
                          <Text strong>Wallet ID:</Text>
                          <p>{data.data.result[0].wallet_id}</p>
                          {data.data.result[0].image_url && (
                            <img
                              src={data.data.result[0].image_url}
                              alt="Wallet QR Code"
                              style={{
                                width: "100%",
                                maxHeight: "150px",
                                objectFit: "contain",
                              }}
                            />
                          )}
                        </>
                      )}
                      <Card.Meta
                        className="mt-5"
                        description={
                          <div
                            dangerouslySetInnerHTML={{
                              __html: he.decode(
                                data.data.result[0].instructions
                              ),
                            }}
                          />
                        }
                      />
                    </div>
                  </Card>
                </Col>
              )}

              {/* Static QR Code Card - Always in the second position */}
              {isPaymentPaused == false && (
                <Col xs={24} sm={12} md={8} lg={6} className="">
                  <Card
                    className="commonCardShadow flex flex-col h-full text-center commonDonationCard"
                    title="Apna Dharm App"
                    bordered={true}
                    hoverable
                    id="scannerCard"
                    style={{ position: "relative" }} // Add this style
                  >
                    <div className="recommendedLabel">Recommended</div>
                    <div>
                      <QRCode
                        value={`https://share.apnadharm.com/app/trust/${trustId}`}
                        icon={icon}
                        bordered={false}
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "contain",
                        }}
                        color="black"
                      />
                    </div>
                    <div style={{ marginTop: "44px" }}>
                      <Card.Meta
                        description={
                          <Text
                            type="secondary"
                            style={{ marginTop: "10px", display: "block" }}
                          >
                            Get Instant automated Receipt{" "}
                          </Text>
                        }
                      />
                    </div>
                  </Card>
                </Col>
              )}

              {/* Map the rest of the payment method cards */}
              {data?.data?.result?.slice(1).map((method) => (
                <Col xs={24} sm={12} md={8} lg={6} key={method._id}>
                  <Card
                    className="commonCardShadow flex flex-col h-full commonDonationCard margin"
                    title={method.method_name}
                    bordered={true}
                    style={{ borderRadius: "10px", height: "100%" }}
                    hoverable
                  >
                    <div className="flex-grow">
                      {/* Bank, UPI, Card, or Wallet Details */}
                      {method.method_type === "BANK" && (
                        <>
                          <Text strong>Account Holder:</Text>
                          <p>{method.account_holder}</p>
                          <Text strong>Bank:</Text>
                          <p>{method.bank_name}</p>
                          <Text strong>Account Number:</Text>
                          <p>{method.account_number}</p>
                          <Text strong>IFS Code:</Text>
                          <p>{method.ifsc_code}</p>
                          <Text strong>Branch:</Text>
                          <p>{method.branch_name}</p>
                        </>
                      )}

                      {/* UPI Details */}
                      {method.method_type === "UPI" && (
                        <>
                          {method.image_url ? (
                            <img
                              src={
                                imageUrls[method._id]
                                  ? imageUrls[method._id]
                                  : ""
                              }
                              alt="QR Code"
                              style={{
                                width: "100%",
                                height: "100%",
                                // maxHeight: "150px",
                                objectFit: "contain",
                              }}
                            />
                          ) : (
                            <QRCode
                              value={method.upi_vpa}
                              icon={icon}
                              bordered={false}
                              style={{
                                width: "100%",
                                height: "100%",
                                objectFit: "contain",
                              }}
                              color="black"
                            />
                          )}
                          <Text strong>UPI ID:</Text>
                          <p
                            style={{
                              wordWrap: "break-word",
                              overflowWrap: "break-word",
                            }}
                          >
                            {method.upi_vpa}
                          </p>
                        </>
                      )}

                      {/* CARD Details */}
                      {method.method_type === "CARD" && (
                        <>
                          <Text strong>Card Network:</Text>
                          <p>{method.card_network}</p>
                          <Text strong>Last 4 Digits:</Text>
                          <p>**** **** **** {method.card_number_last4}</p>
                        </>
                      )}

                      {/* WALLET Details */}
                      {method.method_type === "WALLET" && (
                        <>
                          <Text strong>Wallet Provider:</Text>
                          <p>{method.wallet_provider}</p>
                          <Text strong>Wallet ID:</Text>
                          <p>{method.wallet_id}</p>
                          {method.image_url && (
                            <img
                              src={method.image_url}
                              alt="Wallet QR Code"
                              style={{
                                width: "100%",
                                maxHeight: "150px",
                                objectFit: "contain",
                              }}
                            />
                          )}
                        </>
                      )}
                    </div>
                    {method.instructions && (
                      <Card.Meta
                        className="mt-5"
                        description={
                          <div
                            dangerouslySetInnerHTML={{
                              __html: he.decode(method.instructions),
                            }}
                          />
                        }
                      />
                    )}
                  </Card>
                </Col>
              ))}
            </Row>
          )}
        </div>
      ) : (
        <div>
          <Card className="d-flex align-items-center me-1 justify-content-center text-center">
            <Button color="primary" onClick={handleDonateClick}>
              Donate Now
            </Button>
            {/* Instruction Text */}
            <div className="mt-3">
              <small className=" infoText">
                Click the button to proceed with your donation.
              </small>
            </div>
          </Card>
        </div>
      )}
    </Wrapper>
  );
}

export default DonationPage;
