import React, { useState, useMemo, useEffect } from "react";
import {
  Table,
  Typography,
  Spin,
  Empty,
  Tabs,
  message,
  Card,
  Pagination,
} from "antd";
import { useNavigate } from "react-router-dom";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import {
  getAllBookings,
  updateBookingPayment,
  getAllTrustDetail,
} from "../utility/commonApi";
import dayjs from "dayjs";
import { Trans } from "react-i18next";
import { Button } from "antd";
import { useSelector } from "react-redux";
import { usePayment } from "../components/DonationPayment/paymentContext";
import PaymentHelper from "../components/DonationPayment/paymentHelper";
import { AtomPayment } from "../Helper/AtomPayment";

const { TabPane } = Tabs;

const BookingTable = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const userDetails = useSelector((state) => state.auth.user);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 10;

  const [showPastRequests, setShowPastRequests] = useState(false);
  const [isPaymentLoading, setIsPaymentLoading] = useState(false);
  const [currentBookingForPayment, setCurrentBookingForPayment] =
    useState(null);
  const { updatePaymentResultCallback } = usePayment();
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const { data, isLoading } = useQuery({
    queryKey: ["bookings", currentPage],
    queryFn: () =>
      getAllBookings({
        page: currentPage,
        limit: pageSize,
      }),
    keepPreviousData: true,
  });
  const totalBookings = data?.data?.totalResults || 0;
  const onChangePage = (page) => {
    setCurrentPage(page);
  };
  const trustId = localStorage.getItem("trustId");
  const filteredBookingListData = useMemo(() => {
    if (!data?.data?.results) return [];

    const currentDate = dayjs().startOf("day");
    const dateFormat = "DD-MM-YYYY";

    return data.data.results.filter((item) => {
      const startDate = dayjs(item.startDate, dateFormat);
      const endDate = dayjs(item.endDate, dateFormat);
      return showPastRequests
        ? startDate.isBefore(currentDate)
        : startDate.isAfter(currentDate) ||
            startDate.isSame(currentDate) ||
            endDate.isAfter(currentDate) ||
            endDate.isSame(currentDate);
    });
  }, [data, showPastRequests]);

  const handlePaymentInitiation = async (booking) => {
    try {
      if (!booking) {
        message.error("Invalid booking details");
        return;
      }

      let txnId =
        Date.now().toString(36) + Math.random().toString(36).substr(2, 5);

      const bookingAmount =
        booking.calculatedFields?.totalDue ||
        booking.payment?.totalAmount?.roomrent ||
        booking.rooms?.[0]?.amount ||
        booking.calculatedFields?.totalAmount ||
        0;

      const dharmshalaDetails = booking.dharmshalaId || {};
      const bookingUserDetails = booking.userDetails || {};
      if (bookingAmount <= 0) {
        message.error("Invalid booking amount");
        return;
      }

      setIsPaymentLoading(true);
      setCurrentBookingForPayment(booking);

      const trustDetails = await getAllTrustDetail({ trustId });
      const trustData = trustDetails.data.result;
      const vendorId = trustData.vendorId;

      const pgConfig = dharmshalaDetails.pgConfig || {};

      const merchantDetails = {
        merchantId: vendorId,
        password: pgConfig.password || "",
        productId: pgConfig.productId || "",
        req_enc_key: pgConfig.req_enc_key || "",
        res_enc_key: pgConfig.res_enc_key || "",
        response_hash_key: pgConfig.response_hash_key || "",

        custFirstName: (
          userDetails?.name ||
          bookingUserDetails?.name ||
          dharmshalaDetails?.name ||
          "Guest"
        ).trim(),

        custEmailId: userDetails?.email || bookingUserDetails?.email || "",

        custMobileNumber: (
          userDetails?.mobileNumber ||
          bookingUserDetails?.mobileNumber ||
          dharmshalaDetails?.contact ||
          ""
        ).toString(),

        txnDate: new Date().toISOString(),
        merchTxnId: txnId,
        amount: bookingAmount.toString(),
        udf1: booking.bookingId || "",
        udf2: "booking",
        udf3: dharmshalaDetails?._id || "",
        udf4: "",
        udf5: "",
        paymentMode: "NB",
        mode: "uat",
      };

      const apiParams = {
        customerId:
          userDetails?.userId ??
          userDetails?.id ??
          bookingUserDetails?.userId ??
          bookingUserDetails?.id ??
          "",

        customerPhone: (
          userDetails?.mobileNumber ||
          bookingUserDetails?.mobileNumber ||
          ""
        ).toString(),

        customerEmail: userDetails?.email || bookingUserDetails?.email || "",

        orderAmount: bookingAmount.toString(),
        orderCurrency: "INR",
        vendorId: vendorId,
        orderId: txnId,
        amount: bookingAmount,
        trustId: trustId,
        bookingId: booking.bookingId || "",
        transactionId: txnId,
      };

      console.log("Payment Initiation Debug:", {
        bookingAmount,
        vendorId,
        trustId,
        merchantDetails,
        apiParams,
      });

      if (!vendorId) {
        throw new Error("Cannot find a valid vendor ID for payment");
      }

      if (!bookingAmount || bookingAmount <= 0) {
        throw new Error("Invalid booking amount for payment");
      }

      const { atomTokenId } = await AtomPayment.createOrder(apiParams);

      if (atomTokenId) {
        const ndps = new PaymentHelper();
        const htmlPage = ndps.openAipayPopUp(atomTokenId, merchantDetails);

        updatePaymentResultCallback((result) => {
          handlePaymentResult(result, booking, txnId);
        });

        localStorage.setItem("currentPaymentTxnId", txnId);
        localStorage.setItem(
          "currentPaymentBookingId",
          booking.bookingId || ""
        );

        navigate("/payment", {
          state: {
            htmlPage,
            merchantDetails: {
              ...merchantDetails,
              res_enc_key: merchantDetails.res_enc_key,
              response_hash_key: merchantDetails.response_hash_key,
            },
            origin: "accomodation",
          },
        });
      } else {
        throw new Error("Failed to get atom token");
      }
    } catch (error) {
      console.error("Detailed Payment Initiation Error:", {
        message: error.message,
        stack: error.stack,
        bookingId: booking?.bookingId,
        booking: JSON.stringify(booking, null, 2),
        userDetails: JSON.stringify(userDetails, null, 2),
      });

      message.error(`Payment initiation failed: ${error.message}`);

      setIsPaymentLoading(false);
    }
  };

  const handlePaymentResult = async (result, booking, txnId) => {
    try {
      setIsPaymentLoading(false);

      if (result.success) {
        // Prepare update payload
        const updatePayload = {
          bookingId: booking.bookingId,
          transactionId: txnId,
          status: "paidFromPortfolio",
          amountPaid: booking.calculatedFields.totalDue,
          trustId: booking.trustDetails?._id,
          paymentDetails: {
            type: "deposit",
          },
        };

        const response = await updateBookingPayment(updatePayload, booking._id);

        if (response?.data && response?.data?._id) {
          message.success("Payment successful!");
          queryClient.invalidateQueries(["bookings"]);
        } else {
          message.error("Payment successful, but booking update failed.");
        }
      } else {
        message.error(result.message || "Payment failed");
      }
    } catch (error) {
      console.error("Payment result handling error:", error);
      message.error("An error occurred while processing payment");
    }
  };

  if (isLoading) {
    return (
      <div className="d-flex justify-content-center mt-5">
        <Spin tip="Loading bookings..." />
      </div>
    );
  }

  // if (!filteredBookingListData.length) {
  //   return <Empty description="No Booking Data Available" />;
  // }
  const dataSource = filteredBookingListData.map((booking) => ({
    key: booking.bookingId,
    ...booking,
  }));

  const columns = [
    {
      title: "Booking ID",
      dataIndex: "bookingId",
      key: "bookingId",
      render: (text) => <strong>{text}</strong>,
      fixed: "left",
    },
    {
      title: "Guest Name",
      dataIndex: ["userDetails", "name"],
      key: "guestName",
    },
    {
      title: "Mobile Number",
      dataIndex: ["userDetails", "mobileNumber"],
      key: "mobileNumber",
    },
    {
      title: "Start Date",
      dataIndex: "startDate",
      key: "startDate",
      render: (text) =>
        dayjs(text, "DD-MM-YYYY").isValid()
          ? dayjs(text, "DD-MM-YYYY").format("DD MMM YYYY")
          : "-",
    },
    {
      title: "End Date",
      dataIndex: "endDate",
      key: "endDate",
      render: (text) =>
        dayjs(text, "DD-MM-YYYY").isValid()
          ? dayjs(text, "DD-MM-YYYY").format("DD MMM YYYY")
          : "-",
    },
    {
      title: "Amount",
      dataIndex: ["calculatedFields", "totalDue"],
      key: "totalDue",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      fixed: "right",
      render: (text, record) => (
        <div>
          <span
            style={{
              color:
                text === "requested"
                  ? "orange"
                  : text === "accepted"
                  ? "green"
                  : text === "paid"
                  ? "blue"
                  : "red",
              marginRight: 8,
            }}
          >
            {text.charAt(0).toUpperCase() + text.slice(1)}
          </span>
          {text === "accepted" && (
            <Button
              type="primary"
              size="small"
              onClick={() => handlePaymentInitiation(record)}
              loading={
                isPaymentLoading &&
                currentBookingForPayment?.bookingId === record.bookingId
              }
            >
              Pay Now
            </Button>
          )}
        </div>
      ),
    },
  ];
console.log(filteredBookingListData)
  return (
    <div>
      <Tabs
        defaultActiveKey="1"
        onChange={(key) => setShowPastRequests(key === "2")}
      >
        <Tabs.TabPane tab={<Trans i18nKey="Upcoming Requests" />} key="1">
          {isMobile ? (
            dataSource.map((booking) => (
              <Card key={booking.key} style={{ marginBottom: "10px" }}>
                <p>
                  <span className="commonFontSizeColor">Booking ID:</span>{" "}
                  <span className="commonTextColor">{booking.bookingId}</span>
                </p>
                <p>
                  <span className="commonFontSizeColor">Guest Name:</span>{" "}
                  <span className="commonTextColor">
                    {booking.userDetails?.name || "-"}
                  </span>
                </p>
                <p>
                  <span className="commonFontSizeColor">Mobile Number:</span>{" "}
                  <span className="commonTextColor">
                    {booking.userDetails?.mobileNumber || "-"}
                  </span>
                </p>
                <p>
                  <span className="commonFontSizeColor">Start Date:</span>{" "}
                  <span className="commonTextColor">
                    {dayjs(booking.startDate, "DD-MM-YYYY").isValid()
                      ? dayjs(booking.startDate, "DD-MM-YYYY").format(
                          "DD MMM YYYY"
                        )
                      : "-"}
                  </span>
                </p>
                <p>
                  <span className="commonFontSizeColor">End Date:</span>{" "}
                  <span className="commonTextColor">
                    {dayjs(booking.endDate, "DD-MM-YYYY").isValid()
                      ? dayjs(booking.endDate, "DD-MM-YYYY").format(
                          "DD MMM YYYY"
                        )
                      : "-"}
                  </span>
                </p>
                <p>
                  <span className="commonFontSizeColor">Amount:</span>{" "}
                  <span className="commonTextColor">
                    {booking.calculatedFields?.totalDue || "-"}
                  </span>
                </p>
                <p>
                  <span className="commonFontSizeColor">Status:</span>{" "}
                  <span
                    style={{
                      color:
                        booking.status === "requested"
                          ? "orange"
                          : booking.status === "accepted"
                          ? "green"
                          : booking.status === "paid"
                          ? "blue"
                          : "red",
                    }}
                  >
                    {booking.status.charAt(0).toUpperCase() +
                      booking.status.slice(1)}
                  </span>
                </p>
                {booking.status === "accepted" && (
                  <Button
                    type="primary"
                    size="small"
                    onClick={() => handlePaymentInitiation(booking)}
                    loading={
                      isPaymentLoading &&
                      currentBookingForPayment?.bookingId === booking.bookingId
                    }
                  >
                    Pay Now
                  </Button>
                )}
              </Card>
            ))
          ) : (
            <Table
              dataSource={dataSource}
              columns={columns}
              className="commonListTable"
              scroll={{ x: 1500, y: 400 }}
              sticky={{ offsetHeader: 64 }}
              bordered
              pagination={false}
            />
          )}
        </Tabs.TabPane>

        <Tabs.TabPane tab={<Trans i18nKey="Past Requests" />} key="2">
          {isMobile ? (
            dataSource.map((booking) => (
              <Card key={booking.key} style={{ marginBottom: "10px" }}>
                <p>
                  <span className="commonFontSizeColor">Booking ID:</span>{" "}
                  <span className="commonTextColor">{booking.bookingId}</span>
                </p>
                <p>
                  <span className="commonFontSizeColor">Guest Name:</span>{" "}
                  <span className="commonTextColor">
                    {booking.userDetails?.name || "-"}
                  </span>
                </p>
                <p>
                  <span className="commonFontSizeColor">Mobile Number:</span>{" "}
                  <span className="commonTextColor">
                    {booking.userDetails?.mobileNumber || "-"}
                  </span>
                </p>
                <p>
                  <span className="commonFontSizeColor">Start Date:</span>{" "}
                  <span className="commonTextColor">
                    {dayjs(booking.startDate, "DD-MM-YYYY").isValid()
                      ? dayjs(booking.startDate, "DD-MM-YYYY").format(
                          "DD MMM YYYY"
                        )
                      : "-"}
                  </span>
                </p>
                <p>
                  <span className="commonFontSizeColor">End Date:</span>{" "}
                  <span className="commonTextColor">
                    {dayjs(booking.endDate, "DD-MM-YYYY").isValid()
                      ? dayjs(booking.endDate, "DD-MM-YYYY").format(
                          "DD MMM YYYY"
                        )
                      : "-"}
                  </span>
                </p>
                <p>
                  <span className="commonFontSizeColor">Amount:</span>{" "}
                  <span className="commonTextColor">
                    {booking.calculatedFields?.totalDue || "-"}
                  </span>
                </p>
                <p>
                  <span className="commonFontSizeColor">Status:</span>{" "}
                  <span
                    style={{
                      color:
                        booking.status === "requested"
                          ? "orange"
                          : booking.status === "accepted"
                          ? "green"
                          : booking.status === "paid"
                          ? "blue"
                          : "red",
                    }}
                  >
                    {booking.status.charAt(0).toUpperCase() +
                      booking.status.slice(1)}
                  </span>
                </p>
              </Card>
            ))
          ) : (
            <Table
              dataSource={dataSource}
              columns={columns}
              className="commonListTable"
              scroll={{ x: 1500, y: 400 }}
              sticky={{ offsetHeader: 64 }}
              pagination={false}
              bordered
            />
          )}
        </Tabs.TabPane>
      </Tabs>
      {isMobile && !isLoading && totalBookings > 0 && (
        <Card className="pagination-card">
          <Pagination
            current={currentPage}
            pageSize={pageSize}
            total={totalBookings}
            onChange={onChangePage}
            showSizeChanger={false}
          />
        </Card>
      )}
    </div>
  );
};

export default BookingTable;
