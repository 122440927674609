// CommonLayout.js
import React from "react";
import { Container } from "reactstrap";
import "../../common.scss";
import Nav from "../../components/navbar";
import { useLocation } from "react-router-dom";
const Wrapper = ({ children }) => {
  const location = useLocation();

  // Define page titles based on routes
  const pageTitles = {
    "/": "Home",
    "/about": "About Us",
    "/donation": "Donation",
    "/galleries": "Gallery",
    "/updates": "Latest Updates",
    "/events": "Events",
    "/notices": "Notices",
    // "/seva": "Services",
    "/punyarjak": "Punyarjak",
    "/privacy-policy": "Privacy Policy",
    "/terms-conditions": "Terms & Conditions",
    "/refund-policy": "Cancellation & Refund Policy",
  };
  const path = location.pathname;

  const pageTitle =
    path.startsWith("/seva") && !path.startsWith("/seva-book")
      ? ""
      : path.startsWith("/booking/detail")
      ? ""
      : path.startsWith("/seva-book")
      ? "Service Booking"
      : pageTitles[path] || "Page";

  return (
    <>
      <Nav />
      <div className="main-layout hide-scrollbar">
        <div className="heading_div text-center">{pageTitle}</div>
        <Container>{children}</Container>
      </div>
    </>
  );
};

export default Wrapper;
