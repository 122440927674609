import React, { useEffect, useMemo, useState } from "react";
import { Tabs, Card, Pagination, Row, Col, Empty, Skeleton } from "antd";
import Meta from "antd/es/card/Meta";
import "../../common.scss";
import { useNavigate } from "react-router-dom";
import { getAllNews, getNewsTags } from "../../utility/commonApi";
import { useQuery } from "@tanstack/react-query";
import he from "he";
import Loader from "./loader";
import clockImg from "../../assets/icons/clockIcon.svg";
import calendarImg from "../../assets/icons/calendar.svg";
import moment from "moment";
import { fetchImage } from "../../utility/commonDownloadS3Image";
import placeholderImage from "../../assets/icons/placeholderimage.webp";

const CommonTabs = () => {
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedTag, setSelectedTag] = useState("");
  const pageSize = 8;
  // const startDate = moment().startOf("month").utcOffset(0, true).toISOString();
  // const endDate = moment().endOf("month").utcOffset(0, true).toISOString();

  // Fetching news tags
  const { data: newsTagsData, isLoading: isTagsLoading } = useQuery({
    queryKey: ["newsTags"],
    queryFn: () =>
      getNewsTags({
        englishLanguageId: "6332cbba8054b2cac94da3d1",
        languageId: "",
      }),
    keepPreviousData: true,
  });

  // Fetching news details based on selected tag
  const { data, isLoading } = useQuery({
    queryKey: ["news", currentPage, selectedTag],
    queryFn: () =>
      getAllNews({
        page: currentPage,
        limit: pageSize,
        sortOrder: "DESC",
        search: "",
        tag: selectedTag,
        isPublished: true,
      }),
    keepPreviousData: true,
  });

  const newsItem = useMemo(() => data?.data?.results ?? [], [data]);
  const totalNews = data?.data?.totalResults || 0;
  const onChangePage = (page) => {
    setCurrentPage(page);
  };

  const onTabChange = (key) => {
    setSelectedTag(key);
    setCurrentPage(1);
  };

  const stripHtmlTags = (htmlString) => {
    const doc = new DOMParser().parseFromString(htmlString, "text/html");
    return doc.body.textContent || "";
  };
  const [imageUrls, setImageUrls] = useState({});
  useEffect(() => {
    if (newsItem && newsItem.length > 0) {
      const loadImages = async () => {
        const urls = {};
        await Promise.all(
          newsItem.map((item) => {
            if (item.images && item.images.length > 0) {
              return Promise.all(
                item.images.map(async (image) => {
                  const url = await fetchImage(image.name);
                  urls[item.id] = urls[item.id] || [];
                  urls[item.id].push(url);
                })
              );
            }
          })
        );
        setImageUrls(urls);
      };

      loadImages();
    }
  }, [newsItem]);
  // Map tags to tabs
  const tagTabs =
    newsTagsData?.data?.results?.map((tag) => ({
      key: tag.tag,
      label: tag.tag,
      children: (
        <div>
          {isLoading ? (
            <Loader />
          ) : newsItem.length > 0 ? (
            <div style={{ paddingBottom: "80px" ,marginBottom:"50px"}}>
              <Row gutter={[{ xs: 16, sm: 16, md: 24, lg: 32 }, 16]}>
                {newsItem.map((item) => {
                  const decodedBody = he.decode(item.body);
                  const strippedBody = stripHtmlTags(decodedBody);
                  const truncatedBody =
                    strippedBody.length > 100
                      ? strippedBody.substring(0, 100) + "..."
                      : strippedBody;

                  return (
                    <Col
                      xs={24}
                      sm={12}
                      md={8}
                      lg={6}
                      key={item.id}
                      className="cardColumn"
                    >
                      <Card
                        className="cardContainer"
                        hoverable
                        style={{ width: "100%" }}
                        onClick={() => {
                          const imageSrc =
                            imageUrls[item.id] && imageUrls[item.id][0];

                          navigate(`/detail/news/${item.id}`, {
                            state: { imageSrc },
                          });
                        }}
                        cover={
                          <>
                            {imageUrls && imageUrls[item.id] ? (
                              <img
                                className="cardImage"
                                alt={item.title}
                                src={
                                  (imageUrls[item.id] &&
                                    imageUrls[item.id][0]) ||
                                  placeholderImage
                                }
                              />
                            ) : (
                              <Skeleton.Image active />
                            )}
                          </>
                        }
                      >
                        <Meta
                          title={<div className="cardTitle">{item.title}</div>}
                          description={
                            <div className="cardDescription">
                              <div>{truncatedBody}</div>
                              {strippedBody.length > 100 && (
                                <span
                                  className="cardReadMore"
                                  onClick={() => {
                                    const imageSrc =
                                      imageUrls[item.id] &&
                                      imageUrls[item.id][0];

                                    navigate(`/detail/news/${item.id}`, {
                                      state: { imageSrc },
                                    });
                                  }}
                                >
                                  {" "}
                                  Read more
                                </span>
                              )}
                            </div>
                          }
                        />
                        <div className="cardDate">
                          <img
                            src={calendarImg}
                            width={15}
                            height={15}
                            className="me-1"
                          />
                          <span>
                            {`Publish Date: ${moment(item.publishDate).format(
                              "DD MMM YYYY"
                            )}`}
                          </span>
                        </div>
                      </Card>
                    </Col>
                  );
                })}
              </Row>
            </div>
          ) : (
            <Empty description="No Record Found" style={{ marginTop: 20 }} />
          )}
          {!isLoading && totalNews > 0 && (
            <Card className="pagination-card">
              <Pagination
                current={currentPage}
                pageSize={pageSize}
                total={totalNews}
                onChange={onChangePage}
                showSizeChanger={false}
              />
            </Card>
          )}
        </div>
      ),
    })) || [];
  useEffect(() => {
    if (newsTagsData?.data?.results?.length > 0 && !selectedTag) {
      setSelectedTag(newsTagsData.data.results[0].tag); // Default to the first tag
    }
  }, [newsTagsData, selectedTag]);
  return (
    <Tabs
      defaultActiveKey=""
      items={isTagsLoading ? [] : tagTabs}
      onChange={onTabChange}
    />
  );
};

export default CommonTabs;
