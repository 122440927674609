import React, { useEffect, useMemo, useState } from "react";
import "../common.scss";
import {
  Card,
  Row,
  Col,
  Empty,
  Tabs,
  Typography,
  Pagination,
  Badge,
  Tag,
} from "antd";
import { Button } from "reactstrap";
import Meta from "antd/es/card/Meta";
import { useNavigate } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { getAllSeva } from "../utility/commonApi";
import Loader from "../views/common/loader";
import he from "he";
import { stripHtmlTags } from "../utility/commonDecodeHtmlBody";
import backIcon from "../assets/icons/back.svg";
import kalashIcon from "../assets/icons/kalash.png";
import { fetchImage } from "../utility/commonDownloadS3Image";

function ServiceList({
  // setSevaList,
  setBookingSeva,
  hasUser = true,
  setSelectedSeva,
}) {
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 8;
  const { data, isLoading } = useQuery({
    queryKey: ["service", currentPage],
    queryFn: () => getAllSeva({ page: currentPage, limit: pageSize }),
    keepPreviousData: true,
  });
  // Memoizing the fetched data
  const sevaData = useMemo(() => data?.data?.results ?? [], [data]);
  const totalSeva = data?.data?.totalResults || 0;
  const onChangePage = (page) => {
    setCurrentPage(page);
  };

  const getTagColor = (frequency) => {
    switch (frequency) {
      case "Daily":
        return "green";
      case "Weekly":
        return "blue";
      case "Monthly":
        return "purple";
      default:
        return "gray";
    }
  };
  const handleBookingClick = (sevaId, sevaRecord) => {
    if (hasUser) {
      navigate(`/seva-book/${sevaId}`, { state: sevaRecord });
      setSelectedSeva(sevaRecord);
      // setSevaList(false);
      setBookingSeva(true);
    } else {
      navigate("/login", {
        state: { from: `/seva-book/${sevaId}`, sevaRecord: sevaRecord },
      });
    }
  };
  const [imageUrls, setImageUrls] = useState({});

  useEffect(() => {
    if (sevaData.length > 0) {
      const loadImages = async () => {
        const urls = { ...imageUrls }; // Preserve existing images
        await Promise.all(
          sevaData.map(async (item) => {
            if (item.images?.length > 0) {
              const imagePromises = item.images.map(async (image) => {
                const url = await fetchImage(image.name);
                return url;
              });
              urls[item._id] = await Promise.all(imagePromises);
            } else {
              urls[item._id] = []; // Ensure empty array if no images
            }
          })
        );
        setImageUrls(urls);
      };

      loadImages();
    }
  }, [sevaData]);
  return (
    <>
      <div
        className="back-button mb-0 mt-0"
        style={{ display: !hasUser ? "none" : "" }}
      >
        {/* <img
          src={backIcon}
          width={40}
          height={40}
          onClick={() => {
            // setSevaList(false);
            navigate(`/seva`);
          }}
          className="back-button-link"
        /> */}
      </div>
      {isLoading ? (
        <Loader />
      ) : (
        <div style={{ flex: 1 }}>
          {/* If there are no events, show "No records found" */}
          {sevaData.length === 0 ? (
            <div style={{ textAlign: "center", marginTop: "50px" }}>
              <Empty description="No records found" />
            </div>
          ) : (
            <>
              {/* Displaying events in grid */}
              <div style={{ paddingBottom: "80px" }}>
                <Row gutter={[{ xs: 16, sm: 16, md: 24, lg: 32 }, 16]}>
                  {sevaData.map((seva) => {
                    const decodedDescription = he.decode(seva.description);
                    const description = stripHtmlTags(decodedDescription);
                    return (
                      <Col
                        xs={24}
                        sm={12}
                        md={8}
                        lg={6}
                        key={seva._id}
                        className="cardColumn"
                      >
                        <Card
                          className="cardContainer"
                          id="serviceCard"
                          hoverable
                          style={{ width: "100%", marginLeft: "0px" }}
                          cover={
                            <img
                              className="serviceCardImage"
                              alt={seva.title}
                              src={
                                (imageUrls[seva._id] &&
                                  imageUrls[seva._id][0]) ||
                                kalashIcon
                              }
                            />
                          }
                        >
                          <div className="d-flex justify-content-between align-items-center">
                            <div className="cardTitle">{seva.name}</div>
                            <Tag color={getTagColor(seva.frequency)}>
                              {seva.frequency || "N/A"}
                            </Tag>
                          </div>
                          <Meta
                            description={<p>{description}</p>}
                            className="cardDescription"
                          />
                          <div className="cardAmount">
                            Amount: ₹{seva.amount || "N/A"}
                          </div>
                          <Button
                            className="mb-2"
                            style={{
                              width: "100%",
                              marginTop: "20px",
                            }}
                            onClick={() => handleBookingClick(seva._id, seva)}
                            color="primary"
                          >
                            Book Now
                          </Button>
                        </Card>
                      </Col>
                    );
                  })}
                </Row>
              </div>

              {/* Pagination */}
              {!isLoading && totalSeva > 0 && (
                <Card className="pagination-card">
                  <Pagination
                    current={currentPage}
                    pageSize={pageSize}
                    total={totalSeva}
                    onChange={onChangePage}
                    showSizeChanger={false}
                  />
                </Card>
              )}
            </>
          )}
        </div>
      )}
    </>
  );
}

export default ServiceList;
