import React, { useEffect, useMemo, useState } from "react";
import { Tabs, Table, Empty, Tag, Pagination, Card } from "antd";
import { useQuery } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { bookingList } from "../utility/commonApi";
import Loader from "../views/common/loader";
import moment from "moment";
import eyeIcon from "../assets/icons/eyeIcon.svg";
import { useSelector } from "react-redux";

function BookingHistory() {
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState("Pending");
  const [currentPage, setCurrentPage] = useState(1);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  const pageSize = 10;
  const userDetails = useSelector((state) => state.auth);
  const user = userDetails ? userDetails.user : null;
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  const { data, isLoading } = useQuery({
    queryKey: ["bookingHistory", activeTab, currentPage],
    queryFn: () =>
      bookingList({
        page: currentPage,
        limit: pageSize,
        userId: user?.id,
        status: "",
      }),
    keepPreviousData: true,
  });

  const bookingData = useMemo(() => {
    return (
      data?.data?.results?.map((booking) => ({
        key: booking.bookingId,
        bookingId: booking.bookingId,
        userEmail: booking.userId?.email,
        serviceName: booking.bookedSlots[0]?.serviceId?.name,
        bookingDate: booking.bookedSlots[0]?.date,
        status: booking.status,
        id: booking._id,
      })) ?? []
    );
  }, [data]);

  const totalBookings = data?.data?.totalResults || 0;

  const onChangePage = (page) => {
    setCurrentPage(page);
  };

  const columns = [
    {
      title: "Booking ID",
      dataIndex: "bookingId",
      key: "bookingId",
      width: 150,
      fixed: "left",
    },
    {
      title: "User Email",
      dataIndex: "userEmail",
      key: "userEmail",
      width: 200,
    },
    {
      title: "Service Name",
      dataIndex: "serviceName",
      key: "serviceName",
      width: 180,
    },
    {
      title: "Booking Date",
      dataIndex: "bookingDate",
      key: "bookingDate",
      width: 150,
      render: (text) => <span>{moment(text).format("DD MMM YYYY")}</span>,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: 120,
      render: (status) => (
        <Tag color={status === "Pending" ? "orange" : "green"}>{status}</Tag>
      ),
    },
    {
      title: "Action",
      key: "action",
      fixed: "right",
      width: 80,
      render: (_, record) => (
        <img
          src={eyeIcon}
          width={25}
          height={25}
          style={{ cursor: "pointer" }}
          onClick={() => navigate(`/booking/detail/${record.id}`)}
        />
      ),
    },
  ];

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <Tabs
        activeKey={activeTab}
        onChange={setActiveTab}
        style={{ marginBottom: "100px" }}
      >
        <Tabs.TabPane tab="Upcoming" key="Pending">
          {isLoading ? (
            <Loader />
          ) : bookingData.length === 0 ? (
            <Empty description="No upcoming bookings" />
          ) : isMobile ? (
            bookingData.map((booking) => (
              <Card
                key={booking.key}
                style={{ marginBottom: "10px", position: "relative" }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <p>
                    <span className="commonFontSizeColor">Booking ID:</span>{" "}
                    <span className="commonTextColor">{booking.bookingId}</span>
                  </p>
                  <img
                    src={eyeIcon}
                    width={25}
                    height={25}
                    style={{ cursor: "pointer" }}
                    onClick={() => navigate(`/booking/detail/${booking.id}`)}
                  />
                </div>
                <p>
                  <span className="commonFontSizeColor">User Email:</span>{" "}
                  <span className="commonTextColor">{booking.userEmail}</span>
                </p>
                <p>
                  <span className="commonFontSizeColor">Service Name:</span>{" "}
                  <span className="commonTextColor">{booking.serviceName}</span>
                </p>
                <p>
                  <span className="commonFontSizeColor">Booking Date:</span>{" "}
                  <span className="commonTextColor">
                    {moment(booking.bookingDate).format("DD MMM YYYY")}
                  </span>
                </p>
                <p>
                  <span className="commonFontSizeColor">Status:</span>{" "}
                  <Tag
                    color={booking.status === "Pending" ? "orange" : "green"}
                  >
                    {booking.status}
                  </Tag>
                </p>
              </Card>
            ))
          ) : (
            <Table
              columns={columns}
              dataSource={bookingData}
              scroll={{ x: 1500, y: 400 }}
              pagination={false}
              bordered
              className="commonListTable"
            />
          )}
        </Tabs.TabPane>
        <Tabs.TabPane tab="Past" key="Paid">
          {isLoading ? (
            <Loader />
          ) : bookingData.length === 0 ? (
            <Empty description="No past bookings" />
          ) : isMobile ? (
            bookingData.map((booking) => (
              <Card key={booking.key} style={{ marginBottom: 16 }}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <p>
                    <span className="commonFontSizeColor">Booking ID:</span>{" "}
                    <span className="commonTextColor">{booking.bookingId}</span>
                  </p>
                  <img
                    src={eyeIcon}
                    width={25}
                    height={25}
                    style={{ cursor: "pointer" }}
                    onClick={() => navigate(`/booking/detail/${booking.id}`)}
                  />
                </div>
                <p>
                  <span className="commonFontSizeColor">User Email:</span>{" "}
                  <span className="commonTextColor">{booking.userEmail}</span>
                </p>
                <p>
                  <span className="commonFontSizeColor">Service Name:</span>{" "}
                  <span className="commonTextColor">{booking.serviceName}</span>
                </p>
                <p>
                  <span className="commonFontSizeColor">Booking Date:</span>{" "}
                  <span className="commonTextColor">
                    {moment(booking.bookingDate).format("DD MMM YYYY")}
                  </span>
                </p>
                <p>
                  <span className="commonFontSizeColor">Status:</span>{" "}
                  <Tag
                    color={booking.status === "Pending" ? "orange" : "green"}
                  >
                    {booking.status}
                  </Tag>
                </p>
              </Card>
            ))
          ) : (
            <Table
              columns={columns}
              dataSource={bookingData}
              scroll={{ x: 1500, y: 400 }}
              pagination={false}
              bordered
              className="commonListTable"
            />
          )}
        </Tabs.TabPane>
      </Tabs>
      {!isLoading && totalBookings > 0 && (
        <Card className="pagination-card">
          <Pagination
            current={currentPage}
            pageSize={pageSize}
            total={totalBookings}
            onChange={onChangePage}
            showSizeChanger={false}
          />
        </Card>
      )}
    </div>
  );
}

export default BookingHistory;
