export const kannada = {
  //Sign In
  sign_in_with_mobile: 'ಮೊಬೈಲ್ ಸಂಖ್ಯೆಯೊಂದಿಗೆ ಸೈನ್ ಇನ್ ಮಾಡಿ',
  sign_in: 'ಸೈನ್ ಇನ್',
  sign_up: 'ಸೈನ್ ಅಪ್',
  dont_have_account: `ಖಾತೆ ಇಲ್ಲವೇ?`,

  //Sign Up
  register_yourself: 'ನೀವು ನೋಂದಾಯಿಸಿ',
  full_name: 'ಪೂರ್ಣ ಹೆಸರು',
  mobile_number: 'ಮೊಬೈಲ್ ಸಂಖ್ಯೆ',
  date_of_birth: 'ಹುಟ್ಟಿದ ದಿನಾಂಕ',

  //Home
  sunset: 'ಸಂಜೆ ಸೂರ್ಯಾಸ್ತ',
  sunrise: 'ಸೂರ್ಯೋದಯ',

  thought_of_the_day: 'ಇಂದಿನ ಚಿಂತನ',

  donation: 'ದಾನ',
  commitment: 'ಪ್ರತಿಬದ್ಧತೆ',
  jinvani: 'ಶಾಸ್ತ್ರ',
  apna_mandir: 'ನಮ್ಮ ದೇವಸ್ಥಾನ',
  videos: 'ವೀಡಿಯೊಗಳು',
  audios: 'ಆಡಿಯೊಗಳು',

  favorites: 'ಮೆಚ್ಚಿನ',
  advertisements: 'ಜಾಹೀರಾತುಗಳು',
  post_ads: 'ಜಾಹೀರಾತು ಪೋಸ್ಟ್ ಮಾಡಿ',

  explore_temples: 'ದೇವಾಲಯಗಳನ್ನು ಅನ್ವೇಷಿಸಿ',

  todays_exclusive: 'ಇಂದಿನ ವಿಶೇಷ',

  //Search Home
  header_trust: 'ಟ್ರಸ್ಟ್',
  header_news: 'ಸುದ್ದಿ',
  header_event: 'ಕಾರ್ಯಕ್ರಮ',
  header_todayexclusive: 'ಇಂದಿನ ವಿಶೇಷ',

  //Donation
  no_donation_found: 'ಯಾವುದೇ ದಾನ ಸಿಕ್ಕಿಲ್ಲ',
  make_donation: 'ದಾನ ಮಾಡಿ',
  all: 'ಎಲ್ಲ',

  //Quick Donation
  make_donate_title: 'ದಾನ ಮಾಡಿ',

  //Quick Add trut to Favorites
  add_to_favorites_list_title: 'ಮೆಚ್ಚಿನ ಪಟ್ಟಿ ಸೇರಿಸಿ',
  added_fav_trust_success_msg:
    'ಈ ಟ್ರಸ್ಟ್ ಅನ್ನು ಯಶಸ್ವಿಯಾಗಿ ನಿಮ್ಮ ಮೆಚ್ಚಿನ ಪಟ್ಟಿ ಸೇರಿಸಲಾಗಿದೆ',
  removed_fav_trust_success_msg:
    'ಈ ಟ್ರಸ್ಟ್ ಅನ್ನು ಯಶಸ್ವಿಯಾಗಿ ನಿಮ್ಮ ಮೆಚ್ಚಿನ ಪಟ್ಟಿದಿಂದ ತೆಗೆದುಹಾಕಲಾಗಿದೆ',

  //Mandir Details
  btn_donate_title: 'ಈಗ ದಾನ ಮಾಡಿ',
  btn_commitments_title: 'ಪ್ರತಿಬದ್ಧತೆ',
  alert_message_payment_pause: 'ಪ್ರಸ್ತುತ ಇದಕ್ಕೆ ದಾನ ಮಾಡಲಾಗುವುದಿಲ್ಲ',
  text_address: 'ವಿಳಾಸ',
  text_contact_number: 'ಸಂಪರ್ಕ ಸಂಖ್ಯೆ',
  text_email: 'ಇಮೇಲ್',

  //Report and Dispute
  report_and_dispute: 'ಅಹವಾಲು ಮತ್ತು ವಿವಾದ',
  payment_report: 'ಪಾವತಿ ವರದಿ',
  trust_name: 'ಟ್ರಸ್ಟ್ ಹೆಸರು',

  //QuickInsights
  total_donation: 'ಒಟ್ಟು ದಾನ',
  total_commitment: 'ಒಟ್ಟು ಪ್ರತಿಬದ್ಧತೆ',

  //Insights
  title_insights: 'ಅವಲೋಕನಗಳು',

  //PaidAmountReceipt
  total_amount: 'ಒಟ್ಟು ಮೊತ್ತ',
  paid_amount: 'ಪಾವತಿಸಿದ ಮೊತ್ತ',
  mode_of_payment: 'ಪಾವತಿ ಮೋಡ್',
  donor_name: 'ದಾನಿಗಳ ಹೆಸರು',
  date_and_time: 'ದಿನಾಂಕ ಮತ್ತು ಸಮಯ',
  category: 'ವರ್ಗ',
  download_receipt: 'ರಸೀದಿ ಡೌನ್ಲೋಡ್ ಮಾಡಿ',

  //Global Event
  no_event_on_selected_date: 'ಆಯ್ದ ದಿನಾಂಕದಲ್ಲಿ ಯಾವುದೇ ಘಟನೆ ಇಲ್ಲ',
  no_data_no_event_found: 'ಯಾವುದೇ ಘಟನೆ ಸಿಕ್ಕಿಲ್ಲ',
  there_no_event: 'ಯಾವುದೇ ಘಟನೆ ಇಲ್ಲ.',

  //Event Details
  event_details: 'ವಿವರಗಳು',
  event_about: 'ಬಗ್ಗೆ',
  event_location: 'ಸ್ಥಳ',
  event_timing: 'ಸಮಯ',

  //Success Payment Pop
  anumodnaTitle: 'ಅನುಮೋದನೆ',
  successDonationMsg:
    'ನಾವು ನಿಮ್ಮ ದಾನದಿಗಾಗಿ ಕೃತಜ್ಞರಾಗಿ ಇದ್ದೇವೆ, ನೀವು ದಾನಗಳನ್ನು ನೀಡಿ ಪುಣ್ಯಲಾಭವನ್ನು ಪಡೆಯುವಂತೆ ನಾವು ಆಶಿಸುತ್ತೇವೆ.',
  donate_again: 'ಮತ್ತೆ ದಾನ ಮಾಡಿ',

  //Completed Commitment
  commitments_title: 'ಪ್ರತಿಬದ್ಧತೆ',
  there_is_no_commitment_yet: 'ಇನ್ನೂ ಯಾವುದೇ ಪ್ರತಿಬದ್ಧತೆ ಇಲ್ಲ',

  //Jinvani
  chapters_title: 'ಅಧ್ಯಾಯಗಳು',

  no_jinvani_chapters_found: 'ಯಾವುದೇ ಶಾಸ್ತ್ರ ಅಧ್ಯಾಯಗಳು ಸಿಕ್ಕಿಲ್ಲ',

  //Jinavni Chapter List
  title_jinvani_chapter: 'ಶಾಸ್ತ್ರ ಅಧ್ಯಾಯ',
  total_chapters: 'ಒಟ್ಟು ಪುಟಗಳು',
  total_chapter: 'ಒಟ್ಟು ಪುಟ',
  number_of_chapters: 'ಅಧ್ಯಾಯಗಳ ಸಂಖ್ಯೆ',
  number_of_chapter: 'ಅಧ್ಯಾಯದ ಸಂಖ್ಯೆ',
  number_of_pages: 'ಪುಟಗಳ ಸಂಖ್ಯೆ',
  number_of_page: 'ಪುಟದ ಸಂಖ್ಯೆ',

  //Donation Payment
  donation_payment: 'ದಾನ ಪಾವತಿ',
  donor_name: 'ದಾನಿಗಳ ಹೆಸರು',
  donation_amount: 'ಮೊತ್ತ ನಮೂದಿಸಿ',

  btn_make_payment: 'ಪಾವತಿ ಮಾಡಿ',

  //Profile
  personal_information: 'ವೈಯಕ್ತಿಕ ಮಾಹಿತಿ',
  notifications: 'ಅಧಿಸೂಚನೆಗಳು',
  quick_links: 'ತ್ವರಿತ ಲಿಂಕುಗಳು',
  terms_conditions: 'ನಿಯಮಗಳು ಮತ್ತು ಷರತ್ತುಗಳು',
  privacy_policy: 'ಗೋಪ್ಯತಾ ನೀತಿ',
  contact_support: 'ಬೆಂಬಲವನ್ನು ಸಂಪರ್ಕಿಸಿ',
  get_help: 'ಸಹಾಯ ಪಡೆಯಿರಿ',
  give_us_feedback: 'ನಮಗೆ ಪ್ರತಿಕ್ರಿಯೆ ನೀಡಿ',
  post_your_ads: 'ಜಾಹೀರಾತುಗಳನ್ನು ಪೋಸ್ಟ್ ಮಾಡಿ',
  logout: 'ಲಾಗ್ ಔಟ್',

  version: 'ಆವೃತ್ತಿ',

  //Profile Details
  name: 'ಹೆಸರು',
  email: 'ಇಮೇಲ್',
  phone: 'ಫೋನ್',
  select_date_of_birth: 'ಹುಟ್ಟಿದ ದಿನಾಂಕ ಆಯ್ಕೆಮಾಡಿ',
  address: 'ವಿಳಾಸ',
  state: 'ರಾಜ್ಯ',
  city: 'ನಗರ',
  pan_card: 'ಪ್ಯಾನ್ ಕಾರ್ಡ್',

  //Post Ads
  business_name_title: 'ವ್ಯವಹಾರದ ಹೆಸರು',
  business_type_title: 'ವ್ಯವಹಾರದ ಪ್ರಕಾರ',
  select_business_type: 'ವ್ಯವಹಾರದ ಪ್ರಕಾರ ಆಯ್ಕೆಮಾಡಿ',
  enter_business_type: 'ನಿಮ್ಮ ವ್ಯವಹಾರದ ಪ್ರಕಾರ ನಮೂದಿಸಿ',

  //Account Preferences
  account_preferences: 'ಖಾತೆ ಆಯ್ಕೆಗಳು',
  allPushNotification: 'ಎಲ್ಲಾ ಪುಷ್ ಅಧಿಸೂಚನೆಗಳು',
  accountNotification: 'ಖಾತೆ ಅಧಿಸೂಚನೆ',
  promotionalNotification: 'ಪ್ರಚಾರಾತ್ಮಕ ಅಧಿಸೂಚನೆ',

  appPreference: 'ಅಪ್ಲಿಕೇಶನ್ ಆದ್ಯತೆ',
  deleteAccount: 'ಖಾತೆ ಅಳಿಸಿ',

  language_preferences: 'ಭಾಷಾ ಆಯ್ಕೆಗಳು',

  //Add Pan Card
  pancard_security_message:
    'ನಿಮ್ಮ ವೈಯಕ್ತಿಕ ಡೇಟಾ ನಮ್ಮ ಡೇಟಾಬೇಸ್‌ನಲ್ಲಿ ಸುರಕ್ಷಿತವಾಗಿ ಸಂಗ್ರಹಿಸಲಾಗುತ್ತದೆ.',
  pan_card_number: 'ಪ್ಯಾನ್ ಕಾರ್ಡ್ ಸಂಖ್ಯೆ',
  pan_holder_name: 'ಕಾರ್ಡ್ ಧಾರಕರ ಹೆಸರು',
  pan_card_photo: 'ಪ್ಯಾನ್ ಕಾರ್ಡ್ ಫೋಟೋ',
  add_pan_card: 'ಪ್ಯಾನ್ ಕಾರ್ಡ್ ಸೇರಿಸಿ',
  btn_title_add_card: 'ಕಾರ್ಡ್ ಸೇರಿಸಿ',

  //Commitment payement
  commitments_title: 'ಪ್ರತಿಬದ್ಧತೆ',
  total_amount: 'ಒಟ್ಟು ಮೊತ್ತ',
  paid_amount: 'ಪಾವತಿಸಿದ ಮೊತ್ತ',
  payable_amount: 'ಪಾವತಿಸಬೇಕಾದ ಮೊತ್ತ',
  full_payment: 'ಪೂರ್ಣ ಪಾವತಿ',
  make_commitment: 'ಪ್ರತಿಬದ್ಧತೆ ಮಾಡಿ',
  partial_payment: 'ಆಂಶಿಕ ಪಾವತಿ',

  //Buttons Title
  apply: 'ಅನ್ವಯಿಸಿ',
  update_profile: 'ಪ್ರೊಫೈಲ್ ನವೀಕರಿಸಿ',

  view_all: 'ಎಲ್ಲಾ ವೀಕ್ಷಿಸಿ',
  ok: 'ಸರಿ',
  cancel: 'ರದ್ದುಮಾಡಿ',
  continue: 'ಮುಂದುವರಿಸಿ',
  skip: 'ಬಿಟ್ಟುಹೋಗಿ',
  report: 'ವರದಿ',

  //Placeholder
  enter_your_name: 'ನಿಮ್ಮ ಹೆಸರು ನಮೂದಿಸಿ',
  enter_your_email: 'ನಿಮ್ಮ ಇಮೇಲ್ ನಮೂದಿಸಿ',
  enter_your_phone: 'ನಿಮ್ಮ ಫೋನ್ ನಮೂದಿಸಿ',
  enter_your_dob: 'ನಿಮ್ಮ ಹುಟ್ಟಿದ ದಿನಾಂಕ ನಮೂದಿಸಿ',
  select_address: 'ವಿಳಾಸ ಆಯ್ಕೆಮಾಡಿ',
  add_edit_pancard_details: 'ಪ್ಯಾನ್ ಕಾರ್ಡ್ ವಿವರಗಳನ್ನು ಸೇರಿಸಿ/ತಿದ್ದುಪಡಿಸು',
  enter_mobile_number: 'ಮೊಬೈಲ್ ಸಂಖ್ಯೆ ನಮೂದಿಸಿ',
  p_search_temple: 'ದೇವಾಲಯವನ್ನು ಹುಡುಕಿ',

  p_home_search: 'ಟ್ರಸ್ಟ್, ಇವೆಂಟ್, ಸುದ್ದಿ ಹುಡುಕಿ..',
  p_commitment_search: 'ದೇವಾಲಯವನ್ನು ಹುಡುಕಿ',
  p_jinvani_search: 'ಶಾಸ್ತ್ರ ಹುಡುಕಿ',
  p_jinvani_chapter_search: 'ಅಧ್ಯಾಯದ ಹೆಸರು ಹುಡುಕಿ',

  p_enter_pan_number: 'ಪ್ಯಾನ್ ಕಾರ್ಡ್ ಸಂಖ್ಯೆ ನಮೂದಿಸಿ',
  p_enter_pan_holder_name: 'ಪ್ಯಾನ್ ಕಾರ್ಡ್ ಮೇಲೆ ಇರುವ ಹೆಸರನ್ನು ನಮೂದಿಸಿ',

  p_enter_donar_name: 'ದಾನಿಗಳ ಹೆಸರು ನಮೂದಿಸಿ',

  p_select_category: 'ವರ್ಗ ಆಯ್ಕೆಮಾಡಿ',
  p_select_subcategory: 'ಉಪವರ್ಗ ಆಯ್ಕೆಮಾಡಿ',

  p_enter_donation_amount: 'ಮೊತ್ತ ನಮೂದಿಸಿ',
  p_enter_payable_amount: 'ಪಾವತಿಸಬೇಕಾದ ಮೊತ್ತ ನಮೂದಿಸಿ',

  p_enter_trust_name: 'ಟ್ರಸ್ಟ್ ಹೆಸರನ್ನು ನಮೂದಿಸಿ',

  p_search_event: 'ಮುಂಬರುವ ಕಾರ್ಯಕ್ರಮ ಹುಡುಕಿ',
  //No Data message
  no_commitment_found: 'ಯಾವುದೇ ಪ್ರತಿಬದ್ಧತೆ ಸಿಕ್ಕಿಲ್ಲ',
  no_about_text_found: 'ಬಗ್ಗೆ ವಿಭಾಗವನ್ನು ಇನ್ನೂ ಸೇರಿಸಲಾಗಿಲ್ಲ.',
  no_news_found: 'ಯಾವುದೇ ಸುದ್ದಿ ಸೇರಿಸಲಾಗಿಲ್ಲ.',
  no_event_found: 'ಯಾವುದೇ ಕಾರ್ಯಕ್ರಮ ಸೇರಿಸಲಾಗಿಲ್ಲ.',
  no_photos_found: 'ಯಾವುದೇ ಫೋಟೋಗಳು ಸೇರಿಸಲಾಗಿಲ್ಲ.',
  no_facility_found: 'ಯಾವುದೇ ಸೌಲಭ್ಯ ಸೇರಿಸಲಾಗಿಲ್ಲ.',
  no_punyarjak_found: 'ಯಾವುದೇ ಪುಣ್ಯರ್ಜಕ ಸೇರಿಸಲಾಗಿಲ್ಲ.',
  no_top_doner_found: 'ಪಟ್ಟಿ ಇದೀಗ ಖಾಲಿಯಾಗಿದೆ, ಯಾವುದೇ ದಾನಿಗಳು ಪಟ್ಟಿಯಲ್ಲಿ ಇಲ್ಲ.',
  no_appPreference: 'ಯಾವುದೇ ಅಪ್ಲಿಕೇಶನ್ ಆಯ್ಕೆಗಳು ಲಭ್ಯವಿಲ್ಲ',
  //Alert Messages
  error_enterFullName: 'ದಯವಿಟ್ಟು ಪೂರ್ಣ ಹೆಸರು ನಮೂದಿಸಿ.',
  error_enterMobileNumber: 'ದಯವಿಟ್ಟು ಸರಿಯಾದ ಮೊಬೈಲ್ ಸಂಖ್ಯೆಯನ್ನು ನಮೂದಿಸಿ.',
  error_validEmail: 'ದಯವಿಟ್ಟು ಮಾನ್ಯ ಇಮೇಲ್ ನಮೂದಿಸಿ.',
  error_enterEmail: 'ದಯವಿಟ್ಟು ಇಮೇಲ್ ನಮೂದಿಸಿ.',
  error_enterDob: 'ದಯವಿಟ್ಟು ಹುಟ್ಟಿದ ದಿನಾಂಕ ಆಯ್ಕೆಮಾಡಿ.',
  error_enterOtp: 'ದಯವಿಟ್ಟು ಮಾನ್ಯ otp ನಮೂದಿಸಿ',
  error_enterTrustDescription: 'ದಯವಿಟ್ಟು ವಿವರಣೆ ನಮೂದಿಸಿ',
  error_enter_pan_holdername: 'ದಯವಿಟ್ಟು ಕಾರ್ಡ್ ಧಾರಕರ ಹೆಸರನ್ನು ನಮೂದಿಸಿ',
  error_pan_image_not_found: 'ದಯವಿಟ್ಟು ಪ್ಯಾನ್ ಕಾರ್ಡ್ ಫೋಟೋ ಅಪ್‌ಲೋಡ್ ಮಾಡಿ',
  error_enter_valid_pan_card_no: 'ದಯವಿಟ್ಟು ಪ್ಯಾನ್ ಕಾರ್ಡ್ ಸಂಖ್ಯೆಯನ್ನು ನಮೂದಿಸಿ',
  mesg_logout_confirmation:
    'ನೀವು ಖಚಿತವಾಗಿ ಲಾಗ್ ಔಟ್ ಮಾಡಲು ಬಯಸುತ್ತೀರಿ ಎಂಬುದು ಖಚಿತವೇ?',
  error_already_reported_trust: 'ಈ ಟ್ರಸ್ಟ್‌ಗೆ ಈಗಾಗಲೇ ವರದಿ ಮಾಡಲಾಗಿದೆ',

  noInternetMessage:
    'ನಿಮ್ಮ ವೈಫೈ ಅಥವಾ ಸೆಲ್ಯುಲರ್ ಡೇಟಾ ಆನ್ ಮಾಡಿರುವುದು ಖಚಿತಪಡಿಸಿಕೊಳ್ಳಿ ಮತ್ತು ಮರು ಪ್ರಯತ್ನಿಸಿ',

  error_select_category_first: 'ಮೊದಲು ವರ್ಗವನ್ನು ಆಯ್ಕೆಮಾಡಿ',
  error_please_select_category: 'ದಯವಿಟ್ಟು ವರ್ಗವನ್ನು ಆಯ್ಕೆಮಾಡಿ',
  error_please_select_subcategory: 'ದಯವಿಟ್ಟು ಉಪವರ್ಗವನ್ನು ಆಯ್ಕೆಮಾಡಿ',
  error_please_enter_donation_amount: 'ದಯವಿಟ್ಟು ದಾನದ ಮೊತ್ತವನ್ನು ನಮೂದಿಸಿ',

  error_entered_amount_is_not_valid: 'ನಮೂದಿಸಿದ ಮೊತ್ತವು ಮಾನ್ಯವಲ್ಲ',

  api_error_something_went_wrong: 'ಏನೋ ತಪ್ಪಾಗಿದೆ, ದಯವಿಟ್ಟು ನಂತರ ಮರು ಪ್ರಯತ್ನಿಸಿ',

  api_error_donation_payment_cancelled: 'ದಾನದ ಪಾವತಿಯನ್ನು ರದ್ದು ಮಾಡಲಾಗಿದೆ',
  api_error_commitment_payment_cancelled:
    'ಪ್ರತಿಬದ್ಧತೆಯ ಪಾವತಿಯನ್ನು ರದ್ದು ಮಾಡಲಾಗಿದೆ',

  api_success_donation_payment_success:
    'ದಾನದ ಪಾವತಿಯನ್ನು ಯಶಸ್ವಿಯಾಗಿ ಪೂರ್ಣಗೊಳಿಸಲಾಗಿದೆ',
  api_success_commitment_payment_success:
    'ಪ್ರತಿಬದ್ಧತೆಯ ಪಾವತಿಯನ್ನು ಯಶಸ್ವಿಯಾಗಿ ಪೂರ್ಣಗೊಳಿಸಲಾಗಿದೆ',

  api_success_post_ad:
    'ಜಾಹೀರಾತು ಪೋಸ್ಟ್ ಮಾಡುವ ನಿಮ್ಮ ವಿನಂತಿಯನ್ನು ಯಶಸ್ವಿಯಾಗಿ ಸಲ್ಲಿಸಲಾಗಿದೆ.',
  api_success_profile_image_uploaded:
    'ಪ್ರೊಫೈಲ್ ಚಿತ್ರವನ್ನು ಯಶಸ್ವಿಯಾಗಿ ನವೀಕರಿಸಲಾಗಿದೆ',
  api_success_profile_details: 'ಪ್ರೊಫೈಲ್ ವಿವರಗಳನ್ನು ಯಶಸ್ವಿಯಾಗಿ ನವೀಕರಿಸಲಾಗಿದೆ',

  api_success_pan_added: 'ನಿಮ್ಮ ಪ್ಯಾನ್ ಕಾರ್ಡ್ ಯಶಸ್ವಿಯಾಗಿ ಸೇರಿಸಲಾಗಿದೆ.',
  api_success_pan_updated: 'ನಿಮ್ಮ ಪ್ಯಾನ್ ಕಾರ್ಡ್ ಯಶಸ್ವಿಯಾಗಿ ನವೀಕರಿಸಲಾಗಿದೆ.',

  api_success_get_help: 'ನಿಮ್ಮ ವಿನಂತಿಯನ್ನು ಯಶಸ್ವಿಯಾಗಿ ಕಳುಹಿಸಲಾಗಿದೆ',
  api_success_sent_feedback: 'ಧನ್ಯವಾದಗಳು! ನಿಮ್ಮ ಪ್ರತಿಕ್ರಿಯೆಗಾಗಿ.',

  api_success_added_commitment: 'ಪ್ರತಿಬದ್ಧತೆಯನ್ನು ಯಶಸ್ವಿಯಾಗಿ ಸೇರಿಸಲಾಗಿದೆ',

  //After Provided 28-June
  no_data__notice_has_been_added: 'ಯಾವುದೇ ನೋಟೀಸ್ ಸೇರಿಸಲಾಗಿಲ್ಲ.',
  due_amount: 'ಬಾಕಿ ಮೊತ್ತ',
  btn_title_update_card: 'ನವೀಕರಿಸಿ',
  update_pan_card: 'ಪ್ಯಾನ್ ಕಾರ್ಡ್ ನವೀಕರಿಸಿ',

  delete_account_alert_msg:
    'ನೀವು ಖಚಿತವಾಗಿ ಖಾತೆಯನ್ನು ಅಳಿಸಲು ಬಯಸುತ್ತೀರಿ ಎಂಬುದು ಖಚಿತವೇ?',
  delete_title: 'ಅಳಿಸಿ',
  notification_remove_msg:
    'ಇಲ್ಲಿ ಕಾಣಿಸಿಕೊಳ್ಳುವ ಅಧಿಸೂಚನೆಗಳನ್ನು ಸ್ವೀಕರಿಸಿದ ಸಮಯದಿಂದ 24 ಗಂಟೆಗಳ ನಂತರ ತೆಗೆದುಹಾಕಲಾಗುತ್ತದೆ.',
  get_help_title: 'ಸಹಾಯ ಪಡೆಯಿರಿ',

  description_title: 'ವಿವರಣೆ',

  p_enter_title: 'ನಿಮ್ಮ ಶೀರ್ಷಿಕೆಯನ್ನು ಇಲ್ಲಿ ನಮೂದಿಸಿ',
  p_enter_description: 'ನಿಮ್ಮ ವಿವರವನ್ನು ಇಲ್ಲಿ ಟೈಪ್ ಮಾಡಿ',
  p_enter_your_name: 'ನಿಮ್ಮ ಹೆಸರು ನಮೂದಿಸಿ',
  p_enter_business_name: 'ವ್ಯವಹಾರದ ಹೆಸರು ನಮೂದಿಸಿ',
  p_enter_enter_business_type: 'ವ್ಯವಹಾರದ ಪ್ರಕಾರ ನಮೂದಿಸಿ',
  p_search_trust: 'ಟ್ರಸ್ಟ್ ಹುಡುಕಿ',
  p_search_news: 'ಸುದ್ದಿ ಹುಡುಕಿ',
  p_search_location: 'ಸ್ಥಳ ಹುಡುಕಿ',
  p_search_state: 'ರಾಜ್ಯ ಹುಡುಕಿ',
  p_search_city: 'ನಗರ ಹುಡುಕಿ',
  p_search_audio: 'ಆಡಿಯೊ ಹುಡುಕಿ',
  //No Data Found
  no_results_found: 'ಯಾವುದೇ ಫಲಿತಾಂಶಗಳು ಸಿಕ್ಕಿಲ್ಲ',
  there_no_trust: 'ಯಾವುದೇ ಟ್ರಸ್ಟ್ ಇಲ್ಲ',
  there_no_news: 'ಯಾವುದೇ ಸುದ್ದಿ ಇಲ್ಲ',
  no_news_found: 'ಯಾವುದೇ ಸುದ್ದಿ ಸಿಕ್ಕಿಲ್ಲ',
  no_location_found: 'ಯಾವುದೇ ಸ್ಥಳ ಸಿಕ್ಕಿಲ್ಲ',
  no_video_available: 'ಯಾವುದೇ ವೀಡಿಯೊ ಸಿಕ್ಕಿಲ್ಲ',
  no_video_found: 'ಯಾವುದೇ ವೀಡಿಯೊ ಸಿಕ್ಕಿಲ್ಲ',

  no_audio_available: 'ಯಾವುದೇ ಆಡಿಯೊ ಸಿಕ್ಕಿಲ್ಲ',

  btn_title_submit: 'ಸಲ್ಲಿಸು',

  give_us_feedback_title: 'ನಮಗೆ ಪ್ರತಿಕ್ರಿಯೆ ನೀಡಿ',
  title_text: 'ಶೀರ್ಷಿಕೆ',

  star_rating: 'ನಿಮ್ಮ ನಕ್ಷತ್ರ ರೇಟಿಂಗ್',

  customise_mandir: 'ಮಂದಿರವನ್ನು ಕಸ್ಟಮೈಸ್ ಮಾಡಿ',

  select_state_title: 'ರಾಜ್ಯ ಆಯ್ಕೆಮಾಡಿ',
  select_city_title: 'ನಗರ ಆಯ್ಕೆಮಾಡಿ',
  add_more: 'ಇನ್ನಷ್ಟು ಸೇರಿಸಿ',
  app_preference: 'ಅಪ್ಲಿಕೇಶನ್ ಆದ್ಯತೆ',
  no_language: 'ಯಾವುದೇ ಭಾಷೆ ಲಭ್ಯವಿಲ್ಲ',

  //
  location_permission_message:
    'ನಿಮ್ಮ ಬಳಿ ದೇವಾಲಯ, ಈವೆಂಟ್ಸ್ ಮತ್ತು ಸುದ್ದಿಗಳನ್ನು ಅನ್ವೇಷಿಸಲು ಬಯಸುತ್ತೀರಾ?',
  location_permission_msg_2: 'ನಮ್ಮೊಂದಿಗೆ ನಿಮ್ಮ ಸ್ಥಳವನ್ನು ಹಂಚಿಕೊಂಡು ಪ್ರಾರಂಭಿಸಿ',
  location_permission_button: 'ಸ್ಥಳ ಸೇವೆಗಳನ್ನು ಅನುಮತಿಸಿ',
  maybe_later_button: 'ಬಹುಶಃ ನಂತರ',
  btn_commitment_payment: 'ಪ್ರತಿಬದ್ಧತೆ',
  no_notification_available: 'ಯಾವುದೇ ಅಧಿಸೂಚನೆಗಳು ಲಭ್ಯವಿಲ್ಲ',
  tag: 'ಟ್ಯಾಗ್',

  lanuage_changed_success: 'ಭಾಷೆಯನ್ನು ಯಶಸ್ವಿಯಾಗಿ ನವೀಕರಿಸಲಾಗಿದೆ',
  language_already_selected: 'ಭಾಷೆಯನ್ನು ಈಗಾಗಲೇ ಆಯ್ಕೆ ಮಾಡಲಾಗಿದೆ',
  account_delete_successfully: 'ನಿಮ್ಮ ಖಾತೆಯನ್ನು ಯಶಸ್ವಿಯಾಗಿ ಅಳಿಸಲಾಗಿದೆ',

  error_enter_mobile_number: 'ದಯವಿಟ್ಟು ಮೊಬೈಲ್ ಸಂಖ್ಯೆಯನ್ನು ನಮೂದಿಸಿ',
  get_directions: 'ದಿಕ್ಕುಗಳನ್ನು ಪಡೆಯಿರಿ',

  temple_info: 'ದೇವಾಲಯ ಮಾಹಿತಿ',
  notices: 'ನೋಟೀಸ್ಗಳು',
  about: 'ಬಗ್ಗೆ',
  news: 'ಸುದ್ದಿ',
  events: 'ಈವೆಂಟ್ಸ್',
  gallery: 'ಗ್ಯಾಲರಿ',
  facilities: 'ಸೌಲಭ್ಯಗಳು',
  punyarjak: 'ಪುಣ್ಯರ್ಜಕ',
  top_Donor: 'ಟಾಪ್ ದಾನಿಗಳು',

  change_background: 'ಹಿನ್ನೆಲೆ ಬದಲಾಯಿಸಿ',
  add_background: 'ಹಿನ್ನೆಲೆ ಸೇರಿಸಿ',

  change_god_position: 'ದೇವರ ಸ್ಥಾನ ಬದಲಾಯಿಸಿ',
  add_all_gods: 'ಎಲ್ಲಾ ದೇವರನ್ನು ಸೇರಿಸಿ',

  title_address: 'ವಿಳಾಸ',
  title_location: 'ಸ್ಥಳ',
  title_city: 'ನಗರ',
  title_state: 'ರಾಜ್ಯ',

  txt_donate: 'ದಾನ ಮಾಡಿ',

  choose_from_gallery_or_take_a_new_one:
    'ಗ್ಯಾಲರಿ ಯಿಂದ ಆಯ್ಕೆಮಾಡಿ ಅಥವಾ ಹೊಸದನ್ನು ತೆಗೆದುಕೊಳ್ಳಿ',
  camera: 'ಕ್ಯಾಮೆರಾ',
  btn_gallery: 'ಗ್ಯಾಲರಿ',
  msg_payment_receipt:
    'ಪ್ರತಿಯೊಂದು ದಾನದಿಂದಲೂ, ನಾವು ನಮ್ಮ ಗುರಿಯತ್ತ ಹತ್ತಿರ ಬರುತ್ತೇವೆ. ನಿಮ್ಮ ದಯೆಯಿಂದ ಮತ್ತು ಧಾರ್ಮಿಕತೆಗಾಗಿ ಧನ್ಯವಾದಗಳು',

  monthly: 'ಮಾಸಿಕ',
  weekly: 'ವಾರಂತ್ಯ',
  yearly: 'ವಾರ್ಷಿಕ',

  no_jinvani_found: 'ಯಾವುದೇ ಶಾಸ್ತ್ರ ಸಿಕ್ಕಿಲ್ಲ',

  search_video: 'ವೀಡಿಯೊ ಹುಡುಕಿ',

  saved_payment_receipt: 'ಪಾವತಿ ರಸೀದಿ ಯಶಸ್ವಿಯಾಗಿ ಉಳಿಸಲಾಗಿದೆ',

  create_account: 'ಖಾತೆ ರಚಿಸಿ',
  by_signing_up: 'ನೋಂದಾಯಿಸಿಕೊಂಡು ನೀವು ಒಪ್ಪುತ್ತೀರಿ',
  privacy_policy: 'ಗೋಪ್ಯತಾ ನೀತಿ',
  already_have_an_account: 'ಈಗಾಗಲೇ ಖಾತೆ ಇದೆಯೇ?',
  translate_jinvani: 'ಶಾಸ್ತ್ರ ಭಾಷಾಂತರ',

  //
  DONE: 'ಮುಗಿಯಿತು',
  Recipt: 'ರಸೀದಿ',

  DeleteAccount: 'ಖಾತೆ ಅಳಿಸಿ',

  deleteAccountConfrimationMesage:
    'ನೀವು ಖಚಿತವಾಗಿ ಖಾತೆಯನ್ನು ಅಳಿಸಲು ಬಯಸುತ್ತೀರಿ ಎಂಬುದು ಖಚಿತವೇ? ಎಲ್ಲಾ ಡೇಟಾ ಕಳೆದುಹೋಗುತ್ತದೆ ಮತ್ತು ಮರಳಿ ಪಡೆಯಲಾಗುವುದಿಲ್ಲ.',

  deleteAccountWarningMessage:
    'ನೀವು ಖಚಿತವಾಗಿ ಖಾತೆಯನ್ನು ಅಳಿಸಲು ಬಯಸುತ್ತೀರಿ ಎಂಬುದು ಖಚಿತವೇ?\n\nನೀವು 14 ದಿನಗಳೊಳಗೆ ಲಾಗಿನ್ ಮಾಡಿ ನಿಮ್ಮ ಖಾತೆಯನ್ನು ಮತ್ತೆ ಸಕ್ರಿಯಗೊಳಿಸಬಹುದು. ನಂತರ, ನಿಮ್ಮ ಎಲ್ಲಾ ಖಾತೆಯ ವಿವರಗಳು ನಮ್ಮ ದಾಖಲೆಗಳಿಂದ ಶಾಶ್ವತವಾಗಿ ಅಳಿಸಲಾಗುತ್ತದೆ.',

  WhatDoYouWantToDo: 'ನೀವು ಏನು ಮಾಡಲು ಬಯಸುತ್ತೀರಿ?',

  NoSearchEnteredYet: 'ಇನ್ನೂ ಯಾವುದೇ ಹುಡುಕಾಟ ನಮೂದಿಸಲಾಗಿಲ್ಲ',
  SorryWeCouldnFindAnyResults:
    'ಕ್ಷಮಿಸಿ, ನಾವು ಯಾವುದೇ ಫಲಿತಾಂಶಗಳನ್ನು ಕಂಡುಹಿಡಿಯಲು ಸಾಧ್ಯವಾಗಿಲ್ಲ',

  btnNo: 'ಇಲ್ಲ',
  btnYes: 'ಹೌದು',

  AppDisplayName: 'ನಮ್ಮ ಧರ್ಮ',

  EnterTitle: 'ಶೀರ್ಷಿಕೆ ನಮೂದಿಸಿ',
  AddTempleBackground: 'ದೇವಾಲಯ ಹಿನ್ನೆಲೆ ಸೇರಿಸಿ',
  NoItemFound: 'ಯಾವುದೇ ಐಟಂ ಸಿಕ್ಕಿಲ್ಲ!',
  //
  MobileVerification: 'ಮೊಬೈಲ್ ಪರಿಶೀಲನೆ',
  EnterTheDigitCodeThatWasSentToYourRegisteredMobileNumber: `ನಿಮ್ಮ ನೋಂದಾಯಿತ ಮೊಬೈಲ್ ಸಂಖ್ಯೆಗೆ ಕಳುಹಿಸಲಾದ 6-ಅಂಕಿಯ ಕೋಡ್ ನಮೂದಿಸಿ.`,
  Verifying: 'ಪರಿಶೀಲಿಸಲಾಗುತ್ತಿದೆ...',
  VerifyMobileNumber: 'ಮೊಬೈಲ್ ಸಂಖ್ಯೆಯನ್ನು ಪರಿಶೀಲಿಸಿ',
  ResendCode: 'ಕೋಡ್ ಪುನಃ ಕಳುಹಿಸು',

  No: 'ಇಲ್ಲ',
  Found: 'ಸಿಕ್ಕಿತು',

  Published_on: 'ಪ್ರಕಾಶಿತ: ',
  receipt_url_not_available: 'ರಸೀದಿ ಲಭ್ಯವಿಲ್ಲ ದಯವಿಟ್ಟು ನಂತರ ಪ್ರಯತ್ನಿಸಿ',
  DonorName: 'ದಾನಿಗಳ ಹೆಸರು :',
  SelectBackground: 'ಹಿನ್ನೆಲೆ ಆಯ್ಕೆಮಾಡಿ',

  Subcategory: 'ಉಪವರ್ಗ',

  newUpdateAvailableText: 'ಹೊಸ ನವೀಕರಣ ಲಭ್ಯವಿದೆ',
  newUpdateAvailableMessage:
    'ಸುಧಾರಿತ ಅನುಭವಕ್ಕಾಗಿ ದಯವಿಟ್ಟು ನಮ್ಮ ಆಪ್ ಅನ್ನು ನವೀಕರಿಸಿ! ಈ ಆವೃತ್ತಿ ಇನ್ನು ಮುಂದೆ ಬೆಂಬಲಿಸಲಾಗುವುದಿಲ್ಲ.',
  updateNow: 'ಈಗ ನವೀಕರಿಸಿ',
  notNow: 'ಈಗಿಲ್ಲ',

  verificationEmailHasBeenSentToYourEmail:
    'ನಿಮ್ಮ ನೋಂದಾಯಿತ ಇಮೇಲ್ಗೆ ಪರಿಶೀಲನೆ ಇಮೇಲ್ ಕಳುಹಿಸಲಾಗಿದೆ',
};
