import AppEnvironment from './AppEnvironment';

const Url = {
  verifyMobileNumber: baesUrl() + '/app/auth/check-number',
  user_register: baesUrl() + '/app/auth/register',
  user_login: baesUrl() + '/app/auth/login',
  user_logout: baesUrl() + '/app/auth/logout',
  refreshToken: baesUrl() + '/app/auth/refresh-token',
  trustList: baesUrl() + '/app/trust/trusts',
  languageList: baesUrl() + '/app/language',
  setUserLanguage: baesUrl() + '/app/user/language',
  getUserDetails: baesUrl() + '/app/user/get-detail',
  getAllNews: baesUrl() + '/app/news',
  getTodayExclusive: baesUrl() + '/app/todays-exclusive',
  get_trust_notice: baesUrl() + '/app/trust/get-notice',

  getTodayExclusiveRandom: baesUrl() + '/app/todays-exclusive/random',
  subscribe: baesUrl() + '/app/user/subscribe',
  unSubscribe: baesUrl() + '/app/user/unsubscribe',
  dispute: baesUrl() + '/app/dispute/',
  addPanCard: baesUrl() + '/app/user/add-pan',
  updatePreference: baesUrl() + '/app/user/preference',
  masterCategory: baesUrl() + '/app/category/',
  subcategory: baesUrl() + '/app/category/sub-category',
  insightTotalDonationAmount: baesUrl() + '/app/insight/total-amount',
  getTrustSubscriptionsList: baesUrl() + '/app/trust/subscriptions',
  getTodaysExclusive: baesUrl() + '/app/thought',
  getCommitmentList: baesUrl() + '/app/commitment',
  makeCommitmentPayment: baesUrl() + '/app/commitment/make-payment',
  saveUserProfileDetails: baesUrl() + '/app/user/save-detail',
  getEventList: baesUrl() + '/app/trust/events',
  getNotices: baesUrl() + '/app/trust/notices',
  getTrustNews: baesUrl() + '/app/trust/news',
  getTrustEvents: baesUrl() + '/app/trust/events',
  makeDonation: baesUrl() + '/app/donation/create',
  updateDonation: baesUrl() + '/app/donation/update',
  deleteDonation: baesUrl() + '/app/donation/delete',
  donatedTrusts: baesUrl() + '/app/donation',
  getAdvertisement: baesUrl() + '/app/advertisement',
  getAdvertisementDetails: baesUrl() + '/app/advertisement/get',
  apnamandir_godList: baesUrl() + '/app/apna-mandir/gods',
  apnamandir_frames: baesUrl() + '/app/apna-mandir/background',
  create_apnamandir: baesUrl() + '/app/apna-mandir/create-mandir',
  get_user_mandir: baesUrl() + '/app/apna-mandir/get-mandir',
  getMediaVideos: baesUrl() + '/app/media/video',
  getMediaAudio: baesUrl() + '/app/media/audio',
  allEvent: baesUrl() + '/app/event/',
  state_city: baesUrl() + '/super-admin/state/',
  getTrustDetail: baesUrl() + '/app/trust/',
  punyarjak_detail: baesUrl() + '/app/trust/punyarjak-detail',

  getEventDates: baesUrl() + '/app/event/get-dates',
  jinvaniCategoriesList: baesUrl() + '/app/jinvani/categories',
  jinvaniList: baesUrl() + '/app/jinvani/',
  jinvaniChaptersList: baesUrl() + '/app/jinvani/chapters',
  jinvaniChaptersPageList: baesUrl() + '/app/jinvani/pages',
  punyarjak: baesUrl() + '/app/trust/punyarjak',
  eventDetails: baesUrl() + '/app/event/get-event',
  newsDetails: baesUrl() + '/app/news/get-news',
  exclusiveDetails: baesUrl() + '/app/todays-exclusive/get-exclusive',
  createInquiry: baesUrl() + '/app/inquiry/create',
  createFeedback: baesUrl() + '/app/feedback/create',
  updateProfileImage: baesUrl() + '/app/user/update-profile',
  getTrustType: baesUrl() + '/app/trust/trust-type',
  globalSearch: baesUrl() + '/app/global',
  deleteAccount: baesUrl() + '/app/user/delete',
  fileUpload: baesUrl() + '',
  jinvani_category: baesUrl() + '/app/jinvani/categories',
  app_preference: baesUrl() + '/app/preference',

  createGetHelpRequest: baesUrl() + '/app/help/create',
  get_business_types: baesUrl() + '/app/inquiry/business-types',

  get_trust_facility: baesUrl() + '/app/trust/trust-facility',

  bar_chart_total_donation: baesUrl() + '/app/insight/chart-data',
  pie_chart_total_donation: baesUrl() + '/app/insight/total-category-donation',

  get_top_donars: baesUrl() + '/app/trust/top-donars',

  switch_all_notification: baesUrl() + '/app/user/all-notification',
  switch_account_notification: baesUrl() + '/app/user/account-notification',
  switch_promotional_notification:
    baesUrl() + '/app/user/promotional-notification',

  social_login: baesUrl() + '/app/auth/social/login',

  reversGeoCoding: 'https://maps.googleapis.com/maps/api/geocode/json?',
  vedicBasic: 'https://json.astrologyapi.com/v1/basic_panchang',

  own_hindu_calendar: baesUrl() + '/public/calendar',
  get_month_hindu_calendar: baesUrl() + '/public/calendar/month',

  //CashFree
  pg_create_order: baesUrl() + '/app/order/create',

  notification_list: baesUrl() + '/app/notification',
  notification_seen: baesUrl() + '/app/notification/seen',

  device_register: baesUrl() + '/app/auth/register-device',
  force_update: baesUrl() + '/app/app-version',

  delete_account: baesUrl() + "'/app/delete'",
  receipt_download: baesUrl() + '/public/donation-pdf/download-pdf',
  commitment_receipt_download:
    baesUrl() + '/public/donation-pdf/download-commitment',
  verify_mail: baesUrl() + '/app/user/verify-mail',
};

const s3Bucket = {
  name: s3BucketName(),
};

const cashfreeUrls = {
  pancard_verification: cashfreeBaseUrl() + '/verification/pan',
};

function cashfreeBaseUrl() {
  if (
    AppEnvironment.current === AppEnvironment.Type.development ||
    AppEnvironment.Type.staging
  ) {
    return 'https://sandbox.cashfree.com';
  } else {
    return 'https://api.cashfree.com';
  }
}

function baesUrl() {
  if (AppEnvironment.current === AppEnvironment.Type.development) {
    return 'http://192.168.1.34:3005/v1';
  } else if (AppEnvironment.current === AppEnvironment.Type.staging) {
    return 'https://api.apnadharm.in/v1';
  } else if (AppEnvironment.current === AppEnvironment.Type.local) {
    return 'http://10.0.2.2:3005/v1';
  } else {
    return 'https://api.apnadharm.com/v1';
  }
}

function s3BucketName() {
  if (AppEnvironment.current === AppEnvironment.Type.staging) {
    return 'apnamandir-staging';
  } else if (AppEnvironment.current === AppEnvironment.Type.development) {
    return 'apnamandir';
  } else if (AppEnvironment.current === AppEnvironment.Type.production) {
    return 'apnadharm-prod';
  }
}

function getPrivacyPolicy() {
  return 'https://apnadharm.com/privacy-policy';
  // if (AppEnvironment.current === AppEnvironment.Type.development) {
  //   return 'https://api-am-dev.paridhan.app/privacy-policy'
  // } else if (AppEnvironment.current === AppEnvironment.Type.staging) {
  //   return "https://api-am-staging.paridhan.app/privacy-policy"
  // } else if (AppEnvironment.current === AppEnvironment.Type.production) {
  //   return "https://api-am-production.paridhan.app/privacy-policy"
  // }
}

function getTermsConditions() {
  return 'https://apnadharm.com/terms-conditions';

  // if (AppEnvironment.current === AppEnvironment.Type.development) {
  //   return 'https://api-am-dev.paridhan.app/terms-conditions'
  // } else if (AppEnvironment.current === AppEnvironment.Type.staging) {
  //   return "https://api-am-staging.paridhan.app/terms-conditions"
  // } else if (AppEnvironment.current === AppEnvironment.Type.production) {
  //   return "https://api-am-production.paridhan.app/terms-conditions"
  // }
}

function deepLinkUrl() {
  if (AppEnvironment.current === AppEnvironment.Type.development) {
    return 'https://share.apnadharm.dev';
  } else if (AppEnvironment.current === AppEnvironment.Type.staging) {
    return 'https://share.apnadharm.in';
  } else if (AppEnvironment.current === AppEnvironment.Type.local) {
    return 'http://10.0.2.2:3005/v1';
  } else {
    return 'https://share.apnadharm.com';
  }
}

const deeplink_base_url = deepLinkUrl();

const DeeplinkUrls = {
  punyarjak: deeplink_base_url + '/trust/punyarjak-detail',
  event: deeplink_base_url + '/event/get-event',
  trust: deeplink_base_url + '/app/trust/',
  punyarjak_detail: deeplink_base_url + '/app/trust/punyarjak-detail',
  news: deeplink_base_url + '/app/news/get-news',
};

const aws3 = {
  AWS_REGION: 'ap-south-1',
  AWS_IDENTITYPOOLID: 'ap-south-1:a852d5e8-4f7c-4fbf-a1ea-2c9d5eb04721',
  AWS_S3_BUCKET_NAME: 'apnamandir',
  AWS_S3_RESUOURCE_FOLDER: 'public/temp',
};

const googleService = {
  API_MAP_KEY: 'AIzaSyDs_3VzPUtrHV6s5XkaA_9AMvSCCprNdM8',
  GOOGLE_iOS_OAUTH_CLIENT_:
    '821319961998-traentk3h0m4ekuakkei0na67keusjl9.apps.googleusercontent.com',
  GOOGLE_ANDROID_OAUTH_CLIENT_:
    '821319961998-2cb0kq304piap5ovbfc6a1rdci12lsj5.apps.googleusercontent.com',
};

export default {
  s3Bucket,
  aws3,
  googleService,
  Url,
  cashfreeUrls,
  baesUrl,
  getPrivacyPolicy,
  getTermsConditions,
  DeeplinkUrls,
};
