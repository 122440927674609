export const gujarati = {
  //Sign In
  sign_in_with_mobile: 'મોબાઇલ નંબર સાથે સાઇન ઇન કરો',
  sign_in: 'સાઇન ઇન',
  sign_up: 'સાઇન અપ',
  dont_have_account: `ખાતું નથી?`,

  //Sign Up
  register_yourself: 'તમારું રજીસ્ટ્રેશન કરો',
  full_name: 'पूरુ નામ',
  mobile_number: 'મોબાઇલ નંબર',
  date_of_birth: 'જન્મ તારીખ',

  //Home
  sunset: 'સૂર્યાસ્ત',
  sunrise: 'સૂર્યોદય',

  thought_of_the_day: 'આજનો વિચાર',

  donation: 'દાન',
  commitment: 'પ્રતિબદ્ધતા',
  jinvani: 'શાસ્ત્ર',
  apna_mandir: 'અમારો મંદિર',
  videos: 'વિડિઓઝ',
  audios: 'ઑડિઓઝ',

  favorites: 'મનપસંદ',
  advertisements: 'જાહેરાતો',
  post_ads: 'જાહેરાત પોસ્ટ કરો',

  explore_temples: 'મંદિરો શોધો',

  todays_exclusive: 'આજના વિશિષ્ટ',

  //Search Home
  header_trust: 'ટ્રસ્ટ',
  header_news: 'સમાચાર',
  header_event: 'પ્રયોગ',
  header_todayexclusive: 'આજના વિશિષ્ટ',

  //Donation
  no_donation_found: 'કોઈ દાન મળ્યું નથી',
  make_donation: 'દાન કરો',
  all: 'બધા',

  //Quick Donation
  make_donate_title: 'દાન કરો',

  //Quick Add trut to Favorites
  add_to_favorites_list_title: 'મનપસંદ યાદીમાં ઉમેરો',
  added_fav_trust_success_msg:
    'આ ટ્રસ્ટ સફળતાપૂર્વક તમારા મનપસંદ યાદીમાં ઉમેરવામાં આવ્યું છે',
  removed_fav_trust_success_msg:
    'આ ટ્રસ્ટ સફળતાપૂર્વક તમારા મનપસંદ યાદીમાંથી દૂર કરવામાં આવ્યું છે',

  //Mandir Details
  btn_donate_title: 'હવે દાન કરો',
  btn_commitments_title: 'પ્રતિબદ્ધતાઓ',
  alert_message_payment_pause: 'હાલમાં આ માટે દાન કરી શકતા નથી',
  text_address: 'સરનામું',
  text_contact_number: 'સંપર્ક નંબર',
  text_email: 'ઈમેલ',

  //Report and Dispute
  report_and_dispute: 'અહેવાલ અને વિવાદ',
  payment_report: 'ચુકવણી અહેવાલ',
  trust_name: 'ટ્રસ્ટનું નામ',

  //QuickInsights
  total_donation: 'કુલ દાન',
  total_commitment: 'કુલ પ્રતિબદ્ધતા',

  //Insights
  title_insights: 'અંદાજો',

  //PaidAmountReceipt
  total_amount: 'કુલ રકમ',
  paid_amount: 'ચુકવેલ રકમ',
  mode_of_payment: 'ચુકવણીની રીત',
  donor_name: 'દાનકર્તાનું નામ',
  date_and_time: 'તારીખ અને સમય',
  category: 'શ્રેણી',
  download_receipt: 'રસીદ ડાઉનલોડ કરો',

  //Global Event
  no_event_on_selected_date: 'પસંદ કરેલ તારીખે કોઈ ઇવેન્ટ નથી',
  no_data_no_event_found: 'કોઈ ઇવેન્ટ મળ્યો નથી',
  there_no_event: 'કોઈ ઇવેન્ટ નથી.',

  //Event Details
  event_details: 'વિગતો',
  event_about: 'વિશે',
  event_location: 'સ્થાન',
  event_timing: 'સમય',

  //Success Payment Pop
  anumodnaTitle: 'અનુમોદના',
  successDonationMsg:
    'અમે તમારા દાન માટે આભારી છીએ, અમે આશા કરીએ છીએ કે તમે દાન આપીને પુણ્યલાભ મેળવતા રહો.',
  donate_again: 'ફરીથી દાન કરો',

  //Completed Commitment
  commitments_title: 'પ્રતિબદ્ધતાઓ',
  there_is_no_commitment_yet: 'હજી સુધી કોઈ પ્રતિબદ્ધતા નથી',

  //Jinvani
  chapters_title: 'અધ્યાય',

  no_jinvani_chapters_found: 'કોઈ શાસ્ત્ર અધ્યાય મળ્યા નથી',

  //Jinavni Chapter List
  title_jinvani_chapter: 'શાસ્ત્ર અધ્યાય',
  total_chapters: 'કુલ પાનાં',
  total_chapter: 'કુલ પાનું',
  number_of_chapters: 'અધ્યાયોની સંખ્યા',
  number_of_chapter: 'અધ્યાયની સંખ્યા',
  number_of_pages: 'પાનાંઓની સંખ્યા',
  number_of_page: 'પાનાંની સંખ્યા',

  //Donation Payment
  donation_payment: 'દાન ચુકવણી',
  donor_name: 'દાનકર્તાનું નામ',
  donation_amount: 'રકમ દાખલ કરો',

  btn_make_payment: 'ચુકવણી કરો',

  //Profile
  personal_information: 'વ્યક્તિગત માહિતી',
  notifications: 'સૂચનાઓ',
  quick_links: 'ઝડપી કડીઓ',
  terms_conditions: 'શરતો અને નિયમો',
  privacy_policy: 'ગોપનીયતા નીતિ',
  contact_support: 'સહાય માટે સંપર્ક કરો',
  get_help: 'મદદ મેળવો',
  give_us_feedback: 'અમને પ્રતિસાદ આપો',
  post_your_ads: 'જાહેરાત પોસ્ટ કરો',
  logout: 'લૉગ આઉટ',

  version: 'આવૃત્તિ',

  //Profile Details
  name: 'નામ',
  email: 'ઈમેલ',
  phone: 'ફોન',
  select_date_of_birth: 'જન્મ તારીખ પસંદ કરો',
  address: 'સરનામું',
  state: 'રાજ્ય',
  city: 'શહેર',
  pan_card: 'પેન કાર્ડ',

  //Post Ads
  business_name_title: 'વ્યવસાયનું નામ',
  business_type_title: 'વ્યવસાયનો પ્રકાર',
  select_business_type: 'વ્યવસાયનો પ્રકાર પસંદ કરો',
  enter_business_type: 'તમારા વ્યવસાયનો પ્રકાર દાખલ કરો',

  //Account Preferences
  account_preferences: 'ખાતા માટે પસંદગીઓ',
  allPushNotification: 'બધા પુષ સૂચનાઓ',
  accountNotification: 'ખાતા સૂચના',
  promotionalNotification: 'પ્રચારાત્મક સૂચના',

  appPreference: 'એપ માટે પસંદગી',
  deleteAccount: 'ખાતું ડિલીટ કરો',

  language_preferences: 'ભાષા માટે પસંદગી',

  //Add Pan Card
  pancard_security_message:
    'તમારા વ્યક્તિગત ડેટા અમારા ડેટાબેઝમાં સુરક્ષિત છે અને એન્ક્રિપ્ટ કરેલા સ્વરૂપે સંગ્રહિત છે.',
  pan_card_number: 'પેન કાર્ડ નંબર',
  pan_holder_name: 'કાર્ડ ધારકનું નામ',
  pan_card_photo: 'પેન કાર્ડ ફોટો',
  add_pan_card: 'પેન કાર્ડ ઉમેરો',
  btn_title_add_card: 'કાર્ડ ઉમેરો',

  //Commitment payement
  commitments_title: 'પ્રતિબદ્ધતાઓ',
  total_amount: 'કુલ રકમ',
  paid_amount: 'ચુકવેલ રકમ',
  payable_amount: 'ચુકવવાપાત્ર રકમ',
  full_payment: 'પૂર્ણ ચુકવણી',
  make_commitment: 'પ્રતિબદ્ધતા કરો',
  partial_payment: 'આંશિક ચુકવણી',

  //Buttons Title
  apply: 'લાગુ કરો',
  update_profile: 'પ્રોફાઇલ અપડેટ કરો',

  view_all: 'બધું જુઓ',
  ok: 'બરાબર',
  cancel: 'રદ્દ કરો',
  continue: 'ચાલુ રાખો',
  skip: 'કૂદકો મારવો',
  report: 'અહેવાલ',

  //Placeholder
  enter_your_name: 'તમારું નામ દાખલ કરો',
  enter_your_email: 'તમારો ઈમેલ દાખલ કરો',
  enter_your_phone: 'તમારો ફોન નંબર દાખલ કરો',
  enter_your_dob: 'તમારી જન્મ તારીખ દાખલ કરો',
  select_address: 'સરનામું પસંદ કરો',
  add_edit_pancard_details: 'પેન કાર્ડ વિગતો ઉમેરો/સંપાદિત કરો',
  enter_mobile_number: 'મોબાઇલ નંબર દાખલ કરો',
  p_search_temple: 'મંદિર શોધો',

  p_home_search: 'ટ્રસ્ટ, ઇવેન્ટ, સમાચાર શોધો..',
  p_commitment_search: 'મંદિર શોધો',
  p_jinvani_search: 'શાસ્ત્ર શોધો',
  p_jinvani_chapter_search: 'અધ્યાય નામ શોધો',

  p_enter_pan_number: 'પેન કાર્ડ નંબર દાખલ કરો',
  p_enter_pan_holder_name: 'પેન કાર્ડ પરનું નામ દાખલ કરો',

  p_enter_donar_name: 'દાનકર્તાનું નામ દાખલ કરો',

  p_select_category: 'શ્રેણી પસંદ કરો',
  p_select_subcategory: 'ઉપ શ્રેણી પસંદ કરો',

  p_enter_donation_amount: 'રકમ દાખલ કરો',
  p_enter_payable_amount: 'ચુકવવાપાત્ર રકમ દાખલ કરો',

  p_enter_trust_name: 'ટ્રસ્ટનું નામ દાખલ કરો',

  p_search_event: 'આગામી ઇવેન્ટ શોધો',
  //No Data message
  no_commitment_found: 'કોઈ પ્રતિબદ્ધતા મળી નથી',
  no_about_text_found: 'વિશે વિભાગ હજી ઉમેરવામાં આવ્યો નથી.',
  no_news_found: 'કોઈ સમાચાર ઉમેરવામાં આવ્યા નથી.',
  no_event_found: 'કોઈ ઇવેન્ટ ઉમેરવામાં આવ્યો નથી.',
  no_photos_found: 'કોઈ ફોટા ઉમેરવામાં આવ્યા નથી.',
  no_facility_found: 'કોઈ સુવિધા ઉમેરવામાં આવી નથી.',
  no_punyarjak_found: 'કોઈ પુણ્યર્જક ઉમેરવામાં આવ્યા નથી.',
  no_top_doner_found: 'યાદી હાલમાં ખાલી છે, કોઈ દાતાઓ યાદીમાં નથી.',
  no_appPreference: 'કોઈ એપ પસંદગી ઉપલબ્ધ નથી',
  //Alert Messages
  error_enterFullName: 'કૃપા કરીને પૂરું નામ દાખલ કરો.',
  error_enterMobileNumber: 'કૃપા કરીને માન્ય મોબાઇલ નંબર દાખલ કરો.',
  error_validEmail: 'કૃપા કરીને માન્ય ઈમેલ દાખલ કરો.',
  error_enterEmail: 'કૃપા કરીને ઈમેલ દાખલ કરો.',
  error_enterDob: 'કૃપા કરીને જન્મ તારીખ પસંદ કરો.',
  error_enterOtp: 'કૃપા કરીને માન્ય otp દાખલ કરો',
  error_enterTrustDescription: 'કૃપા કરીને વર્ણન દાખલ કરો',
  error_enter_pan_holdername: 'કૃપા કરીને કાર્ડ ધારકનું નામ દાખલ કરો',
  error_pan_image_not_found: 'કૃપા કરીને પેન કાર્ડ ફોટો અપલોડ કરો',
  error_enter_valid_pan_card_no: 'કૃપા કરીને પેન કાર્ડ નંબર દાખલ કરો',
  mesg_logout_confirmation: 'શું તમે ખાતરી છો કે તમે લૉગઆઉટ કરવા માગો છો?',
  error_already_reported_trust: 'આ ટ્રસ્ટને પહેલાથી જ રિપોર્ટ કર્યું છે',

  noInternetMessage:
    'ખાતરી કરો કે તમારો વાયફાય અથવા સેલ્યુલર ડેટા ચાલુ છે અને ફરી પ્રયાસ કરો',

  error_select_category_first: 'પ્રથમ શ્રેણી પસંદ કરો',
  error_please_select_category: 'કૃપા કરીને શ્રેણી પસંદ કરો',
  error_please_select_subcategory: 'કૃપા કરીને ઉપ શ્રેણી પસંદ કરો',
  error_please_enter_donation_amount: 'કૃપા કરીને દાન રકમ દાખલ કરો',

  error_entered_amount_is_not_valid: 'દાખલ કરેલી રકમ માન્ય નથી',

  api_error_something_went_wrong:
    'કૈંક ખોટું થયું, કૃપા કરીને થોડા સમય પછી પ્રયાસ કરો',

  api_error_donation_payment_cancelled: 'દાનની ચુકવણી રદ્દ કરવામાં આવી છે',
  api_error_commitment_payment_cancelled:
    'પ્રતિબદ્ધતાની ચુકવણી રદ્દ કરવામાં આવી છે',

  api_success_donation_payment_success: 'દાનની ચુકવણી સફળતાપૂર્વક થઈ છે',
  api_success_commitment_payment_success:
    'પ્રતિબદ્ધતાની ચુકવણી સફળતાપૂર્વક થઈ છે',

  api_success_post_ad:
    'જાહેરાત પોસ્ટ કરવાની તમારી વિનંતી સફળતાપૂર્વક સબમિટ કરવામાં આવી છે.',
  api_success_profile_image_uploaded:
    'પ્રોફાઇલ છબી સફળતાપૂર્વક અપડેટ કરવામાં આવી છે',
  api_success_profile_details:
    'પ્રોફાઇલ વિગતો સફળતાપૂર્વક અપડેટ કરવામાં આવી છે',

  api_success_pan_added: 'તમારો પેન કાર્ડ સફળતાપૂર્વક ઉમેરવામાં આવ્યો છે.',
  api_success_pan_updated:
    'તમારો પેન કાર્ડ સફળતાપૂર્વક અપડેટ કરવામાં આવ્યો છે.',

  api_success_get_help: 'તમારી વિનંતી સફળતાપૂર્વક મોકલવામાં આવી છે',
  api_success_sent_feedback: 'આભાર! તમારા પ્રતિસાદ માટે.',

  api_success_added_commitment: 'પ્રતિબદ્ધતા સફળતાપૂર્વક ઉમેરવામાં આવી છે',

  //After Provided 28-June
  no_data__notice_has_been_added: 'કોઈ નોટિસ ઉમેરવામાં આવી નથી.',
  due_amount: 'બાકી રકમ',
  btn_title_update_card: 'અપડેટ કરો',
  update_pan_card: 'પેન કાર્ડ અપડેટ કરો',

  delete_account_alert_msg:
    'શું તમે ખાતરી કરો છો કે તમે ખાતું ડિલીટ કરવા માંગો છો',
  delete_title: 'ડિલીટ કરો',
  notification_remove_msg:
    'અહીં દેખાયેલી સૂચનાઓ પ્રાપ્ત થયાના 24 કલાક પછી દૂર કરવામાં આવશે.',
  get_help_title: 'મદદ મેળવો',

  description_title: 'વર્ણન',

  p_enter_title: 'તમારું શીર્ષક અહીં દાખલ કરો',
  p_enter_description: 'તમારું વર્ણન અહીં લખો',
  p_enter_your_name: 'તમારું નામ દાખલ કરો',
  p_enter_business_name: 'વ્યવસાય નામ દાખલ કરો',
  p_enter_enter_business_type: 'વ્યવસાય પ્રકાર દાખલ કરો',
  p_search_trust: 'ટ્રસ્ટ શોધો',
  p_search_news: 'સમાચાર શોધો',
  p_search_location: 'સ્થાન શોધો',
  p_search_state: 'રાજ્ય શોધો',
  p_search_city: 'શહેર શોધો',
  p_search_audio: 'ઑડિયો શોધો',
  //No Data Found
  no_results_found: 'કોઈ પરિણામ મળ્યું નથી',
  there_no_trust: 'કોઈ ટ્રસ્ટ નથી',
  there_no_news: 'કોઈ સમાચાર નથી',
  no_news_found: 'કોઈ સમાચાર મળ્યા નથી',
  no_location_found: 'કોઈ સ્થાન મળ્યું નથી',
  no_video_available: 'કોઈ વિડિઓ મળ્યો નથી',
  no_video_found: 'કોઈ વિડિઓ મળ્યો નથી',

  no_audio_available: 'કોઈ ઑડિયો મળ્યો નથી',

  btn_title_submit: 'સબમિટ કરો',

  give_us_feedback_title: 'અમને પ્રતિસાદ આપો',
  title_text: 'શીર્ષક',

  star_rating: 'તમારા સ્ટાર રેટિંગ',

  customise_mandir: 'મંદિરને કસ્ટમાઇઝ કરો',

  select_state_title: 'રાજ્ય પસંદ કરો',
  select_city_title: 'શહેર પસંદ કરો',
  add_more: 'વધારે ઉમેરો',
  app_preference: 'એપ માટે પસંદગી',
  no_language: 'કોઈ ભાષા ઉપલબ્ધ નથી',

  //
  location_permission_message:
    'શું તમે નજીકના મંદિરો, ઇવેન્ટ્સ અને સમાચાર શોધવા માંગો છો?',
  location_permission_msg_2: 'તમારું સ્થાન અમને શેર કરીને શરૂઆત કરો',
  location_permission_button: 'સ્થાન સેવાઓને મંજૂરી આપો',
  maybe_later_button: 'શાયદ પછી',
  btn_commitment_payment: 'પ્રતિબદ્ધતા',
  no_notification_available: 'કોઈ સૂચનાઓ ઉપલબ્ધ નથી',
  tag: 'ટેગ્સ',

  lanuage_changed_success: 'ભાષા સફળતાપૂર્વક અપડેટ કરવામાં આવી છે',
  language_already_selected: 'ભાષા પહેલેથી પસંદ છે',
  account_delete_successfully: 'તમારું ખાતું સફળતાપૂર્વક ડિલીટ થયું છે',

  error_enter_mobile_number: 'કૃપા કરીને મોબાઇલ નંબર દાખલ કરો',
  get_directions: 'માર્ગદર્શન મેળવો',

  temple_info: 'મંદિર માહિતી',
  notices: 'નોટિસ',
  about: 'વિશે',
  news: 'સમાચાર',
  events: 'ઇવેન્ટ્સ',
  gallery: 'ગેલેરી',
  facilities: 'સુવિધાઓ',
  punyarjak: 'પુણ્યર્જક',
  top_Donor: 'ટોપ દાતા',

  change_background: 'પૃષ્ઠભૂમિ બદલો',
  add_background: 'પૃષ્ઠભૂમિ ઉમેરો',
  change_god_position: 'ભગવાનની સ્થિતિ બદલો',
  add_all_gods: 'બધા ભગવાન ઉમેરો',

  title_address: 'સરનામું',
  title_location: 'સ્થાન',
  title_city: 'શહેર',
  title_state: 'રાજ્ય',

  txt_donate: 'દાન કરો',

  choose_from_gallery_or_take_a_new_one:
    'ગેલેરીમાંથી પસંદ કરો અથવા નવો ફોટો લો',
  camera: 'કેમેરા',
  btn_gallery: 'ગેલેરી',
  msg_payment_receipt:
    'દરેક દાન સાથે, અમે અમારા લક્ષ્યની નજીક આવીએ છીએ. તમારા દાન અને સહાનુભૂતિ બદલ આભાર',

  monthly: 'માસિક',
  weekly: 'સાપ્તાહિક',
  yearly: 'વાર્ષિક',

  no_jinvani_found: 'કોઈ શાસ્ત્ર મળ્યો નથી',

  search_video: 'વિડિઓ શોધો',

  saved_payment_receipt: 'ચુકવણી રસીદ સફળતાપૂર્વક સંગ્રહવામાં આવી છે',

  create_account: 'ખાતું બનાવો',
  by_signing_up: 'સાઇન અપ કરીને તમે સંમત થાઓ છો',
  privacy_policy: 'ગોપનીયતા નીતિ',
  already_have_an_account: 'પહેલાથી જ એકાઉન્ટ છે?',
  translate_jinvani: 'શાસ્ત્ર ભાષાંતર',

  //
  DONE: 'પુરું',
  Recipt: 'રસીદ',

  DeleteAccount: 'ખાતું ડિલીટ કરો',

  deleteAccountConfrimationMesage:
    'શું તમે ખાતરી છો કે તમે તમારું ખાતું ડિલીટ કરવા માંગો છો? તમારું બધું ડેટા હંમેશા માટે ગુમ થઈ જશે અને પુનઃપ્રાપ્ત નહીં થાય.',

  deleteAccountWarningMessage:
    'શું તમે ખાતરી છો કે તમે તમારું ખાતું ડિલીટ કરવા માંગો છો?\n\nતમે 14 દિવસની અંદર પાછા લોગિન કરીને તમારું ખાતું ફરીથી સક્રિય કરી શકો છો. તે પછી, તમારી તમામ ખાતાની વિગતો અમારું રેકોર્ડમાંથી હંમેશા માટે ડિલીટ થઈ જશે.',

  WhatDoYouWantToDo: 'તમે શું કરવા માંગો છો?',

  NoSearchEnteredYet: 'હજી સુધી કોઈ શોધ દાખલ કરાઇ નથી',
  SorryWeCouldnFindAnyResults: 'માફ કરશો, અમને કોઈ પરિણામ મળ્યા નથી',

  btnNo: 'ના',
  btnYes: 'હા',

  AppDisplayName: 'આપણા ધર્મ',

  EnterTitle: 'શીર્ષક દાખલ કરો',
  AddTempleBackground: 'મંદિર પૃષ્ઠભૂમિ ઉમેરો',
  NoItemFound: 'કોઈ વસ્તુ મળી નથી!',
  //
  MobileVerification: 'મોબાઇલ વેરિફિકેશન',
  EnterTheDigitCodeThatWasSentToYourRegisteredMobileNumber: `તમારા નોંધાયેલ મોબાઇલ નંબર પર મોકલેલ 6-આંકનો કોડ દાખલ કરો.`,
  Verifying: 'ચકાસી રહ્યા છીએ...',
  VerifyMobileNumber: 'મોબાઇલ નંબર ચકાસો',
  ResendCode: 'કોડ ફરી મોકલો',

  No: 'ના',
  Found: 'મળ્યું',

  Published_on: 'પ્રકાશિત: ',
  receipt_url_not_available: 'રસીદ ઉપલબ્ધ નથી કૃપા કરીને પછી પ્રયાસ કરો',
  DonorName: 'દાનકર્તાનું નામ :',
  SelectBackground: 'પૃષ્ઠભૂમિ પસંદ કરો',

  Subcategory: 'ઉપશ્રેણી',

  newUpdateAvailableText: 'નવી અપડેટ ઉપલબ્ધ છે',
  newUpdateAvailableMessage:
    'સુધારેલી અનુભૂતિ માટે કૃપા કરીને અમારું એપ અપડેટ કરો! આ આવૃત્તિ હવે સપોર્ટેડ નથી.',
  updateNow: 'હવે અપડેટ કરો',
  notNow: 'હવે નહીં',

  verificationEmailHasBeenSentToYourEmail:
    'તમારા નોંધાયેલ ઈમેલ પર ચકાસણી ઈમેલ મોકલવામાં આવી છે',
};
