export const tamil = {
  //Sign In
  sign_in_with_mobile: 'மொபைல் எண்ணுடன் உள்நுழைக',
  sign_in: 'உள்நுழைய',
  sign_up: 'பதிவு செய்ய',
  dont_have_account: `கணக்கு இல்லையா?`,

  //Sign Up
  register_yourself: 'உங்களை பதிவு செய்யவும்',
  full_name: 'முழு பெயர்',
  mobile_number: 'மொபைல் எண்',
  date_of_birth: 'பிறந்த தேதி',

  //Home
  sunset: 'சூரியஅஸ்தமனம்',
  sunrise: 'சூரிய உதயம்',

  thought_of_the_day: 'இன்றைய சிந்தனை',

  donation: 'நன்கொடைகள்',
  commitment: 'உறுதிமொழி',
  jinvani: 'சாஸ்திரம்',
  apna_mandir: 'எங்கள் கோவில்',
  videos: 'வீடியோக்கள்',
  audios: 'ஆடியோக்கள்',

  favorites: 'பிடித்தவை',
  advertisements: 'விளம்பரங்கள்',
  post_ads: 'விளம்பரங்களை இடுங்கள்',

  explore_temples: 'கோவில்களை ஆராயுங்கள்',

  todays_exclusive: 'இன்றைய சிறப்பு',

  //Search Home
  header_trust: 'நம்பிக்கை',
  header_news: 'செய்திகள்',
  header_event: 'நிகழ்ச்சி',
  header_todayexclusive: 'இன்றைய சிறப்பு',

  //Donation
  no_donation_found: 'நன்கொடை இல்லை',
  make_donation: 'நன்கொடை செய்யவும்',
  all: 'அனைத்தும்',

  //Quick Donation
  make_donate_title: 'நன்கொடை செய்யவும்',

  //Quick Add trut to Favorites
  add_to_favorites_list_title: 'பிடித்தவர்களின் பட்டியலில் சேர்க்கவும்',
  added_fav_trust_success_msg:
    'இந்த நம்பிக்கை உங்கள் பிடித்த பட்டியலில் வெற்றிகரமாக சேர்க்கப்பட்டது',
  removed_fav_trust_success_msg:
    'இந்த நம்பிக்கை உங்கள் பிடித்த பட்டியலில் இருந்து வெற்றிகரமாக நீக்கப்பட்டது',

  //Mandir Details
  btn_donate_title: 'இப்போது நன்கொடை செய்யவும்',
  btn_commitments_title: 'உறுதிமொழிகள்',
  alert_message_payment_pause: 'தற்சமயம் இதற்கு நன்கொடை செய்ய இயலாது',
  text_address: 'முகவரி',
  text_contact_number: 'தொடர்பு எண்',
  text_email: 'மின்னஞ்சல்',

  //Report and Dispute
  report_and_dispute: 'அறிக்கை மற்றும் மோதல்',
  payment_report: 'கட்டண அறிக்கை',
  trust_name: 'நம்பிக்கை பெயர்',

  //QuickInsights
  total_donation: 'மொத்த நன்கொடைகள்',
  total_commitment: 'மொத்த உறுதிமொழிகள்',

  //Insights
  title_insights: 'நுண்ணறிவுகள்',

  //PaidAmountReceipt
  total_amount: 'மொத்த தொகை',
  paid_amount: 'செலுத்திய தொகை',
  mode_of_payment: 'கட்டண முறை',
  donor_name: 'நன்கொடையாளர் பெயர்',
  date_and_time: 'தேதி மற்றும் நேரம்',
  category: 'வகை',
  download_receipt: 'ரசீதை பதிவிறக்கவும்',

  //Global Event
  no_event_on_selected_date:
    'தேர்ந்தெடுக்கப்பட்ட தேதியில் எந்த நிகழ்வும் இல்லை',
  no_data_no_event_found: 'நிகழ்வு எதுவும் இல்லை',
  there_no_event: 'நிகழ்வு எதுவும் இல்லை.',

  //Event Details
  event_details: 'விவரங்கள்',
  event_about: 'பற்றி',
  event_location: 'இடம்',
  event_timing: 'நேரம்',

  //Success Payment Pop
  anumodnaTitle: 'அனுமோதனை',
  successDonationMsg:
    'உங்கள் நன்கொடைக்கு நாங்கள் நன்றியுள்ளவர்களாக இருக்கிறோம், நாங்கள் உங்களை தொடர்ந்து நன்கொடைகள் வழங்கி புண்ணியலாபம் அடைய விரும்புகிறோம்.',
  donate_again: 'மீண்டும் நன்கொடை செய்யவும்',

  //Completed Commitment
  commitments_title: 'உறுதிமொழிகள்',
  there_is_no_commitment_yet: 'இன்னும் எந்த உறுதிமொழியும் இல்லை',

  //Jinvani
  chapters_title: 'அத்தியாயங்கள்',

  no_jinvani_chapters_found: 'எந்த சாஸ்திரம் அத்தியாயங்களும் இல்லை',

  //Jinavni Chapter List
  title_jinvani_chapter: 'சாஸ்திரம் அத்தியாயம்',
  total_chapters: 'மொத்த பக்கங்கள்',
  total_chapter: 'மொத்த பக்கம்',
  number_of_chapters: 'அத்தியாயங்களின் எண்ணிக்கை',
  number_of_chapter: 'அத்தியாயத்தின் எண்ணிக்கை',
  number_of_pages: 'பக்கங்களின் எண்ணிக்கை',
  number_of_page: 'பக்கத்தின் எண்ணிக்கை',

  //Donation Payment
  donation_payment: 'நன்கொடை கட்டணம்',
  donor_name: 'நன்கொடையாளர் பெயர்',
  donation_amount: 'தொகையை உள்ளிடவும்',

  btn_make_payment: 'கட்டணம் செலுத்து',

  //Profile
  personal_information: 'தனிப்பட்ட தகவல்',
  notifications: 'அறிவிப்புகள்',
  quick_links: 'விரைவு இணைப்புகள்',
  terms_conditions: 'விதிமுறைகள் மற்றும் நிபந்தனைகள்',
  privacy_policy: 'தனியுரிமை கொள்கை',
  contact_support: 'ஆதரவை தொடர்பு கொள்ளுங்கள்',
  get_help: 'உதவி பெறவும்',
  give_us_feedback: 'எங்களுக்கு கருத்து தெரிவிக்கவும்',
  post_your_ads: 'உங்கள் விளம்பரங்களை இடுங்கள்',
  logout: 'வெளியேறு',

  version: 'பதிப்பு',

  //Profile Details
  name: 'பெயர்',
  email: 'மின்னஞ்சல்',
  phone: 'தொலைபேசி',
  select_date_of_birth: 'பிறந்த தேதியைத் தேர்ந்தெடுக்கவும்',
  address: 'முகவரி',
  state: 'மாநிலம்',
  city: 'நகரம்',
  pan_card: 'பான் அட்டை',

  //Post Ads
  business_name_title: 'வணிகத்தின் பெயர்',
  business_type_title: 'வணிகத்தின் வகை',
  select_business_type: 'வணிகத்தின் வகையைத் தேர்ந்தெடுக்கவும்',
  enter_business_type: 'உங்கள் வணிகத்தின் வகையை உள்ளிடவும்',

  //Account Preferences
  account_preferences: 'கணக்கு விருப்பங்கள்',
  allPushNotification: 'அனைத்து புஷ் அறிவிப்புகள்',
  accountNotification: 'கணக்கு அறிவிப்பு',
  promotionalNotification: 'விளம்பர அறிவிப்பு',

  appPreference: 'செயலி விருப்பம்',
  deleteAccount: 'கணக்கை நீக்கு',

  language_preferences: 'மொழி விருப்பங்கள்',

  //Add Pan Card
  pancard_security_message:
    'உங்கள் தனிப்பட்ட தரவுகள் எங்கள் தரவுத்தொகுப்பில் பாதுகாப்பாகவும் குறியாக்கத்திலும் சேமிக்கப்படுகிறது.',
  pan_card_number: 'பான் அட்டை எண்',
  pan_holder_name: 'அட்டைதாரரின் பெயர்',
  pan_card_photo: 'பான் அட்டை புகைப்படம்',
  add_pan_card: 'பான் அட்டை சேர்க்கவும்',
  btn_title_add_card: 'அட்டை சேர்க்கவும்',

  //Commitment payement
  commitments_title: 'உறுதிமொழிகள்',
  total_amount: 'மொத்த தொகை',
  paid_amount: 'செலுத்திய தொகை',
  payable_amount: 'செலுத்த வேண்டிய தொகை',
  full_payment: 'முழு கட்டணம்',
  make_commitment: 'உறுதிமொழி செய்க',
  partial_payment: 'பகுதி கட்டணம்',

  //Buttons Title
  apply: 'விண்ணப்பிக்கவும்',
  update_profile: 'சுயவிவரம் புதுப்பிக்கவும்',

  view_all: 'அனைத்தையும் காண்க',
  ok: 'சரி',
  cancel: 'ரத்து செய்',
  continue: 'தொடரவும்',
  skip: 'தவிர்க்கவும்',
  report: 'அறிக்கை',

  //Placeholder
  enter_your_name: 'உங்கள் பெயரை உள்ளிடவும்',
  enter_your_email: 'உங்கள் மின்னஞ்சலை உள்ளிடவும்',
  enter_your_phone: 'உங்கள் தொலைபேசி எண்ணை உள்ளிடவும்',
  enter_your_dob: 'உங்கள் பிறந்த தேதியை உள்ளிடவும்',
  select_address: 'முகவரியைத் தேர்ந்தெடுக்கவும்',
  add_edit_pancard_details: 'பான் அட்டை விவரங்களை சேர்க்கவும்/தொகுக்கவும்',
  enter_mobile_number: 'மொபைல் எண்ணை உள்ளிடவும்',
  p_search_temple: 'கோவிலைத் தேடுங்கள்',

  p_home_search: 'நம்பிக்கை, நிகழ்ச்சி, செய்தி தேடுங்கள்..',
  p_commitment_search: 'கோவிலைத் தேடுங்கள்',
  p_jinvani_search: 'சாஸ்திரம் தேடுங்கள்',
  p_jinvani_chapter_search: 'அத்தியாயத்தின் பெயரைத் தேடுங்கள்',

  p_enter_pan_number: 'பான் அட்டை எண்ணை உள்ளிடவும்',
  p_enter_pan_holder_name: 'பான் அட்டையில் உள்ள பெயரை உள்ளிடவும்',

  p_enter_donar_name: 'நன்கொடையாளர் பெயரை உள்ளிடவும்',

  p_select_category: 'வகையைத் தேர்ந்தெடுக்கவும்',
  p_select_subcategory: 'துணை வகையைத் தேர்ந்தெடுக்கவும்',

  p_enter_donation_amount: 'தொகையை உள்ளிடவும்',
  p_enter_payable_amount: 'செலுத்த வேண்டிய தொகையை உள்ளிடவும்',

  p_enter_trust_name: 'நம்பிக்கை பெயரை உள்ளிடவும்',

  p_search_event: 'வரவிருக்கும் நிகழ்வுகளைத் தேடுங்கள்',
  //No Data message
  no_commitment_found: 'எந்த உறுதிமொழியும் இல்லை',
  no_about_text_found: 'பற்றி பிரிவு இன்னும் சேர்க்கப்படவில்லை.',
  no_news_found: 'எந்த செய்தியும் சேர்க்கப்படவில்லை.',
  no_event_found: 'எந்த நிகழ்வும் சேர்க்கப்படவில்லை.',
  no_photos_found: 'எந்த புகைப்படங்களும் சேர்க்கப்படவில்லை.',
  no_facility_found: 'எந்த வசதியும் சேர்க்கப்படவில்லை.',
  no_punyarjak_found: 'எந்த புண்யர்ஜாக்களும் சேர்க்கப்படவில்லை.',
  no_top_doner_found:
    'பட்டியல் தற்போது காலியாக உள்ளது, எந்த தானதாரர்களும் பட்டியலில் இல்லை.',
  no_appPreference: 'எந்த செயலி விருப்பமும் கிடைக்கவில்லை',
  //Alert Messages
  error_enterFullName: 'தயவுசெய்து முழு பெயரை உள்ளிடவும்.',
  error_enterMobileNumber: 'தயவுசெய்து சரியான மொபைல் எண்ணை உள்ளிடவும்.',
  error_validEmail: 'தயவுசெய்து சரியான மின்னஞ்சல் முகவரியை உள்ளிடவும்.',
  error_enterEmail: 'தயவுசெய்து மின்னஞ்சல் முகவரியை உள்ளிடவும்.',
  error_enterDob: 'தயவுசெய்து பிறந்த தேதியை தேர்ந்தெடுக்கவும்.',
  error_enterOtp: 'தயவுசெய்து சரியான OTPஐ உள்ளிடவும்',
  error_enterTrustDescription: 'தயவுசெய்து விவரத்தை உள்ளிடவும்',
  error_enter_pan_holdername: 'தயவுசெய்து அட்டைதாரரின் பெயரை உள்ளிடவும்',
  error_pan_image_not_found: 'தயவுசெய்து பான் அட்டை புகைப்படத்தை பதிவேற்றவும்',
  error_enter_valid_pan_card_no: 'தயவுசெய்து பான் அட்டை எண்ணை உள்ளிடவும்',
  mesg_logout_confirmation:
    'நீங்கள் நிச்சயமாக லாக் அவுட் செய்ய விரும்புகிறீர்களா?',
  error_already_reported_trust: 'இந்த நம்பிக்கையை ஏற்கனவே அறிவித்தது',

  noInternetMessage:
    'உங்கள் வைஃபை அல்லது செல்யுலர் தரவுகள் இயக்கப்பட்டிருப்பதை உறுதிசெய்கின்றீர்கள் மற்றும் மீண்டும் முயற்சிக்கவும்',

  error_select_category_first: 'முதலில் வகையைத் தேர்ந்தெடுக்கவும்',
  error_please_select_category: 'தயவுசெய்து வகையைத் தேர்ந்தெடுக்கவும்',
  error_please_select_subcategory: 'தயவுசெய்து துணை வகையைத் தேர்ந்தெடுக்கவும்',
  error_please_enter_donation_amount: 'தயவுசெய்து நன்கொடை தொகையை உள்ளிடவும்',

  error_entered_amount_is_not_valid: 'உள்ளிடப்பட்ட தொகை சரியாக இல்லை',

  api_error_something_went_wrong:
    'ஏதோ தவறாகியுள்ளது, தயவுசெய்து பின்னர் முயற்சிக்கவும்',

  api_error_donation_payment_cancelled: 'நன்கொடை கட்டணம் ரத்து செய்யப்பட்டது',
  api_error_commitment_payment_cancelled:
    'உறுதிமொழி கட்டணம் ரத்து செய்யப்பட்டது',

  api_success_donation_payment_success: 'நன்கொடை கட்டணம் வெற்றிகரமாக முடிந்தது',
  api_success_commitment_payment_success:
    'உறுதிமொழி கட்டணம் வெற்றிகரமாக முடிந்தது',

  api_success_post_ad:
    'விளம்பரத்தை இடுகைக்கான உங்கள் கோரிக்கை வெற்றிகரமாக சமர்ப்பிக்கப்பட்டது.',
  api_success_profile_image_uploaded:
    'சுயவிவர புகைப்படம் வெற்றிகரமாக புதுப்பிக்கப்பட்டது',
  api_success_profile_details:
    'சுயவிவர விவரங்கள் வெற்றிகரமாக புதுப்பிக்கப்பட்டது',

  api_success_pan_added: 'உங்கள் பான் அட்டை வெற்றிகரமாக சேர்க்கப்பட்டது.',
  api_success_pan_updated: 'உங்கள் பான் அட்டை வெற்றிகரமாக புதுப்பிக்கப்பட்டது.',

  api_success_get_help: 'உங்கள் கோரிக்கை வெற்றிகரமாக அனுப்பப்பட்டது',
  api_success_sent_feedback: 'நன்றி! உங்கள் கருத்துக்காக.',

  api_success_added_commitment: 'உறுதிமொழி வெற்றிகரமாக சேர்க்கப்பட்டது',

  //After Provided 28-June
  no_data__notice_has_been_added: 'எந்த அறிவிப்பும் சேர்க்கப்படவில்லை.',
  due_amount: 'நிலுவை தொகை',
  btn_title_update_card: 'புதுப்பிக்கவும்',
  update_pan_card: 'பான் அட்டை புதுப்பிக்கவும்',

  delete_account_alert_msg: 'நீங்கள் நிச்சயமாக கணக்கை நீக்க விரும்புகிறீர்களா?',
  delete_title: 'நீக்கு',
  notification_remove_msg:
    'இங்கே காணப்படும் அறிவிப்புகள் பெறும் நேரத்திலிருந்து 24 மணி நேரத்திற்கு பிறகு நீக்கப்படும்.',
  get_help_title: 'உதவி பெறுங்கள்',

  description_title: 'விவரணம்',

  p_enter_title: 'உங்கள் தலைப்பை இங்கே உள்ளிடவும்',
  p_enter_description: 'உங்கள் விவரத்தை இங்கே தட்டச்சு செய்க',
  p_enter_your_name: 'உங்கள் பெயரை உள்ளிடவும்',
  p_enter_business_name: 'வணிகத்தின் பெயரை உள்ளிடவும்',
  p_enter_enter_business_type: 'வணிக வகையை உள்ளிடவும்',
  p_search_trust: 'நம்பிக்கை தேடுங்கள்',
  p_search_news: 'செய்திகளைத் தேடுங்கள்',
  p_search_location: 'இடத்தை தேடுங்கள்',
  p_search_state: 'மாநிலத்தைத் தேடுங்கள்',
  p_search_city: 'நகரத்தைத் தேடுங்கள்',
  p_search_audio: 'ஆடியோ தேடுங்கள்',
  //No Data Found
  no_results_found: 'முடிவுகள் எதுவும் இல்லை',
  there_no_trust: 'எந்த நம்பிக்கையும் இல்லை',
  there_no_news: 'எந்த செய்தியும் இல்லை',
  no_news_found: 'செய்திகள் எதுவும் இல்லை',
  no_location_found: 'இடம் எதுவும் இல்லை',
  no_video_available: 'வீடியோ எதுவும் இல்லை',
  no_video_found: 'வீடியோ எதுவும் இல்லை',

  no_audio_available: 'ஆடியோ எதுவும் இல்லை',

  btn_title_submit: 'சமர்ப்பி',

  give_us_feedback_title: 'எங்களுக்கு கருத்து தெரிவிக்கவும்',
  title_text: 'தலைப்பு',

  star_rating: 'உங்கள் நட்சத்திர மதிப்பீடு',

  customise_mandir: 'கோவிலை விருப்பமிடவும்',

  select_state_title: 'மாநிலத்தைத் தேர்ந்தெடுக்கவும்',
  select_city_title: 'நகரத்தைத் தேர்ந்தெடுக்கவும்',
  add_more: 'மேலும் சேர்க்கவும்',
  app_preference: 'செயலி விருப்பம்',
  no_language: 'எந்த மொழியும் கிடைக்கவில்லை',

  //
  location_permission_message:
    'உங்கள் அருகிலுள்ள கோவில்களை, நிகழ்வுகளை மற்றும் செய்திகளை ஆராய விரும்புகிறீர்களா?',
  location_permission_msg_2: 'உங்கள் இடத்தை எங்களுடன் பகிர்ந்து தொடங்குங்கள்',
  location_permission_button: 'இட சேவைகளை அனுமதிக்கவும்',
  maybe_later_button: 'பின்னர் கூடும்',
  btn_commitment_payment: 'உறுதிமொழி',
  no_notification_available: 'எந்த அறிவிப்புகளும் இல்லை',
  tag: 'குறிச்சொற்கள்',

  lanuage_changed_success: 'மொழி வெற்றிகரமாக புதுப்பிக்கப்பட்டது',
  language_already_selected: 'மொழி ஏற்கனவே தேர்வு செய்யப்பட்டது',
  account_delete_successfully: 'உங்கள் கணக்கு வெற்றிகரமாக நீக்கப்பட்டது',

  error_enter_mobile_number: 'தயவுசெய்து மொபைல் எண்ணை உள்ளிடவும்',
  get_directions: 'வழிமுறைகளைப் பெறுங்கள்',

  temple_info: 'கோவில் தகவல்',
  notices: 'அறிவிப்புகள்',
  about: 'பற்றி',
  news: 'செய்திகள்',
  events: 'நிகழ்வுகள்',
  gallery: 'காட்சியகம்',
  facilities: 'வசதிகள்',
  punyarjak: 'புண்யர்ஜாக்',
  top_Donor: 'சிறந்த நன்கொடையாளர்கள்',

  change_background: 'பின்னணி மாற்று',
  add_background: 'பின்னணி சேர்க்கவும்',

  change_god_position: 'தெய்வத்தின் நிலையை மாற்று',
  add_all_gods: 'அனைத்து தெய்வங்களையும் சேர்க்கவும்',

  title_address: 'முகவரி',
  title_location: 'இடம்',
  title_city: 'நகரம்',
  title_state: 'மாநிலம்',

  txt_donate: 'நன்கொடை செய்ய',

  choose_from_gallery_or_take_a_new_one:
    'கேலரி யில் இருந்து தேர்வுசெய்க அல்லது புதியதை எடுக்கவும்',
  camera: 'கேமரா',
  btn_gallery: 'கேலரி',
  msg_payment_receipt:
    'ஒவ்வொரு நன்கொடையுடன், எங்கள் இலக்கின் அருகே வருகிறோம். உங்கள் கருணை மற்றும் தாராளத்தால் மாற்றத்தை ஏற்படுத்தியதற்கு நன்றி',

  monthly: 'மாதாந்திர',
  weekly: 'வாராந்திர',
  yearly: 'ஆண்டு',

  no_jinvani_found: 'எந்த சாஸ்திரம்யும் இல்லை',

  search_video: 'வீடியோவைத் தேடுங்கள்',

  saved_payment_receipt: 'செலுத்திய ரசீது வெற்றிகரமாக சேமிக்கப்பட்டது',

  create_account: 'கணக்கை உருவாக்கு',
  by_signing_up: 'பதிவு செய்வதன் மூலம் நீங்கள் ஒப்புக்கொள்கிறீர்கள்',
  privacy_policy: 'தனியுரிமை கொள்கை',
  already_have_an_account: 'ஏற்கனவே கணக்கு உள்ளதா?',
  translate_jinvani: 'சாஸ்திரம் மொழிபெயர்ப்பு',

  //
  DONE: 'முடிந்தது',
  Recipt: 'ரசீது',

  DeleteAccount: 'கணக்கை நீக்கு',

  deleteAccountConfrimationMesage:
    'நீங்கள் நிச்சயமாக கணக்கை நீக்க விரும்புகிறீர்களா? அனைத்து தரவுகளும் எப்போதும் இழக்கப்படும் மற்றும் மீட்டெடுக்க முடியாது.',

  deleteAccountWarningMessage:
    'நீங்கள் நிச்சயமாக கணக்கை நீக்க விரும்புகிறீர்களா?\n\nநீங்கள் 14 நாட்களுக்குள் மீண்டும் உள்நுழைந்து உங்கள் கணக்கை மீண்டும் செயல்படுத்தலாம். அதன் பிறகு, உங்கள் கணக்கு விவரங்கள் அனைத்தும் எங்கள் பதிவுகளிலிருந்து நிரந்தரமாக நீக்கப்படும்.',

  WhatDoYouWantToDo: 'நீங்கள் என்ன செய்ய விரும்புகிறீர்கள்?',

  NoSearchEnteredYet: 'இன்னும் எந்தத் தேடலும் உள்ளிடப்படவில்லை',
  SorryWeCouldnFindAnyResults:
    'மன்னிக்கவும், எங்களால் எந்த முடிவுகளையும் கண்டுபிடிக்க முடியவில்லை',

  btnNo: 'இல்லை',
  btnYes: 'ஆம்',

  AppDisplayName: 'எங்கள் தர்மம்',

  EnterTitle: 'தலைப்பை உள்ளிடவும்',
  AddTempleBackground: 'கோவில் பின்னணியைச் சேர்க்கவும்',
  NoItemFound: 'எந்த உருப்படியும் இல்லை!',
  //
  MobileVerification: 'மொபைல் சரிபார்ப்பு',
  EnterTheDigitCodeThatWasSentToYourRegisteredMobileNumber: `உங்கள் பதிவு செய்யப்பட்ட மொபைல் எண்ணிற்கு அனுப்பப்பட்ட 6-எண் கோடியை உள்ளிடவும்.`,
  Verifying: 'சரிபார்க்கப்படுகிறது...',
  VerifyMobileNumber: 'மொபைல் எண்ணை சரிபார்க்கவும்',
  ResendCode: 'கோடு மீண்டும் அனுப்பு',

  No: 'இல்லை',
  Found: 'கண்டறியப்பட்டது',

  Published_on: 'வெளியிடப்பட்டது: ',
  receipt_url_not_available:
    'ரசீது கிடைக்கவில்லை, தயவுசெய்து பின்னர் முயற்சிக்கவும்',
  DonorName: 'நன்கொடையாளர் பெயர் :',
  SelectBackground: 'பின்னணியைத் தேர்ந்தெடுக்கவும்',

  Subcategory: 'துணை வகை',

  newUpdateAvailableText: 'புதிய புதுப்பிப்பு கிடைக்கிறது',
  newUpdateAvailableMessage:
    'மேம்பட்ட அனுபவத்திற்காக தயவுசெய்து எங்கள் பயன்பாட்டை புதுப்பிக்கவும்! இந்த பதிப்பு இனி ஆதரிக்கப்படாது.',
  updateNow: 'இப்போது புதுப்பிக்கவும்',
  notNow: 'இப்போது இல்லை',

  verificationEmailHasBeenSentToYourEmail:
    'உங்கள் பதிவு செய்யப்பட்ட மின்னஞ்சலுக்கு சரிபார்ப்பு மின்னஞ்சல் அனுப்பப்பட்டுள்ளது',
};
