// Getting the window size for screen width and height in React
const { innerWidth: width, innerHeight: height } = window;

// Device size
export const SIZE = {
  deviceWidth: window.innerWidth,  // Browser window width
  deviceHeight: window.innerHeight, // Browser window height
  SCREEN_WIDTH: width,
  SCREEN_HEIGHT: height,
  SAFE_AREA_TOP_HEIGHT: 0, // In web, we typically don't have 'safe area' concept
  SAFE_AREA_BOTTOM_HEIGHT: 0,
  TABBAR_HEIGHT: 70, // Set this as a fixed height for tabbars, you can adjust as needed
  FLOATING_MUSIC_HEIGHT: 55, // Similarly, adjust based on your needs
};

// Fonts (Use web-safe fonts or Google Fonts for React)
export const FONTS = {
  Metropolis: 'Metropolis, sans-serif', // Use web-safe fonts, or import web fonts
  NotoSans_Black: 'NotoSans-Black, sans-serif',
  NotoSans_BlackItalic: 'NotoSans-BlackItalic, sans-serif',
  NotoSans_Bold: 'NotoSans-Bold, sans-serif',
  NotoSans_BoldItalic: 'NotoSans-BoldItalic, sans-serif',
  NotoSans_ExtraBold: 'NotoSans-ExtraBold, sans-serif',
  NotoSans_ExtraBoldItalic: 'NotoSans-ExtraBoldItalic, sans-serif',
  NotoSans_ExtraLight: 'NotoSans-ExtraLight, sans-serif',
  NotoSans_Italic: 'NotoSans-Italic, sans-serif',
  NotoSans_Light: 'NotoSans-Light, sans-serif',
  NotoSans_LightItalic: 'NotoSans-LightItalic, sans-serif',
  NotoSans_Medium: 'NotoSans-Medium, sans-serif',
  NotoSans_MediumItalic: 'NotoSans-MediumItalic, sans-serif',
  NotoSans_Regular: 'NotoSans-Regular, sans-serif',
  NotoSans_SemiBold: 'NotoSans-SemiBold, sans-serif',
  NotoSans_SemiBoldItalic: 'NotoSans-SemiBoldItalic, sans-serif',
  NotoSans_Thin: 'NotoSans-Thin, sans-serif',
  NotoSans_ThinItalic: 'NotoSans-ThinItalic, sans-serif',
};

// Colors (these can be used directly in your CSS styles)
export const COLORS = {
  BGCOLOR: '#FFF',
  THEME_PRIMARY: '#FF8744',
  THEME_SECONDARY: '#FFF7E8',
  TextColor: '#583703',
  PlaceHolderColorWithOpcity: '#58370330',
  PlaceHolderColor: '#58370360',
  THEME_WHITE: '#FFFFFF',
  THEME_GRAY: '#70707070',
  SeparatorColor: '#EEEAE5',
  THEME_GOLDEN: '#AE7A00',
  Theme_RED_Light: '#FF0008',
  Theme_Green_Light: '#0CB500',
  Theme_Pichart_Yellow: '#FFB83B',
  Theme_Pichart_Orange: '#FF8744',
  Theme_Pichart_DarkOrange: '#F15A24',
  Theme_Pichart_Red: '#FF2B24',
  TextGray: '#9C9C9C',
};

// Keys (these can be stored in localStorage or sessionStorage in a browser environment)
export const KEYS = {
  FCM_TOKEN: 'FCM_TOKEN',
  ACCESS_TOKEN: 'ACCESS_TOKEN',
  JINVANI_LANGUAGE: 'JINVANI_LANGUAGE_',
  REFRESH_TOKEN: 'REFRESH_TOKEN',
  LOCATION_CORDINATE: 'CURRENT_LOCATION_CORDINATE',
  DEVICE_TOKEN: 'DEVICE_TOKEN',
  USER_PROFILE_INFO: 'USER_PROFILE_INFO',
  APP_PREFERENCE: "USER_APP_PREFERENCE",
  APP_LANGUAGE: "APP_LANGUAGE",
  ASK_APP_PREFERENCE_SELECT: "ASK_APP_PREFERENCE_SELECT",
  USER_CURRENT_LOCATION_CORD: "USER_CURRENT_LOCATION_CORD",
  UPDATE_MEDIA_STATE: 'UPDATE_MEDIA_STATE',
};

// App name
export const APP_NAME = 'Apna Mandir';

// Export the theme
const appTheme = { SIZE, FONTS, COLORS, APP_NAME, KEYS };
export default appTheme;
