import { callApi, callDharmshalaApi } from "../utils/apiUtils";
import { API_AUTH_URL } from "../utils/authApiInstance";
const trustId = localStorage.getItem("trustId");
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL_PUBLIC;
const API_BASE_URL_ADMIN = process.env.REACT_APP_API_BASE_URL;
const API_BASE_URL_APP = process.env.REACT_APP_API_BASE_URL_APP;
const getTrustId = async () => {
  let trustId = localStorage.getItem("trustId");
  let attempts = 0;
  const maxAttempts = 3;

  while (!trustId && attempts < maxAttempts) {
    await new Promise((resolve) => setTimeout(resolve, 500)); // Wait 500ms before retrying
    trustId = localStorage.getItem("trustId");
    attempts++;
  }

  return trustId;
};
export const loginPage = (payload) =>
  callApi({
    requestFunction: (axios) =>
      axios.get(`${API_AUTH_URL}public/sub-domain-details/${payload}`, payload),
    showToastOnSuccess: false,
    showToastOnError: false,
  });

export const getAllNews = async (payload) => {
  const trustId = await getTrustId();
  if (!trustId) return Promise.reject(new Error("Trust ID is missing."));

  return callApi({
    requestFunction: (axios) =>
      axios.post(`${API_BASE_URL}${trustId}/news`, payload),
    showToastOnSuccess: false,
    showToastOnError: false,
  });
};
export const getNewsTags = async (payload) => {
  const trustId = await getTrustId();
  if (!trustId) return Promise.reject(new Error("Trust ID is missing."));

  return callApi({
    requestFunction: (axios) =>
      axios.post(`${API_BASE_URL}${trustId}/news/tags`, {
        ...payload,
        englishLanguageId: "6332cbba8054b2cac94da3d1",
        languageId: "",
      }),
    showToastOnSuccess: false,
    showToastOnError: false,
  });
};
export const getNewsDetail = async (payload) => {
  const trustId = await getTrustId();
  if (!trustId) return Promise.reject(new Error("Trust ID is missing."));

  return callApi({
    requestFunction: (axios) =>
      axios.post(`${API_BASE_URL}${trustId}/news/get-news`, payload),
    showToastOnSuccess: false,
    showToastOnError: false,
  });
};
export const getEvents = async (payload) => {
  const trustId = await getTrustId();
  if (!trustId) return Promise.reject(new Error("Trust ID is missing."));

  return callApi({
    requestFunction: (axios) =>
      axios.post(`${API_BASE_URL}${trustId}/event`, payload),
    showToastOnSuccess: false,
    showToastOnError: false,
  });
};
export const getEventsbyId = async (eventId) => {
  const trustId = await getTrustId();
  if (!trustId) return Promise.reject(new Error("Trust ID is missing."));

  return callApi({
    requestFunction: (axios) =>
      axios.post(`${API_BASE_URL}${trustId}/event/get-event`, {
        eventId: eventId,
      }),
    showToastOnSuccess: false,
    showToastOnError: false,
  });
};
export const getNotices = async (payload) => {
  const trustId = await getTrustId();
  if (!trustId) return Promise.reject(new Error("Trust ID is missing."));

  return callApi({
    requestFunction: (axios) =>
      axios.post(`${API_BASE_URL}${trustId}/notice`, payload),
    showToastOnSuccess: false,
    showToastOnError: false,
  });
};
export const getNoticeDetail = async (payload) => {
  const trustId = await getTrustId();
  if (!trustId) return Promise.reject(new Error("Trust ID is missing."));

  return callApi({
    requestFunction: (axios) =>
      axios.post(`${API_BASE_URL}${trustId}/notice/get-notice`, payload),
    showToastOnSuccess: false,
    showToastOnError: false,
  });
};
export const getPunyarjak = async (payload) => {
  const trustId = await getTrustId();
  if (!trustId) return Promise.reject(new Error("Trust ID is missing."));

  return callApi({
    requestFunction: (axios) =>
      axios.post(`${API_BASE_URL}${trustId}/punyarjak`, payload),
    showToastOnSuccess: false,
    showToastOnError: false,
  });
};
export const getPunyarjakDetail = async (payload) => {
  const trustId = await getTrustId();
  if (!trustId) return Promise.reject(new Error("Trust ID is missing."));

  return callApi({
    requestFunction: (axios) =>
      axios.post(`${API_BASE_URL}${trustId}/punyarjak/get`, payload),
    showToastOnSuccess: false,
    showToastOnError: false,
  });
};
export const getAllSeva = async (payload) => {
  const trustId = await getTrustId();
  if (!trustId) return Promise.reject(new Error("Trust ID is missing."));

  return callApi({
    requestFunction: (axios) =>
      axios.post(`${API_BASE_URL}${trustId}/service`, payload),
    showToastOnSuccess: false,
    showToastOnError: false,
  });
};

export const getSevaById = async (id) => {
  const trustId = await getTrustId();
  if (!trustId) return Promise.reject(new Error("Trust ID is missing."));

  return callApi({
    requestFunction: (axios) =>
      axios.get(`${API_BASE_URL}${trustId}/service/${id}`),
    showToastOnSuccess: false,
    showToastOnError: false,
  });
};
export const createBooking = async (payload) => {
  const trustId = await getTrustId();
  if (!trustId) return Promise.reject(new Error("Trust ID is missing."));

  return callApi({
    requestFunction: (axios) =>
      axios.post(`${API_BASE_URL_ADMIN}${trustId}/booking/create`, payload),
    showToastOnSuccess: false,
    showToastOnError: false,
  });
};
export const updateBookingApi = async (payload) => {
  const trustId = await getTrustId();
  if (!trustId) return Promise.reject(new Error("Trust ID is missing."));
  return callApi({
    requestFunction: (axios) =>
      axios.post(`${API_BASE_URL_ADMIN}${trustId}/booking/complete`, payload),
    showToastOnSuccess: false,
    showToastOnError: false,
  });
};
export const callDeleteBooking = async (bookingId) => {
  const trustId = await getTrustId();
  if (!trustId) return Promise.reject(new Error("Trust ID is missing."));
  return callApi({
    requestFunction: (axios) =>
      axios.delete(`${API_BASE_URL_ADMIN}${trustId}/booking/${bookingId}`),
    showToastOnSuccess: false,
    showToastOnError: false,
  });
};

export const bookingList = async (payload) => {
  const trustId = await getTrustId();
  if (!trustId) return Promise.reject(new Error("Trust ID is missing."));

  return callApi({
    requestFunction: (axios) =>
      axios.post(`${API_BASE_URL_ADMIN}${trustId}/booking/list`, payload),
    showToastOnSuccess: false,
    showToastOnError: false,
  });
};
export const bookingDetail = async (bookingId) => {
  const trustId = await getTrustId();
  if (!trustId) return Promise.reject(new Error("Trust ID is missing."));

  return callApi({
    requestFunction: (axios) =>
      axios.get(`${API_BASE_URL_ADMIN}${trustId}/booking/${bookingId}`),
    showToastOnSuccess: false,
    showToastOnError: false,
  });
};
export const profileUpdate = async (payload) => {
  const trustId = await getTrustId();
  if (!trustId) return Promise.reject(new Error("Trust ID is missing."));

  return callApi({
    requestFunction: (axios) =>
      axios.put(`${API_BASE_URL_ADMIN}${trustId}/trust/update`, payload),
    showToastOnSuccess: false,
    showToastOnError: false,
  });
};
export const getPaymentMethods = async () => {
  const trustId = await getTrustId();
  if (!trustId) return Promise.reject(new Error("Trust ID is missing."));

  return callApi({
    requestFunction: (axios) =>
      axios.get(`${API_BASE_URL}${trustId}/paymentMethod/`),
    showToastOnSuccess: false,
    showToastOnError: false,
  });
};
export const getAllMasterCategories = async (payload) => {
  const trustId = await getTrustId();
  if (!trustId) return Promise.reject(new Error("Trust ID is missing."));

  return callApi({
    requestFunction: (axios) =>
      axios.post(
        `${API_BASE_URL_ADMIN}${trustId}/donation-category/list-master`,
        payload
      ),
    showToastOnSuccess: false,
    showToastOnError: false,
  });
};
export const getAllSubCategories = async (payload) => {
  const trustId = await getTrustId();
  if (!trustId) return Promise.reject(new Error("Trust ID is missing."));

  return callApi({
    requestFunction: (axios) =>
      axios.post(`${API_BASE_URL_ADMIN}${trustId}/donation-category`, payload),
    showToastOnSuccess: false,
    showToastOnError: false,
  });
};
export const createDonation = (payload) =>
  callApi({
    requestFunction: (axios) =>
      axios.post(`${API_BASE_URL_APP}donation/create`, payload),
    showToastOnSuccess: false,
    showToastOnError: false,
  });
export const getAllDonation = (payload) =>
  callApi({
    requestFunction: (axios) =>
      axios.post(`${API_BASE_URL_APP}donation`, payload),
    showToastOnSuccess: false,
    showToastOnError: false,
  });
export const getAllTrustDetail = (payload) =>
  callApi({
    requestFunction: (axios) =>
      axios.post(`${API_BASE_URL_APP}trust/`, payload),
    showToastOnSuccess: false,
    showToastOnError: false,
  });
export const getRoomTypeList = () =>
  callApi({
    requestFunction: (axios) =>
      axios.get(`${process.env.REACT_APP_DHARMSHALA_BASEURL}/roomTypes`),
    showToastOnSuccess: false,
    showToastOnError: false,
  });

export const getAvailableBuildingList = (payload) =>
  callApi({
    requestFunction: (axios) =>
      axios.post(
        `${process.env.REACT_APP_DHARMSHALA_BASEURL}/buildings/search`,
        payload
      ),
    showToastOnSuccess: false,
    showToastOnError: false,
  });
export const getDharmshalaFloorList = (payload) =>
  callApi({
    requestFunction: (axios) =>
      axios.get(
        `${process.env.REACT_APP_DHARMSHALA_BASEURL}/buildings/${payload}/floors`,
        payload
      ),
    showToastOnSuccess: false,
    showToastOnError: false,
  });
export const getAllRoomsByFloorId = (floorId, fromDate, toDate) =>
  callApi({
    requestFunction: (axios) =>
      axios.get(`${process.env.REACT_APP_DHARMSHALA_BASEURL}/rooms/`, {
        params: {
          floorId,
          fromDate,
          toDate,
        },
      }),
    showToastOnSuccess: false,
    showToastOnError: false,
  });
export const getAllBookings = (payload) =>
  callApi({
    requestFunction: (axios) =>
      axios.post(
        `${process.env.REACT_APP_DHARMSHALA_BASEURL}/bookings/getBookings`,
        payload
      ),
    showToastOnSuccess: false,
    showToastOnError: false,
  });
export const createDharmshalaBooking = (payload) =>
  callApi({
    requestFunction: (axios) =>
      axios.post(
        `${process.env.REACT_APP_DHARMSHALA_BASEURL}/bookings`,
        payload
      ),
    showToastOnSuccess: false,
    showToastOnError: false,
  });

export const updateBookingPayment = (payload, bookingId) =>
  callApi({
    requestFunction: (axios) =>
      axios.put(
        `${process.env.REACT_APP_DHARMSHALA_BASEURL}/bookings/${bookingId}`,
        payload
      ),
    showToastOnSuccess: false,
    showToastOnError: false,
  });
// export const getRoomTypeList = () =>
//   callDharmshalaApi({
//     requestFunction: (axios) => axios.get(`roomTypes/`),
//     showToastOnSuccess: false,
//     showToastOnError: false,
//   });

export const getDonation = (payload) =>
  callApi({
    requestFunction: (axios) =>
      axios.post(`${API_BASE_URL_APP}donation/get-donation`, payload),
    showToastOnSuccess: false,
  });
