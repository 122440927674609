import AppEnvironment from "../../Helper/WebServices/AppEnvironment";

class PaymentHelper {
  getJsonData(merchantDetails) {
    // Existing getJsonData method remains the same
    let jsonString = merchantDetails.paymentMode
      ? '{ "payInstrument": { "headDetails": { "version": "OTSv1.1", "api": "AUTH", "platform": "FLASH" }, "merchDetails": { "merchId": "' +
        merchantDetails.merchantId +
        '", "userId": "", "password": "' +
        merchantDetails.password +
        '", "merchTxnId": "' +
        merchantDetails.merchTxnId +
        '", "merchTxnDate": "' +
        merchantDetails.txnDate +
        '" }, "payModeSpecificData": { "subChannel" : "' +
        merchantDetails.paymentMode +
        '" }, "payDetails": { "amount": ' +
        merchantDetails.amount +
        ', "product": "' +
        merchantDetails.productId +
        '", "custAccNo": "213232323", "txnCurrency": "INR" }, "custDetails": { "custFirstName": "' +
        merchantDetails.custFirstName +
        '", "custEmail": "' +
        merchantDetails.custEmailId +
        '", "custMobile": "' +
        merchantDetails.custMobileNumber +
        '" }, "extras": { "udf1":"' +
        merchantDetails.udf1 +
        '", "udf2":"' +
        merchantDetails.udf2 +
        '", "udf3":"' +
        merchantDetails.udf3 +
        '", "udf4":"' +
        merchantDetails.udf4 +
        '", "udf5":"' +
        merchantDetails.udf5 +
        '" } }}'
      : '{ "payInstrument": { "headDetails": { "version": "OTSv1.1", "api": "AUTH", "platform": "FLASH" }, "merchDetails": { "merchId": "' +
        merchantDetails.merchantId +
        '", "userId": "", "password": "' +
        merchantDetails.password +
        '", "merchTxnId": "' +
        merchantDetails.merchTxnId +
        '", "merchTxnDate": "' +
        merchantDetails.txnDate +
        '" }, "payDetails": { "amount": ' +
        merchantDetails.amount +
        ', "product": "' +
        merchantDetails.productId +
        '", "custAccNo": "213232323", "txnCurrency": "INR" }, "custDetails": { "custFirstName": "' +
        merchantDetails.custFirstName +
        '", "custEmail": "' +
        merchantDetails.custEmailId +
        '", "custMobile": "' +
        merchantDetails.custMobileNumber +
        '" }, "extras": { "udf1":"' +
        merchantDetails.udf1 +
        '", "udf2":"' +
        merchantDetails.udf2 +
        '", "udf3":"' +
        merchantDetails.udf3 +
        '", "udf4":"' +
        merchantDetails.udf4 +
        '", "udf5":"' +
        merchantDetails.udf5 +
        '" } }}';
    return jsonString;
  }

  openAipayPopUp(atomTokenId, merchantDetails) {
    let setReturnUrl =
      AppEnvironment.Type.production === AppEnvironment.current
        ? "https://payment.atomtech.in/mobilesdk/param"
        : "https://pgtest.atomtech.in/mobilesdk/param";
    let cdnVal =
      AppEnvironment.Type.production === AppEnvironment.current
        ? "psa"
        : "pgtest";

    return `
      <div id="payment-container">
        <p style="text-align:center;margin-top:20%;">Please wait while we process your payment...</p>
        <script src="https://${cdnVal}.atomtech.in/staticdata/ots/js/atomcheckout.js?v=${Date.now()}" 
          onerror="handleScriptError()" 
          defer
        ></script>
        <script>
          // Initialize payment
          function openPay() {
            try {
              if (typeof AtomPaynetz !== "undefined") {
                const options = {
                  "atomTokenId": "${atomTokenId}",
                  "merchId": "${merchantDetails.merchantId}",
                  "custEmail": "${merchantDetails.custEmailId}",
                  "custMobile": "${merchantDetails.custMobileNumber}",
                  "returnUrl": "${setReturnUrl}",
                  "responseType": "samePage",
                  "userAgent": "web"
                };
                
                const atomPaynetzInstance = new AtomPaynetz(options, "${merchantDetails.mode || 'uat'}");
                
                // Listen for payment events from Atom with updated handling
                window.addEventListener('message', function({ data }) {
                  // Handle cancelTransaction case
                  if(data === "cancelTransaction") {
                    console.log("Payment has been cancelled by the user!");
                    window.parent.postMessage({
                      type: 'PAYMENT_RESPONSE',
                      data: {
                        success: false,
                        message: "Payment has been cancelled by the user!"
                      }
                    }, '*');
                  }
                  
                  // Handle sessionTimeout case
                  else if(data === "sessionTimeout") {
                    console.log("Session timedout");
                    window.parent.postMessage({
                      type: 'PAYMENT_RESPONSE',
                      data: {
                        success: false,
                        message: "Payment session timed out"
                      }
                    }, '*');
                  }
                  
                  // Handle ndpsResponse case
                  else if(data.ndpsResponse) {
                    console.log("ndpsResponse response found");
                    console.log(data.ndpsResponse.merchId);
                    console.log(data.ndpsResponse.encData);
                    
                    window.parent.postMessage({
                      type: 'PAYMENT_RESPONSE',
                      data: {
                        success: true,
                        message: "Transaction processed",
                        ndpsResponse: data.ndpsResponse
                      }
                    }, '*');
                  }
                  
                  // Forward all other messages to parent
                  else {
                    window.parent.postMessage({
                      type: 'PAYMENT_RESPONSE',
                      data: data
                    }, '*');
                  }
                });
              } else {
                window.parent.postMessage({
                  type: 'PAYMENT_RESPONSE',
                  data: {
                    success: false,
                    message: "Payment module failed to load"
                  }
                }, '*');
              }
            } catch (error) {
              window.parent.postMessage({
                type: 'PAYMENT_RESPONSE',
                data: {
                  success: false,
                  message: "Payment initialization failed: " + error.message
                }
              }, '*');
            }
          }

          // Start payment process when page loads
          if (document.readyState === 'loading') {
            document.addEventListener('DOMContentLoaded', openPay);
          } else {
            openPay();
          }
        </script>
      </div>
    `;
  }

  // Other methods remain the same
  getAtomTokenId(encStr, merchantDetails) {
    var data = "encData=" + encStr + "&merchId=" + merchantDetails.merchantId;
    var xhr = new XMLHttpRequest();
    xhr.withCredentials = true;
    return new Promise((resolve, reject) => {
      xhr.addEventListener("readystatechange", function () {
        if (this.readyState === 4) {
          var splitResponse = xhr.responseText.split("&");
          if (splitResponse[1].includes("encData")) {
            let splitEncData = splitResponse[1].split("=");
            resolve(splitEncData[1]); // sending encrypted string only
          }
        }
      });
      let authAPIUrl =
        AppEnvironment.Type.production === AppEnvironment.current
          ? "https://payment1.atomtech.in/ots/aipay/auth"
          : "https://caller.atomtech.in/ots/aipay/auth";
      xhr.open("POST", authAPIUrl);
      xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
      xhr.send(data);
    });
  }

  createSigStr(parsedResponse) {
    let merchId = parsedResponse.payInstrument.merchDetails.merchId.toString();
    let atomTxnId = parsedResponse.payInstrument.payDetails.atomTxnId.toString();
    let merchTxnId = parsedResponse.payInstrument.merchDetails.merchTxnId.toString();
    let totalAmount = parsedResponse.payInstrument.payDetails.totalAmount.toFixed(2).toString();
    let statusCode = parsedResponse.payInstrument.responseDetails.statusCode.toString();
    let subChannel = parsedResponse.payInstrument.payModeSpecificData.subChannel[0].toString();
    let bankTxnId = parsedResponse.payInstrument.payModeSpecificData.bankDetails.bankTxnId.toString();
    let signatureStr = merchId + atomTxnId + merchTxnId + totalAmount + statusCode + subChannel + bankTxnId;
    return signatureStr;
  }
}

export default PaymentHelper;