import React, { useMemo, useState } from "react";
import "../App.css";
import Wrapper from "../views/common/commonWrapper";
import { useLocation } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { getDetails } from "../apis/getDetails";
import { Col, Row, Spin, Card, Pagination, Image } from "antd";

function Gallery() {
  const subDomain = window.location.host.replace(
    process.env.REACT_APP_SUBDOMAIN_REPLACE_VALUE,
    ""
  );

  const location = useLocation();

  const { data, isLoading } = useQuery({
    queryKey: ["details"],
    queryFn: () => getDetails(subDomain),
  });

  const trustDetails = useMemo(() => data?.data?.result ?? null, [data]);

  // Pagination states
  const [currentPage, setCurrentPage] = useState(1);
  const imagesPerPage = 9; // Show 9 images per page

  const totalImages = trustDetails?.images?.length ?? 0;

  // Get images for the current page
  const paginatedImages = useMemo(() => {
    if (!trustDetails?.images) return [];
    const startIndex = (currentPage - 1) * imagesPerPage;
    const endIndex = startIndex + imagesPerPage;
    return trustDetails.images.slice(startIndex, endIndex);
  }, [trustDetails, currentPage]);

  // Handle page change
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  if (isLoading) {
    return (
      <Wrapper>
        <div className="spinner-container">
          <Spin size="large" />
        </div>
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      {trustDetails?.images?.length !== 0 ? (
        <div className="gallery-container">
          <div className="heading_div">Images</div>

          {/* Image Grid */}
          <Row gutter={[16, 16]}>
            {paginatedImages.map((item, idx) => (
              <Col xs={24} sm={12} md={8} lg={8} key={idx}>
                <div className="image-card">
                  <Image
                    src={item?.presignedUrl}
                    alt={`Image ${idx + 1}`}
                    preview
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                      borderRadius: "8px",
                    }}
                  />
                </div>
              </Col>
            ))}
          </Row>

          {/* Pagination as Card */}
          <Card className="pagination-card">
            <Pagination
              current={currentPage}
              pageSize={imagesPerPage}
              total={totalImages}
              onChange={handlePageChange}
              showSizeChanger={false} // Disable page size changer
            />
          </Card>
        </div>
      ) : (
        <div className="no-images">No images available</div>
      )}
    </Wrapper>
  );
}

export default Gallery;
