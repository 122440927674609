import apiCaller from './WebServices/apiCaller';
import i18n from '../../src/constants/AppLocalization/LocalizedStrings'; // Import the i18n configuration for translations

const AtomPayment = {
  createOrder: (params) => {
    console.log('Creating Atom order with params:', params);
    return new Promise((resolve, reject) => {
      // Check internet connection using navigator.onLine
      if (navigator.onLine) {
        // Internet is available
        apiCaller.atomCreateOrder(params, (statusCode, response) => {
          const isSuccess = statusCode === 200 || statusCode === 201;

          if (isSuccess) {
            resolve(response?.data?.data?.results); // Resolving promise with result
          } else {
            const errorMessage =
              response?.data?.message ?? i18n.t('api_error_something_went_wrong');
            reject(new Error(errorMessage)); // Rejecting promise with error message
          }
        });
      } else {
        // No internet connection
        const errorMessage = i18n.t('noInternetMessage');
        reject(new Error(errorMessage)); // Rejecting with no internet error
      }
    });
  },
};

export { AtomPayment };
