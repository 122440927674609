import React, { useEffect, useState } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import axios from "axios";
import {
  Button,
  FormGroup,
  Label,
  Row,
  Col,
  Spinner,
  CardBody,
} from "reactstrap";
import { Card, Select, DatePicker } from "antd";
import CustomCountryMobileField from "../views/common/customCountryMobileField";
import "../common.scss";
import "../App.css";
import { useSelector } from "react-redux";
import moment from "moment";
import momentGenerateConfig from "rc-picker/lib/generate/moment";
import calendarImg from "../assets/icons/calendar.svg";
import { profileUpdate } from "../utility/commonApi";
import Swal from "sweetalert2";
const CustomDatePickerComponent =
  DatePicker.generatePicker(momentGenerateConfig);

// Mocked states data
const states = [
  { value: "Andhra Pradesh", label: "Andhra Pradesh" },
  { value: "Arunachal Pradesh", label: "Arunachal Pradesh" },
  { value: "Assam", label: "Assam" },
  { value: "Bihar", label: "Bihar" },
  { value: "Chhattisgarh", label: "Chhattisgarh" },
  { value: "Goa", label: "Goa" },
  { value: "Gujarat", label: "Gujarat" },
  { value: "Haryana", label: "Haryana" },
  { value: "Himachal Pradesh", label: "Himachal Pradesh" },
  { value: "Jharkhand", label: "Jharkhand" },
  { value: "Karnataka", label: "Karnataka" },
  { value: "Kerala", label: "Kerala" },
  { value: "Madhya Pradesh", label: "Madhya Pradesh" },
  { value: "Maharashtra", label: "Maharashtra" },
  { value: "Manipur", label: "Manipur" },
  { value: "Meghalaya", label: "Meghalaya" },
  { value: "Mizoram", label: "Mizoram" },
  { value: "Nagaland", label: "Nagaland" },
  { value: "Odisha", label: "Odisha" },
  { value: "Punjab", label: "Punjab" },
  { value: "Rajasthan", label: "Rajasthan" },
  { value: "Sikkim", label: "Sikkim" },
  { value: "Tamil Nadu", label: "Tamil Nadu" },
  { value: "Telangana", label: "Telangana" },
  { value: "Uttar Pradesh", label: "Uttar Pradesh" },
  { value: "Uttarakhand", label: "Uttarakhand" },
  { value: "West Bengal", label: "West Bengal" },
];

// Validation schema using Yup
const validationSchema = Yup.object({
  name: Yup.string().required("Full Name is required"),
  email: Yup.string()
    .email("Invalid email format")
    .required("Email is required"),
  mobileNumber: Yup.mixed().required("Mobile number is required."),
  dob: Yup.date().required("Date of Birth is required"),
  address: Yup.string().required("Address is required"),
  state: Yup.mixed().required("State is required"),
  panNum: Yup.string()
    .length(10, "PAN card should be 10 characters long")
    .required("PAN Card is required"),
});

// Custom Mobile Number Field with Country Code
const UserProfile = () => {
  const userData = useSelector((state) => state.auth.user); // Getting data from Redux

  // Submit form handler
  const handleSubmit = async (values) => {
    try {
      // Build the payload based on the form fields
      const payload = {
        name: values.name, // Full Name
        email: values.email, // Email Address
        mobileNumber: values.mobileNumber.mobileNumber, // Mobile Number
        countryCode: values.mobileNumber.countryCode.replace("+", ""), // Country Code
        address: values.address, // Address
        state: values.state?.value || values.state, // State
        panNum: values.panNum, // PAN Card
        // dob: values.dob ? moment(values.dob).format("YYYY-MM-DD") : null, // Date of Birth (formatted)
        about: "",
        city: "", 
        location: "", 
        trustEmail: "",
        trustNumber: "",
        countryName: "",
        // typeId: null,
        languageId: null,
      };

      // API request to save updated user data
      const response = await profileUpdate(payload);

      // Show success notification using SweetAlert
      Swal.fire({
        icon: "success",
        title: "Profile Updated",
        text: "Your profile has been updated successfully!",
        confirmButtonText: "OK",
      });

      console.log("Profile updated successfully:", response.data);
    } catch (error) {
      console.error("Error updating profile:", error);

      // Show error notification using SweetAlert
      Swal.fire({
        icon: "error",
        title: "Update Failed",
        text: "An error occurred while updating your profile. Please try again later.",
        confirmButtonText: "OK",
      });
    }
  };
  // Display loading spinner until data is fetched
  //   if (loading) {
  //     return <Spinner color="primary" />;
  //   }

  return (
    <Card className="commonCardShadow mt-3">
      <Formik
        initialValues={{
          name: userData.name,
          email: userData.email,
          mobileNumber: {
            countryCode: userData.countryCode || "+91",
            mobileNumber: userData.mobileNumber || "",
          },
          dob: "",
          address: userData.address,
          state: userData.state,
          panNum: userData.panNum,
        }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ values, errors, touched, setFieldValue, setFieldTouched }) => (
          <Form>
            <Row form>
              <Col md={4}>
                <FormGroup>
                  <Label for="name">Full Name</Label>
                  <Field
                    type="text"
                    name="name"
                    className="form-control"
                    placeholder="Enter your full name"
                  />
                  <ErrorMessage
                    name="name"
                    component="div"
                    className="text-danger"
                  />
                </FormGroup>
              </Col>

              <Col md={4}>
                <FormGroup>
                  <Label for="email">Email</Label>
                  <Field
                    type="email"
                    name="email"
                    className="form-control"
                    placeholder="Enter your email"
                  />
                  <ErrorMessage
                    name="email"
                    component="div"
                    className="text-danger"
                  />
                </FormGroup>
              </Col>

              <Col md={4}>
                <FormGroup>
                  <Label for="mobileNumber">Mobile</Label>
                  <div className="userProfileField">
                    <CustomCountryMobileField
                      name="mobileNumber"
                      value={values.mobileNumber} // Pass Formik's value
                      placeholder="Enter your mobile number"
                      required
                      onChange={({ countryCode, mobileNumber }) => {
                        setFieldValue("mobileNumber", {
                          countryCode,
                          mobileNumber,
                        });
                      }}
                      onBlur={() => setFieldTouched("mobileNumber", true)}
                      error={errors.mobileNumber}
                      touched={touched.mobileNumber}
                    />
                  </div>
                  <ErrorMessage
                    name="mobileNumber"
                    component="div"
                    className="text-danger"
                  />
                </FormGroup>
              </Col>

              <Col md={4}>
                <FormGroup>
                  <Label for="dob">Date of Birth</Label>
                  <div className="userProfileField">
                    <CustomDatePickerComponent
                      value={values.dob}
                      format="DD MMM YYYY"
                      onChange={(date) => setFieldValue("dob", date)}
                      className="inputBox"
                      placeholder="Date of Birth"
                      name="dob"
                      prefix={
                        <img
                          src={calendarImg} // Use your custom image
                          alt="calendar-icon"
                          style={{
                            width: 20,
                            height: 20,
                            marginRight: 8,
                          }}
                        />
                      }
                      disabledDate={(current) =>
                        current &&
                        (current.isSame(moment(), "day") ||
                          current.isAfter(moment()))
                      }
                    />
                  </div>
                  <ErrorMessage
                    name="dob"
                    component="div"
                    className="text-danger"
                  />
                </FormGroup>
              </Col>

              <Col md={4}>
                <FormGroup className="d-flex flex-column">
                  <Label for="state">State</Label>
                  <Select
                    className="stateDrop"
                    name="state"
                    options={states}
                    value={values.state}
                    onChange={(option) => setFieldValue("state", option)}
                  />
                  <ErrorMessage
                    name="state"
                    component="div"
                    className="text-danger"
                  />
                </FormGroup>
              </Col>

              <Col md={4}>
                <FormGroup>
                  <Label for="panNum">PAN Card</Label>
                  <Field
                    type="text"
                    name="panNum"
                    className="form-control"
                    placeholder="Enter your PAN card number"
                  />
                  <ErrorMessage
                    name="panNum"
                    component="div"
                    className="text-danger"
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row form>
              <Col md={12}>
                <FormGroup>
                  <Label for="address">Address</Label>
                  <Field
                    type="text"
                    name="address"
                    className="form-control"
                    placeholder="Enter your address"
                  />
                  <ErrorMessage
                    name="address"
                    component="div"
                    className="text-danger"
                  />
                </FormGroup>
              </Col>
            </Row>

            <Button type="submit" color="primary">
              Update Profile
            </Button>
          </Form>
        )}
      </Formik>
    </Card>
  );
};

export default UserProfile;
