import React, { useEffect, useMemo, useState } from "react";
import { Card, Row, Col, Pagination, Empty, Skeleton } from "antd";
import Meta from "antd/es/card/Meta";
import { useQuery } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { getNotices } from "../utility/commonApi";
import he from "he";
import Loader from "../views/common/loader";
import "../common.scss";
import Wrapper from "../views/common/commonWrapper";
import moment from "moment";
import clockImg from "../assets/icons/clockIcon.svg";
import calendarImg from "../assets/icons/calendar.svg";
import { fetchImage } from "../utility/commonDownloadS3Image";
import placeholderImage from "../assets/icons/placeholderimage.webp";

function Notice() {
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 8;
  // const startDate = moment().startOf("month").utcOffset(0, true).toISOString();
  // const endDate = moment().endOf("month").utcOffset(0, true).toISOString();
  // Fetching notice details using React Query
  const { data, isLoading } = useQuery({
    queryKey: ["notices", currentPage],
    queryFn: () =>
      getNotices({
        page: currentPage,
        limit: pageSize,
        sortOrder: "DESC",
        isPublished: true,
      }),
    keepPreviousData: true,
  });

  // Memoizing the fetched data
  const noticeData = useMemo(() => data?.data?.results ?? [], [data]);
  const totalNotices = data?.data?.totalResults || 0;

  // Handle page change
  const onChangePage = (page) => {
    setCurrentPage(page);
  };

  // Function to decode HTML entities in body (if needed)
  const stripHtmlTags = (htmlString) => {
    const doc = new DOMParser().parseFromString(htmlString, "text/html");
    return doc.body.textContent || "";
  };
  const [imageUrls, setImageUrls] = useState([]);
  useEffect(() => {
    if (noticeData && noticeData.length > 0) {
      const loadImages = async () => {
        const urls = await Promise.all(
          noticeData.map(async (notice) => {
            const url = await fetchImage(notice.imageName);
            return url;
          })
        );
        setImageUrls(urls);
      };

      loadImages();
    }
  }, [noticeData]);
  return (
    <>
      <Wrapper>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          {/* Loader - Display while data is loading */}
          {isLoading ? (
            <Loader />
          ) : (
            <div style={{ flex: 1 }}>
              {/* If there are no notices, show "No records found" */}
              {noticeData.length === 0 ? (
                <div style={{ textAlign: "center", marginTop: "50px" }}>
                  <Empty description="No records found" />
                </div>
              ) : (
                <>
                  {/* Displaying notices in grid */}
                  <div style={{ paddingBottom: "80px", marginBottom: "50px" }}>
                    <Row gutter={[{ xs: 16, sm: 16, md: 24, lg: 32 }, 16]}>
                      {noticeData.map((notice, index) => {
                        const decodedBody = he.decode(notice.body);
                        const strippedBody = stripHtmlTags(decodedBody);

                        const truncatedBody =
                          strippedBody.length > 30
                            ? strippedBody.substring(0, 30) + "..."
                            : strippedBody;

                        return (
                          <Col
                            key={notice.id}
                            xs={24}
                            sm={12}
                            md={8}
                            lg={6}
                            className="cardColumn"
                          >
                            <Card
                              className="cardContainer"
                              hoverable
                              onClick={() => {
                                const imageSrc =
                                  imageUrls?.[index] || placeholderImage; // Ensure fallback to placeholder
                                navigate(`/detail/notice/${notice.id}`, {
                                  state: { imageSrc },
                                });
                              }}
                              cover={
                                <>
                                  {imageUrls?.[index] ? (
                                    <img
                                      className="cardImage"
                                      alt={notice.title}
                                      src={imageUrls[index] || placeholderImage} // Fallback applied here as well
                                    />
                                  ) : (
                                    <img
                                      className="cardImage"
                                      alt="placeholder"
                                      src={placeholderImage}
                                    /> // Ensure placeholder is shown
                                  )}
                                </>
                              }
                            >
                              <Meta
                                title={
                                  <div className="cardTitle">
                                    {notice.title}
                                  </div>
                                }
                                description={
                                  <div className="cardDescription">
                                    <div>{truncatedBody}</div>
                                    {strippedBody.length > 30 && (
                                      <span
                                        className="cardReadMore"
                                        onClick={() => {
                                          const imageSrc =
                                            imageUrls && imageUrls[index];

                                          navigate(
                                            `/detail/notice/${notice.id}`,
                                            {
                                              state: { imageSrc },
                                            }
                                          );
                                        }}
                                      >
                                        Read more
                                      </span>
                                    )}
                                  </div>
                                }
                              />
                              <div className="cardDate">
                                <img
                                  src={calendarImg}
                                  width={15}
                                  height={15}
                                  className="me-1"
                                />
                                <span>
                                  {`Publish Date: ${moment(
                                    notice.publishDate
                                  ).format("DD MMM YYYY")}`}
                                </span>
                              </div>
                            </Card>
                          </Col>
                        );
                      })}
                    </Row>
                  </div>

                  {/* Pagination */}
                  {!isLoading && totalNotices > 0 && (
                    <Card className="pagination-card">
                      <Pagination
                        current={currentPage}
                        pageSize={pageSize}
                        total={totalNotices}
                        onChange={onChangePage}
                        showSizeChanger={false}
                      />
                    </Card>
                  )}
                </>
              )}
            </div>
          )}
        </div>
      </Wrapper>
    </>
  );
}

export default Notice;
