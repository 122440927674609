import React from "react";
import { Container } from "reactstrap";
import Wrapper from "../common/commonWrapper";
import { useSelector } from "react-redux";

const RefundPolicy = () => {
  const userDetails = useSelector((state) => state.auth);
  const trustData = userDetails ? userDetails?.trustData : null;
  return (
    <Wrapper>
      <Container className="privacy_policy">
        <p>
          {trustData?.name ?? ""} and DHARM PRABHAVNA TECHNOLOGIES LLP believe
          in helping their customers as far as possible and have, therefore, a
          liberal cancellation policy. Under this policy:
        </p>
        <ul>
          <li>
            <b>Order Cancellations: </b>
            Order Cancellations: Cancellations will be considered only if the
            request is made immediately after placing the order. However, the
            cancellation request may not be entertained if the orders have been
            communicated to the vendors/merchants and they have initiated the
            process of shipping them.
          </li>
          <li>
            <b>Perishable Items: </b>We do not accept cancellation requests for
            perishable items like flowers, eatables, etc. However, a
            refund/replacement can be made if the customer establishes that the
            quality of the product delivered is not good.
          </li>
          <li>
            <b>Damaged or Defective Items: </b>
            In case of receipt of damaged or defective items, please report the
            same to our Customer Service team. The request will, however, be
            entertained once the merchant has checked and determined the same at
            their end. This should be reported within 30 days of receipt of the
            products.
          </li>
          <li>
            <b>Product Dissatisfaction: </b>
            In case you feel that the product received is not as shown on the
            site or as per your expectations, you must bring it to the notice of
            our customer service within 30 days of receiving the product. The
            Customer Service Team, after looking into your complaint, will take
            an appropriate decision.
          </li>
          <li>
            <b>Manufacturer Warranty: </b> In case of complaints regarding
            products that come with a warranty from manufacturers, please refer
            the issue to them.
          </li>
          <li>
            <b>Refund Processing: </b>
            In case of any refunds approved by {trustData?.name ?? ""} and DHARM
            PRABHAVNA TECHNOLOGIES LLP, it will take 9-15 days for the refund to
            be processed to the end customer.
          </li>
        </ul>

        <h3>DONATIONS AND REVERSAL OF DONATIONS</h3>
        <ul>
          <li>
            <b>Donations: </b>Donations made to {trustData?.name ?? ""} and
            associated temples or religious institutions are voluntary and
            non-refundable. Once a donation is made, it cannot be canceled or
            reversed.
          </li>
          <li>
            <b>Reversal of Donations: </b>Reversal of donations will only be
            considered in cases of fraudulent transactions or duplicate
            payments, subject to verification and approval by our team. Requests
            for reversal must be made within 7 days of the transaction.
          </li>
        </ul>

        <h3>SEVA AND OTHER TEMPLE SERVICES</h3>
        <ul>
          <li>
            <b>Booking of Seva and Temple Services: </b>Once a seva (religious
            service) or other temple-related service is booked, it cannot be
            canceled or rescheduled unless otherwise specified.
          </li>
          <li>
            <b>Refunds for Seva Services: </b>
            If a seva is not conducted due to unforeseen circumstances or the
            temple's inability to provide the service, a refund or rescheduling
            will be processed as per temple policies.
          </li>
          <li>
            <b>Temple Event Contributions: </b>
            Contributions made towards temple events and activities are
            considered voluntary and are non-refundable unless explicitly stated
            otherwise.
          </li>
        </ul>

        <h3>TRANSACTION FEES</h3>
        <ul>
          <li>
            <b>Non-Refundable Transaction Fees: </b>Transaction fee charges will
            not be refunded or reversed under any circumstances, including
            refund, reversal, chargeback, or any other reasons.
          </li>
          <li>
            <b>Cardholder Responsibility: </b>The transaction fees charged will
            be borne by the cardholder for any payment.
          </li>
        </ul>

        <p>
          For any concerns regarding cancellations, refunds, or disputes, please
          reach out to our customer service team using the contact information
          provided on the website.
        </p>
      </Container>
    </Wrapper>
  );
};

export default RefundPolicy;
