import { useState, useEffect } from 'react';
import { toast } from 'react-toastify'; // React Toastify
import 'react-toastify/dist/ReactToastify.css'; // Import styles for Toastify

// Function to show success toast
function showSuccessToast(title) {
  let topInset = 20 + 10; // Default top inset for devices without notch
  if (isNotchDevice()) {
    topInset = 44 + 10; // Adjust for notch devices
  }

  toast.success(title, {
    position: 'top-center',
    autoClose: 3000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    style: { marginTop: topInset },
  });
}

// Function to show error toast without auto-hide
function showErrorToastNoAutoHide(title) {
  let topInset = 20 + 10;
  if (isNotchDevice()) {
    topInset = 44 + 10;
  }

  toast.error(title, {
    position: 'top-center',
    autoClose: false,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    style: { marginTop: topInset },
  });
}

// Function to show custom popup using alert
function showCustomPopup(title, message, buttons) {
  // Simple browser alert
  window.alert(`${title}\n${message}`);
}

// Function to show error toast
function showErrorToast(title) {
  let topInset = 20 + 10;
  if (isNotchDevice()) {
    topInset = 44 + 10;
  }

  toast.error(title, {
    position: 'top-center',
    autoClose: 3000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    style: { marginTop: topInset },
  });
}

// Function to hide toast
function hideToast() {
  toast.dismiss();
}

// Function to check if device has a notch (using screen width & height)
function isNotchDevice() {
  const { innerWidth: width, innerHeight: height } = window;

  // Example screen heights and widths for notch devices (customize as needed)
  const notchDevices = [844, 812, 896, 926]; // iPhone sizes (use actual widths/heights)

  // Here, we can also check for `width` if needed, but checking only `height` is enough for most cases
  return notchDevices.includes(height);
}

// Export functions for usage
export {
  showSuccessToast,
  showErrorToast,
  showErrorToastNoAutoHide,
  hideToast,
  isNotchDevice,
  showCustomPopup,
};
