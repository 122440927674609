import axios from 'axios';
import { NetworkErrorMessages } from './NetworkErrorMessages';
import { ErrorType } from './ErrorType';
import AppEnvironment from './AppEnvironment';
import Endpoint from './Endpoint';

// Utility functions for local storage
const getLocalStorageItem = async (key) => localStorage.getItem(key);
const setLocalStorageItem = async (key, value) => localStorage.setItem(key, value);
const clearLocalStorage = async () => localStorage.clear();

let isRefreshingToken = false;

const callAPI = async (method, url, params, completion, fcm_token) => {
  const arrOfWhiteListArray = [
    Endpoint.Url.user_login,
    Endpoint.Url.user_register,
    Endpoint.Url.refreshToken,
  ];

  method = method || 'POST';

  let apiParams = {
    method: method,
    url: url,
    headers: await commonAPIHeaders(fcm_token),
  };

  if (method === 'POST') {
    apiParams.data = params;
  }

  console.log('API URL: ', url + ' (' + method + ')');
  console.log('API PARAMS: ', params);
  axios.defaults.timeout = 40000;

  axios(apiParams)
    .then((response) => {
      const statusCode = response?.status || 500;

      console.log('API STATUS CODE: ', statusCode);
      console.log('API RESPONSE: ', JSON.stringify(response?.data));
      console.log('------------------------------------------------------------------------------------>>>\n');

      completion(statusCode, response);
    })
    .catch((error) => {
      const errorResp = error?.response;
      const statusCode = errorResp?.status || 600;
      console.log('API ERROR: ', JSON.stringify(errorResp));
      console.log('API ERROR CODE :', statusCode);
      console.log('----------------------------------------------------------------------------------->>>\n', url);

      if (statusCode === 401 && !arrOfWhiteListArray.includes(url)) {
        console.log('###Session has been expired. Please Refresh the refresh token');
        // Handle refresh token logic here
      } else {
        if (error.code === ErrorType.no_network) {
          completion(600, ErrorType.no_network);
          alert(NetworkErrorMessages.no_internet);
          return;
        } else if (error.code === ErrorType.timeout) {
          alert(NetworkErrorMessages.timeout);
          completion(601, ErrorType.timeout);
          return;
        }
      }
      completion(statusCode, errorResp);
    });
};

const callDeviceRegisterWithFCMAPI = async (fcm_token, completion) => {
  const url = Endpoint.Url.device_register;
  const apiParams = {
    method: 'POST',
    url: url,
    headers: await commonAPIHeaders(fcm_token),
  };

  console.log('API URL: ', url + ' (POST)');
  axios.defaults.timeout = 40000;

  axios(apiParams)
    .then((response) => {
      const statusCode = response?.status || 500;

      console.log('-----------------------------Device Register----Success------------------------------------->>>\n');
      completion(statusCode, response);
    })
    .catch((error) => {
      const errorResp = error?.response;
      const statusCode = errorResp?.status || 600;
      console.log('API ERROR: ', JSON.stringify(errorResp));
      console.log('API ERROR CODE :', statusCode);
      console.log('-----------------------------------Device Register----ERROR--------------------------------->>>\n');

      if (error.code === ErrorType.no_network) {
        completion(600, ErrorType.no_network);
        return;
      } else if (error.code === ErrorType.timeout) {
        completion(601, ErrorType.timeout);
        return;
      }
      completion(statusCode, errorResp);
    });
};

async function getRefreshToken(callBack) {
  const oldRefreshToken = await getLocalStorageItem('refreshToken');
  const params = { refreshToken: oldRefreshToken };

  const apiParams = {
    method: 'POST',
    url: Endpoint.Url.refreshToken,
    headers: await commonAPIHeaders(),
    data: params,
  };

  axios.defaults.timeout = 40000;
  isRefreshingToken = true;

  axios(apiParams)
    .then((response) => {
      const statusCode = response?.status || 500;
      const responseData = response?.data?.data;
      isRefreshingToken = false;

      if (statusCode === 200 || statusCode === 201) {
        const accessToken = responseData?.access?.token;
        const refreshToken = responseData?.refresh?.token;

        console.log('###Refreshed Tokens');

        void setLocalStorageItem('ACCESS_TOKEN', accessToken);
        void setLocalStorageItem('REFRESH_TOKEN', refreshToken);
        callBack(statusCode, responseData);
      }
    })
    .catch((error) => {
      const errorResp = error?.response;
      const statusCode = errorResp?.status || 401;

      if (statusCode === 401) {
        console.log('###Session has been expired. Please log in');
        clearLocalStorage();
        callBack(statusCode, errorResp);
      }
    });
}

async function commonAPIHeaders(fcm_token) {
  const tempToken = fcm_token || (await getLocalStorageItem('accessToken')) || '';

  const headers = {
    'device-id': 'WEB', // Replace with a unique ID for the web app if needed
    'device-type': 'web',
    'app-version': '1.0.0', // Replace with the current version of your app
    'os-version': navigator.userAgent,
    'ip-address': '127.0.0.1', // Replace with a proper IP address fetching logic if needed
    'device-name': 'Web Browser',
    'app-environment': AppEnvironment.current.toLowerCase(),
    role: 'app_users',
    tm: +new Date(),
    Authorization: 'Bearer ' + (await getLocalStorageItem('accessToken')),
    'Content-Type': 'application/json',
    environment: AppEnvironment.current.toLowerCase(),
    'time-zone': new Date().getTimezoneOffset() / 60,
    'language-code': 'en',
  };

  if (tempToken?.length > 0) {
    headers['device-token'] = tempToken;
  }

  console.log('Headers = ', headers);
  return headers;
}

const RequestType = {
  post: 'POST',
  get: 'GET',
  delete: 'DELETE',
};

export default {
  callAPI,
  RequestType,
  commonAPIHeaders,
  callDeviceRegisterWithFCMAPI,
  getRefreshToken,
};
