import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  Button,
  Card,
  Col,
  Divider,
  Input,
  InputNumber,
  Row,
  Select,
  DatePicker,
} from "antd";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { Formik, Field, Form, FieldArray } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import momentGenerateConfig from "rc-picker/lib/generate/moment";
import backIcon from "../assets/icons/back.svg";
import axios from "axios";
import moment from "moment";
import { useQuery } from "@tanstack/react-query";
import {
  createDharmshalaBooking,
  getAllRoomsByFloorId,
  getAvailableBuildingList,
  getDharmshalaFloorList,
  getRoomTypeList,
} from "../utility/commonApi";
import deleteIcon from "../assets/icons/deleteIcon.svg";
import Swal from "sweetalert2";
import { toast } from "react-toastify";

const CustomDatePicker = DatePicker.generatePicker(momentGenerateConfig);
const { Option } = Select;

function AddAccommodation() {
  const navigate = useNavigate();
  const userDetails = useSelector((state) => state.auth.user);
  const [roomTypes, setRoomTypes] = useState([]); // State to store dynamic room types from API

  // Fetch room types from API
  const { data } = useQuery({
    queryKey: ["roomTypes"],
    queryFn: () => getRoomTypeList(),
    keepPreviousData: true,
  });
  useEffect(() => {
    if (data) {
      setRoomTypes(data.data.results); // Update state with fetched data
    }
  }, [data]);

  const handleRemoveRoom = async (values, index, remove) => {
    remove(index);
  };

  const initialValues = {
    fromDate: null,
    toDate: null,
    men: 0,
    women: 0,
    children: 0,
    rooms: [
      {
        roomTypeId: "",
        // roomTypeName: "",
        // building: "",
        // buildingName: "",
        // floor: "",
        // floorName: "",
        // roomId: "",
        amount: 0,
        // roomNumber: "",
      },
    ],
    guestName: userDetails?.name || "",
    mobileNumber: userDetails?.mobileNumber || "",
    email: userDetails?.email || "",
    idType: "",
    idNumber: userDetails?.idNumber || "",
  };

  const validationSchema = Yup.object({
    fromDate: Yup.date().required("From Date is required"),
    toDate: Yup.date()
      .min(Yup.ref("fromDate"), "To Date must be after From Date")
      .required("To Date is required"),
    rooms: Yup.array().of(
      Yup.object({
        roomTypeId: Yup.string().required("Room Type is required"),
        amount: Yup.number()
          .min(0, "Amount cannot be negative")
          .required("Amount is required"),
      })
    ),
    guestName: Yup.string().required("Guest Name is required"),
    mobileNumber: Yup.string().required("Mobile Number is required"),
    email: Yup.string()
      .email("Invalid Email Address")
      .required("Email is required"),
    idType: Yup.string().required("ID Type is required"),
    idNumber: Yup.string().required("ID Number is required"),
  });

  const handleSubmit = async (values, { resetForm }) => {
    const bookingPayload = {
      startDate: values.fromDate
        ? moment(values.fromDate).format("DD-MM-YYYY")
        : null,
      endDate: values.toDate
        ? moment(values.toDate).format("DD-MM-YYYY")
        : null,
      idType: values.idType,
      idNumber: values.idNumber,
      guestCount: {
        men: values.men || 0,
        women: values.women || 0,
        children: values.children || 0,
      },
      rooms: values.rooms.map((room) => ({
        roomTypeId: room.roomTypeId,
        // roomTypeName: room.roomTypeName,
        // building: room.building,
        // buildingName: room.buildingName,
        // floor: room.floor,
        // floorName: room.floorName,
        // roomId: room.roomId,
        // roomNumber: room.roomNumber,
        amount: room.amount,
      })),
      userDetails: {
        name: values.guestName,
        email: values.email,
        mobileNumber: values.mobileNumber,
        idType: values.idType,
        idNumber: values.idNumber,
      },
      status: "requested",
      Mobile: values.mobileNumber,
      bookingId: "",
      SelectedUser: userDetails,
    };
    try {
      const response = await createDharmshalaBooking(bookingPayload);
      if (response.status) {
        Swal.fire({
          title: response.message,
          text: `Your booking ID: ${response?.data?.bookingId}`,
          icon: "success",
          confirmButtonText: "Go to Bookings",
        }).then(() => {
          // Navigate to the booking table
          navigate("/seva?page=bookings");
        });
        resetForm();
      }
      // history.push("/booking/info");
    } catch (error) {
      if (error.response?.status === 409) {
        toast.error("You have already booked a room for the specified dates.");
      } else {
        toast.error("Error creating booking. Please try again.");
      }
    }
    // Add API call to submit the form data
  };

  const handleBack = () => {
    navigate(`/seva`);
  };

  return (
    <div>
      {/* <div className="back-button mb-0 mt-0">
        <img
          onClick={handleBack}
          className="back-button-link"
          src={backIcon}
          width={40}
          height={40}
          alt="Back"
        />
      </div> */}
      <Card className="commonCardShadow mb-5">
        <div className="card-header">
          <img
            onClick={handleBack}
            className="back-button-link"
            src={backIcon}
            width={40}
            height={40}
          />
        </div>
        <h3 className="booking-title text-center mb-3">Accommodation Form</h3>

        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ values, errors, touched, handleChange, setFieldValue }) => (
            <Form layout="vertical">
              <Row gutter={16}>
                <Col span={6}>
                  <label htmlFor="fromDate" className="label">
                    From Date<span className="text-danger">*</span>:
                  </label>
                  <CustomDatePicker
                    className="antdDatePickerComponent"
                    id="fromDate"
                    name="fromDate"
                    format="DD MMM YYYY"
                    placeholder="Select Date"
                    value={values.fromDate}
                    onChange={(value) => {
                      setFieldValue("fromDate", value);
                    }}
                    disabledDate={(current) =>
                      current && current < moment().startOf("day")
                    }
                  />
                  {touched.fromDate && errors.fromDate && (
                    <div className="error">
                      <span className="text-danger">{errors.fromDate}</span>
                    </div>
                  )}
                </Col>
                <Col span={6}>
                  <label htmlFor="toDate" className="label">
                    To Date<span className="text-danger">*</span>:
                  </label>
                  <CustomDatePicker
                    className="antdDatePickerComponent"
                    id="toDate"
                    name="toDate"
                    format="DD MMM YYYY"
                    placeholder="Select Date"
                    value={values.toDate}
                    onChange={(value) => {
                      setFieldValue("toDate", value);
                    }}
                    disabledDate={(current) =>
                      current &&
                      (current < moment().startOf("day") ||
                        (values.fromDate &&
                          current < moment(values.fromDate).startOf("day")))
                    }
                  />
                  {touched.toDate && errors.toDate && (
                    <div className="error">
                      <span className="text-danger">{errors.toDate}</span>
                    </div>
                  )}
                </Col>
                <Col span={8}>
                  <div className="d-flex justify-content-evenly">
                    <div className="d-flex flex-column">
                      <label htmlFor="men" className="label">
                        Men:
                      </label>
                      <InputNumber
                        className="antInput"
                        id="men"
                        name="men"
                        placeholder="Men"
                        min={0}
                        value={values.men}
                        onChange={(value) => setFieldValue("men", value)}
                      />
                    </div>

                    <div className="d-flex flex-column">
                      <label htmlFor="women" className="label">
                        Women:
                      </label>
                      <InputNumber
                        className="antInput"
                        id="women"
                        name="women"
                        placeholder="Women"
                        min={0}
                        value={values.women}
                        onChange={(value) => setFieldValue("women", value)}
                      />
                    </div>

                    <div className="d-flex flex-column">
                      <label htmlFor="children" className="label">
                        Children:
                      </label>
                      <InputNumber
                        className="antInput"
                        id="children"
                        name="children"
                        placeholder="Children"
                        min={0}
                        value={values.children}
                        onChange={(value) => setFieldValue("children", value)}
                      />
                    </div>
                  </div>
                </Col>
              </Row>

              <Divider />

              <FieldArray name="rooms">
                {({ push, remove }) => (
                  <div>
                    {values.rooms.map((room, index) => (
                      <Row
                        gutter={16}
                        key={index}
                        className="d-flex align-items-center"
                      >
                        {/* Room Type */}
                        <Col span={4}>
                          <div className="d-flex flex-column">
                            <label
                              htmlFor={`rooms[${index}].roomTypeId`}
                              className="label"
                            >
                              Room Type:
                            </label>
                            <Select
                              id={`rooms[${index}].roomTypeId`}
                              name={`rooms[${index}].roomTypeId`}
                              placeholder="Select Room Type"
                              value={room.roomTypeId}
                              onChange={(value) => {
                                const selectedRoomType = roomTypes.find(
                                  (type) => type._id === value
                                );
                                setFieldValue(
                                  `rooms[${index}].roomTypeId`,
                                  value
                                );
                                // setFieldValue(
                                //   `rooms[${index}].roomTypeName`,
                                //   selectedRoomType?.name
                                // );
                                setFieldValue(
                                  `rooms[${index}].amount`,
                                  selectedRoomType?.price || 0
                                );
                              }}
                              style={{ marginBottom: "5px" }}
                            >
                              {roomTypes.map((type) => (
                                <Option key={type._id} value={type._id}>
                                  {type.name}
                                </Option>
                              ))}
                            </Select>
                          </div>
                        </Col>
                        {/* Amount */}
                        <Col span={4}>
                          <div className="d-flex flex-column">
                            <label
                              htmlFor={`rooms[${index}].amount`}
                              className="label"
                            >
                              Amount:
                            </label>
                            <Input
                              className="antInput"
                              id={`rooms[${index}].amount`}
                              name={`rooms[${index}].amount`}
                              type="number"
                              value={room.amount}
                              onChange={(e) =>
                                setFieldValue(
                                  `rooms[${index}].amount`,
                                  e.target.value
                                )
                              }
                              readOnly
                            />
                          </div>
                        </Col>
                        {/* Remove Button */}
                        <Col
                          span={2}
                          className="d-flex justify-content-center align-items-center"
                        >
                          {values.rooms.length > 1 && (
                            <img
                              width={30}
                              height={30}
                              onClick={() =>
                                handleRemoveRoom(values, index, remove)
                              } // Call the new remove function
                              src={deleteIcon}
                              style={{ cursor: "pointer", marginTop: "16px" }}
                            />
                          )}
                        </Col>
                      </Row>
                    ))}

                    {/* Add More Room Button */}
                    <Button
                      type="dashed"
                      icon={<PlusOutlined />}
                      onClick={() =>
                        push({
                          roomTypeId: "",
                          amount: 0,
                        })
                      }
                      style={{ marginTop: 16 }}
                    >
                      <span className="addBtnRoom">Add More Room</span>
                    </Button>
                  </div>
                )}
              </FieldArray>

              <Divider />

              <Row gutter={16}>
                <Col span={8}>
                  <div className="d-flex flex-column">
                    <label htmlFor="guestName" className="label">
                      Guest Name:
                    </label>
                    <Input
                      className="antInput"
                      id="guestName"
                      name="guestName"
                      placeholder="Guest Name"
                      value={values.guestName}
                      onChange={handleChange}
                    />
                    {touched.guestName && errors.guestName && (
                      <div className="error">
                        <span className="text-danger">{errors.guestName}</span>
                      </div>
                    )}
                  </div>
                </Col>
                <Col span={8}>
                  <div className="d-flex flex-column">
                    <label htmlFor="mobileNumber" className="label">
                      Mobile Number:
                    </label>
                    <Input
                      className="antInput"
                      id="mobileNumber"
                      name="mobileNumber"
                      placeholder="Mobile Number"
                      value={values.mobileNumber}
                      onChange={handleChange}
                    />
                    {touched.mobileNumber && errors.mobileNumber && (
                      <div className="error">
                        <span className="text-danger">
                          {errors.mobileNumber}
                        </span>
                      </div>
                    )}
                  </div>
                </Col>
                <Col span={8}>
                  <div className="d-flex flex-column">
                    <label htmlFor="email" className="label">
                      Email Address:
                    </label>
                    <Input
                      className="antInput"
                      id="email"
                      name="email"
                      placeholder="Email Address"
                      value={values.email}
                      onChange={handleChange}
                    />
                    {touched.email && errors.email && (
                      <div className="error">
                        <span className="text-danger">{errors.email}</span>
                      </div>
                    )}
                  </div>
                </Col>
              </Row>

              <Row gutter={16} className="mt-2">
                <Col span={8}>
                  <div className="d-flex flex-column">
                    <label htmlFor="idType" className="label">
                      ID Type:
                    </label>
                    <Select
                      id="idType"
                      name="idType"
                      placeholder="Select ID Type"
                      value={values.idType}
                      onChange={(value) => setFieldValue("idType", value)}
                      style={{ marginBottom: "5px" }}
                    >
                      <Option value="aadhaar">Aadhaar</Option>
                      <Option value="passport">Passport</Option>
                      <Option value="voterid">Voter ID</Option>
                    </Select>
                    {touched.idType && errors.idType && (
                      <div className="error">
                        <span className="text-danger">{errors.idType}</span>
                      </div>
                    )}
                  </div>
                </Col>
                <Col span={8}>
                  <div className="d-flex flex-column">
                    <label htmlFor="idNumber" className="label">
                      ID Number:
                    </label>
                    <Input
                      className="antInput"
                      id="idNumber"
                      name="idNumber"
                      placeholder="ID Number"
                      value={values.idNumber}
                      onChange={handleChange}
                    />
                    {touched.idNumber && errors.idNumber && (
                      <div className="error">
                        <span className="text-danger">{errors.idNumber}</span>
                      </div>
                    )}
                  </div>
                </Col>
              </Row>

              <div className="d-flex justify-content-end">
                <Button
                  type="primary"
                  htmlType="submit"
                  className="submitButton"
                >
                  Submit Booking
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </Card>
    </div>
  );
}

export default AddAccommodation;
