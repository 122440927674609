export const english = {
  //Sign In
  sign_in_with_mobile: 'Sign in with Mobile Number',
  sign_in: 'Sign In',
  sign_up: 'Sign Up',
  dont_have_account: `Don't have an account?`,

  //Sign Up
  register_yourself: 'Register Yourself',
  full_name: 'Full Name',
  mobile_number: 'Mobile Number',
  date_of_birth: 'Date Of Birth',

  //Home
  sunset: 'Sunset',
  sunrise: 'Sunrise',

  thought_of_the_day: 'Thought of the day',

  donation: 'Donation',
  commitment: 'Commitment',
  jinvani: 'Shastra',
  apna_mandir: 'Apna Mandir',
  videos: 'Videos',
  audios: 'Audios',

  favorites: 'Favorites',
  advertisements: 'Advertisements',
  post_ads: 'Post Ads',

  explore_temples: 'Explore Temples',

  todays_exclusive: "Today's Exclusive",

  //Search Home
  header_trust: 'Trust',
  header_news: 'News',
  header_event: 'Event',
  header_todayexclusive: "Today's Exclusive",

  //Donation
  no_donation_found: 'No Donation Found',
  make_donation: 'Make Donation',
  all: 'All',

  //Quick Donation
  make_donate_title: 'Make Donation',

  //Quick Add trut to Favorites
  add_to_favorites_list_title: 'Add To Favorites List',
  added_fav_trust_success_msg:
    'This trust has been added to your favorite list successfully',
  removed_fav_trust_success_msg:
    'This trust has been removed from your favorite list successfully',

  //Mandir Details
  btn_donate_title: 'Donate Now',
  btn_commitments_title: 'Commitments',
  alert_message_payment_pause: "Currently can't donate to this",
  text_address: 'Address',
  text_contact_number: 'Contact Number',
  text_email: 'Email',

  //Report and Dispute
  report_and_dispute: 'Report and Dispute',
  payment_report: 'Payment Report',
  trust_name: 'Trust Name',

  //QuickInsights
  total_donation: 'Total Donation',
  total_commitment: 'Pending Commitment',

  //Insights
  title_insights: 'Insights',

  //PaidAmountReceipt
  total_amount: 'Total Amount',
  paid_amount: 'Paid Amount',
  mode_of_payment: 'Mode of Payment',
  donor_name: 'Donor Name',
  date_and_time: 'Date & Time',
  paid_status: 'Payment Status',
  category: 'Category',
  download_receipt: 'Download Receipt',

  //Global Event
  no_event_on_selected_date: 'There is no event on the selected date',
  no_data_no_event_found: 'No Event Found',
  there_no_event: 'There is no event.',

  //Event Details
  event_details: 'Details',
  event_about: 'About',
  event_location: 'Location',
  event_timing: 'Timing',

  //Success Payment Pop
  anumodnaTitle: 'Anumodna',
  successDonationMsg:
    'We are grateful for your donation, We wish that you continue taking punyalabh by giving donations.',
  donate_again: 'Donate Again',

  //Completed Commitment
  commitments_title: 'Commitments',
  there_is_no_commitment_yet: 'There is no commitment yet',

  //Jinvani
  chapters_title: 'Chapters',

  no_jinvani_chapters_found: 'No shastra chapters found',

  //Jinavni Chapter List
  title_jinvani_chapter: 'Shastra Chapter',
  total_chapters: 'Total Pages',
  total_chapter: 'Total Page',
  number_of_chapters: 'Number of Chapters',
  number_of_chapter: 'Number of Chapter',
  number_of_pages: 'Number of Pages',
  number_of_page: 'Number of Page',

  //Donation Payment
  donation_payment: 'Donation Payment',
  donation_amount: 'Enter Amount',
  btn_make_payment: 'Make Payment',

  //Profile
  personal_information: 'Personal Information',
  notifications: 'Notifications',
  quick_links: 'Quick Links',
  terms_conditions: 'Terms & Conditions',
  privacy_policy: 'Privacy Policy',
  contact_support: 'Contact Support',
  get_help: 'Get Help',
  give_us_feedback: 'Give Us Feedback',
  post_your_ads: 'Post Ads',
  logout: 'Logout',

  version: 'Version',

  //Profile Details
  name: 'Name',
  email: 'Email',
  phone: 'Phone',
  select_date_of_birth: 'Select Date of birth',
  address: 'Address',
  state: 'State',
  city: 'City',
  pan_card: 'PAN Card',
  addressline1: 'House/Apartment/Floor Number',

  //Post Ads
  business_name_title: 'Business Name',
  business_type_title: 'Business Type',
  select_business_type: 'Select Business Type',
  enter_business_type: 'Enter your Business Type',

  //Account Preferences
  account_preferences: 'Account Preferences',
  allPushNotification: 'All Push Notification',
  accountNotification: 'Account Notification',
  promotionalNotification: 'Promotional Notification',

  appPreference: 'App Preference',
  deleteAccount: 'Delete Account',

  language_preferences: 'Language Preferences',

  //Add Pan Card
  pancard_security_message:
    'Your personal data is secured and stored in an encrypted manner in our database.',
  pan_card_number: 'PAN Card Number',
  pan_holder_name: 'Card Holder Name',
  pan_card_photo: 'PAN Card Photo',
  add_pan_card: 'Add PAN Card',
  btn_title_add_card: 'Add Card',

  //Commitment payement
  commitments_title: 'Commitments',
  total_amount: 'Total Amount',
  paid_amount: 'Paid Amount',
  payable_amount: 'Payable Amount',
  full_payment: 'Full Payment',
  make_commitment: 'Make Commitment',
  partial_payment: 'Partial Payment',

  //Buttons Title
  apply: 'Apply',
  update_profile: 'Update Profile',

  view_all: 'View All',
  ok: 'OK',
  cancel: 'Cancel',
  continue: 'Continue',
  retry: 'Retry',
  skip: 'Skip',
  report: 'Report',

  //Placeholder
  enter_your_name: 'Enter your name',
  enter_your_address_line1: 'Enter House/Apartment/Floor Number',

  enter_your_email: 'Enter your email',
  enter_your_phone: 'Enter your phone',
  enter_your_dob: 'Enter your dob',
  select_address: 'Select Address',
  add_edit_pancard_details: 'Add/Edit PAN Card Details',
  enter_mobile_number: 'Enter Mobile Number',
  p_search_temple: 'Search Temple',

  p_home_search: 'Search trust, event, news..',
  p_commitment_search: 'Search Temple',
  p_jinvani_search: 'Search Shastra',
  p_jinvani_chapter_search: 'Search Chapter name',

  p_enter_pan_number: 'Enter PAN card number',
  p_enter_pan_holder_name: 'Enter name on PAN card',

  p_enter_donar_name: 'Enter Donor Name',

  p_select_category: 'Select Category',
  p_select_subcategory: 'Select Sub Category',

  p_enter_donation_amount: 'Enter amount',
  p_enter_payable_amount: 'Enter Payable Amount',

  p_enter_trust_name: 'Enter trust name',

  p_search_event: 'Search Upcoming Event',
  //No Data message
  no_commitment_found: 'No Commitment found',
  no_about_text_found: 'About section is yet to be added.',
  no_news_found: 'No news has been added.',
  no_event_found: 'No event has been added.',
  no_photos_found: 'No photos has been added.',
  no_facility_found: 'No facility has been added.',
  no_punyarjak_found: 'No Punyarjak has been added.',
  no_top_doner_found: 'The list is currently empty, with no donors listed.',
  no_appPreference: 'No app preference available',
  //Alert Messages
  error_enterFullName: 'Please enter full name.',
  error_enterMobileNumber: 'Please enter valid mobile number.',
  error_validEmail: 'Please enter valid email.',
  error_enterEmail: 'Please enter email.',
  error_enterDob: 'Please Select date of birth.',
  error_enterOtp: 'Please enter a valid otp',
  error_enterTrustDescription: 'Please enter description',
  error_enter_pan_holdername: 'Please enter card holder name',
  error_pan_image_not_found: 'Please upload pan card photo',
  error_enter_valid_pan_card_no: 'Please enter PAN card number',
  mesg_logout_confirmation: 'Are you sure you want to logout?',
  error_already_reported_trust: 'Already Reported to this trust',

  noInternetMessage:
    'Make sure that your wifi or cellular data is turned on and try again',

  error_select_category_first: 'Select Category first',
  error_please_select_category: 'Please select category',
  error_please_select_subcategory: 'Please select subcategory',
  error_please_enter_donation_amount: 'Please enter donation amount',

  error_entered_amount_is_not_valid: 'The Entered amount is not valid',

  api_error_something_went_wrong:
    'Something went wrong, please try again later',

  api_error_donation_payment_cancelled:
    'The payment of donation has been cancelled',
  api_error_commitment_payment_cancelled:
    'The payment of commitment has been cancelled',

  api_success_donation_payment_success:
    'The payment of donation has been successfully',
  api_success_commitment_payment_success:
    'The payment of commitment has been successfully',

  api_success_post_ad:
    'Your request to post an advertisement has been successfully submitted.',
  api_success_profile_image_uploaded:
    'Profile image has been updated successfully',
  api_success_profile_details: 'Profile details has been updated successfully',

  api_success_pan_added: 'Your PAN card has been added successfully.',
  api_success_pan_updated: 'Your PAN card has been updated successfully.',

  api_success_get_help: 'Your request has been sent successfully',
  api_success_sent_feedback: 'Thank you! for your feedback.',

  api_success_added_commitment: 'Commitment has been added successfully',

  //After Provided 28-June
  no_data__notice_has_been_added: 'No notice has been added.',
  due_amount: 'Due Amount',
  btn_title_update_card: 'Update',
  update_pan_card: 'Update PAN Card',

  delete_account_alert_msg: 'Are you sure you want to delete account',
  delete_title: 'Delete',
  notification_remove_msg:
    'The notifications visible here will be removed after 24 hours from the receive time.',
  get_help_title: 'Get Help',

  description_title: 'Description',

  p_enter_title: 'Enter your title here',
  p_enter_description: 'Type your description here',
  p_enter_your_name: 'Enter Your name',
  p_enter_business_name: 'Enter Business name',
  p_enter_enter_business_type: 'Enter business type',
  p_search_trust: 'Search Trust',
  p_search_news: 'Search News',
  p_search_location: 'Search Location',
  p_search_state: 'Search State',
  p_search_city: 'Search City',
  p_search_audio: 'Search Audio',
  //No Data Found
  no_results_found: 'No results found',
  there_no_trust: 'There is no trust',
  there_no_news: 'There is no news',
  no_news_found: 'No News found',
  no_location_found: 'No location found',
  no_video_available: 'No Video Found',
  no_video_found: 'No Video Found',

  no_audio_available: 'No Audio Found',

  btn_title_submit: 'Submit',

  give_us_feedback_title: 'Give Us Feedback',
  title_text: 'Title',

  star_rating: 'Your Star Rating',

  customise_mandir: 'Customise Mandir',

  select_state_title: 'Select State',
  select_city_title: 'Select City',
  add_more: 'Add More',
  app_preference: 'App Preference',
  no_language: 'There is no language available',

  //
  location_permission_message:
    'Would you like to explore temple, events and news nearby?',
  location_permission_msg_2: 'Start with sharing your location with us',
  location_permission_button: 'Allow Location Services',
  maybe_later_button: 'Maybe Later',
  btn_commitment_payment: 'Commitment',
  no_notification_available: 'There are no notification available',
  tag: 'Tags',

  lanuage_changed_success: 'Language has been updated successfully',
  language_already_selected: 'Language already selected',
  account_delete_successfully: 'Your account has been deleted successfully',

  error_enter_mobile_number: 'Please enter mobile number',
  get_directions: 'Get Directions',

  temple_info: 'Temple Info',
  notices: 'Notices',
  about: 'About',
  news: 'News',
  events: 'Events',
  gallery: 'Gallery',
  facilities: 'Facilities',
  punyarjak: 'Punyarjak',
  top_Donor: 'Top Donor',

  change_background: 'Change Background',
  add_background: 'Add Background',

  change_god_position: 'Change God Position',
  add_all_gods: 'Add All Gods',

  title_address: 'Address',
  title_location: 'Location',
  title_city: 'City',
  title_state: 'State',

  txt_donate: 'Donate',

  choose_from_gallery_or_take_a_new_one:
    'Choose from gallery or take a new one',
  camera: 'CAMERA',
  btn_gallery: 'GALLERY',
  msg_payment_receipt:
    'Anumodna: We appreciate your donation and generosity. We wish that you continue to earn such good deeds in the future.',

  monthly: 'Monthly',
  weekly: 'Weekly',
  yearly: 'Yearly',

  no_jinvani_found: 'No Shastra found',

  search_video: 'Search Video',

  saved_payment_receipt: 'Saved Payment receipt successfully',

  create_account: 'Create Account',
  by_signing_up: 'By signing up you agree to the',
  privacy_policy: 'Privacy policy',
  already_have_an_account: 'Already have an account?',
  translate_jinvani: 'Translate Shastra',

  //
  DONE: 'DONE',
  Recipt: 'Receipt',

  DeleteAccount: 'Delete Account',

  deleteAccountConfrimationMesage:
    'Are you sure you want to delete your account? All the data will be lost once you delete your account and will not recovered.',

  deleteAccountWarningMessage:
    'Are you sure you want to delete your account?\n\nYou can reactivate your account within 14 days by logging back in. After that, all your account details will be permanently deleted from our records.',

  WhatDoYouWantToDo: 'What Do You Want To Do ?',

  NoSearchEnteredYet: 'No search entered yet',
  SorryWeCouldnFindAnyResults: "Sorry, we couldn't find any results",

  btnNo: 'No',
  btnYes: 'Yes',

  AppDisplayName: 'Apna Dharm',

  EnterTitle: 'Enter Title',
  AddTempleBackground: 'Add Temple Background',
  NoItemFound: 'No Item Found!',
  //
  MobileVerification: 'Mobile Verification',
  EnterTheDigitCodeThatWasSentToYourRegisteredMobileNumber: `Enter the 6-digit code that was sent to your registered mobile number.`,
  Verifying: 'Verifying...',
  VerifyMobileNumber: 'Verify Mobile Number',
  ResendCode: 'Resend Code',

  No: 'No',
  Found: 'Found',

  Published_on: 'Published on: ',
  receipt_url_not_available: 'Receipt is not available please try again later',
  DonorName: 'Donor Name :',
  SelectBackground: 'Select Background',

  Subcategory: 'Subcategory',

  newUpdateAvailableText: 'New update is available',
  newUpdateAvailableMessage:
    'Please update our app for an improved experience! This version is no longer supported.',
  updateNow: 'Update Now',
  notNow: 'Not Now',

  verificationEmailHasBeenSentToYourEmail:
    'Verification email has been sent to your registered email',
};
