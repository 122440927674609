export const hindi = {
  //Sign In
  sign_in_with_mobile: 'मोबाइल नंबर से साइन इन करें',
  sign_in: 'साइन इन',
  sign_up: 'साइन अप करें',

  dont_have_account: `खाता नहीं है?`,

  //Sign Up
  register_yourself: 'पंजीयन करें',
  full_name: 'पूरा नाम',
  mobile_number: 'मोबाइल नंबर',
  date_of_birth: 'जन्म तिथि',

  //Home
  sunset: 'सूर्यास्त',
  sunrise: 'सूर्योदय',

  thought_of_the_day: 'आज का सुविचार',

  donation: 'दान',
  commitment: 'प्रतिबद्धता (बोली)',
  jinvani: 'शास्त्र',
  apna_mandir: 'अपना मंदिर',
  videos: 'वीडियो',
  audios: 'ऑडियो',

  favorites: 'पसंदीदा',
  advertisements: 'विज्ञापन',
  post_ads: 'विज्ञापन पोस्ट करें',

  explore_temples: 'मंदिर देखे',

  todays_exclusive: 'आज का विशेष',

  //Search Home
  header_trust: 'ट्रस्',
  header_news: 'समाचार',
  header_event: 'आयोजन',
  header_todayexclusive: 'आज का विशेष',

  //Donation
  no_donation_found: 'कोई दान नहीं किया',
  make_donation: 'दान करें',
  all: 'सभी',

  //Quick Donation
  make_donate_title: 'दान करें',

  //Quick Add trut to Favorites
  add_to_favorites_list_title: 'पसंदीदा सूची में जोड़ें',
  added_fav_trust_success_msg:
    'यह ट्रस्ट आपकी पसंदीदा सूची में जोड़ दिया गया है',
  removed_fav_trust_success_msg:
    'इस ट्रस्ट को आपकी पसंदीदा सूची से सफलतापूर्वक हटा दिया गया है',

  //Mandir Details
  btn_donate_title: 'दान करे', //दान करे
  btn_commitments_title: 'प्रतिबद्धता (बोली)',
  alert_message_payment_pause: 'अभी इसमें दान नहीं कर सकता',
  text_address: 'पता',
  text_contact_number: 'सम्पर्क नंबर',
  text_email: 'ईमेल',

  //Report and Dispute
  report_and_dispute: 'विवाद रिपोर्ट करें',
  payment_report: 'विवाद रिपोर्ट करें',
  trust_name: 'ट्रस्ट का नाम',

  //QuickInsights
  total_donation: 'कुल दान',
  total_commitment: 'कुल प्रतिबद्धता',

  //Insights
  title_insights: 'अंतर्दृष्टि',

  //PaidAmountReceipt
  total_amount: 'कुल राशि',
  paid_amount: 'भरी गई राशि',
  mode_of_payment: 'भुगतान का तरीका',

  donor_name: 'दाता का नाम',
  date_and_time: 'तिथि और समय',
  category: 'वर्ग',
  download_receipt: 'रसीद डाउनलोड करें',

  //Global Event
  no_event_on_selected_date: 'यहां चयनित तिथि पर कोई आयोजन नहीं है',
  no_data_no_event_found: 'कोई आयोजन नहीं मिला',
  there_no_event: 'कोई आयोजन नहीं है',

  //Event Details
  event_details: 'विवरण',
  event_about: 'बारे में',
  event_location: 'जगह',
  event_timing: 'समय',

  //Success Payment Pop
  anumodnaTitle: 'अनुमोदना',
  successDonationMsg:
    'हम आपके दान के लिए आभारी हैं, हम कामना करते हैं कि आप दान देकर पुण्यलाभ लेते रहें',
  donate_again: 'पुनः दान करें',

  //Completed Commitment
  commitments_title: 'प्रतिबद्धता (बोली)',
  there_is_no_commitment_yet: 'अभी तक कोई प्रतिबद्धता नहीं है',

  //Jinvani
  chapters_title: 'अध्याय',
  no_jinvani_chapters_found: 'क्षमा करें, कोई shastr अध्याय नहीं मिला',

  //Jinavni Chapter List
  title_jinvani_chapter: 'शास्त्र अध्याय',
  total_chapters: 'कुल अध्याय',
  total_chapter: 'कुल अध्याय',
  number_of_chapters: 'अध्यायों की संख्या',
  number_of_chapter: 'अध्यायों की संख्या',
  number_of_pages: 'पृष्ठ संख्या',
  number_of_page: 'पृष्ठ संख्या',

  //Donation Payment
  donation_payment: 'दान भुगतान',

  donor_name: 'दाता का नाम',
  donation_amount: 'राशि डालें',
  btn_make_payment: 'भुगतान करें',

  //Profile
  personal_information: 'व्यक्तिगत जानकारी',
  notifications: 'सूचनाएं',
  quick_links: 'त्वरित सम्पक',
  terms_conditions: 'नियम एवं शर्तें',
  privacy_policy: 'गोपनीयता नीति',
  contact_support: 'समर्थन से संपर्क करें',
  get_help: 'मदद लें',
  give_us_feedback: 'हमें प्रतिक्रिया दे',
  post_your_ads: 'विज्ञापन पोस्ट करें',
  logout: 'लॉग आउट',

  version: 'संस्करण',

  //Profile Details
  name: 'नाम',
  email: 'ईमेल',
  phone: 'फ़ोन',
  select_date_of_birth: 'जन्मतिथि चुनें',
  address: 'पता',
  state: 'राज्य',
  city: 'शहर',
  pan_card: 'पैन कार्ड',

  //Post Ads
  business_name_title: 'व्यापार का नाम',
  business_type_title: 'व्यापार के प्रकार',
  select_business_type: 'व्यापार प्रकार चुनें',
  enter_business_type: 'अपना व्यापार प्रकार दर्ज करें',

  //Account Preferences
  account_preferences: 'खाता प्राथमिकताएँ',
  allPushNotification: 'सभी अधिसूचना',
  accountNotification: 'खाता अधिसूचना',
  promotionalNotification: 'प्रचार अधिसूचना',

  appPreference: 'ऐप प्राथमिकता',
  deleteAccount: 'खाता हटा दे',

  language_preferences: 'भाषा प्राथमिकताएँ',

  //Add Pan Card
  pancard_security_message:
    'आपका व्यक्तिगत डेटा हमारे डेटाबेस में एन्क्रिप्टेड तरीके से सुरक्षित और संग्रहीत है ',
  pan_card_number: 'पैन नंबर',

  pan_holder_name: 'कार्डधारक का नाम',
  pan_card_photo: 'पैन कार्ड फोटो',
  add_pan_card: 'पैन कार्ड जोड़ें',
  btn_title_add_card: 'कार्ड जोड़ें',

  //Commitment payment
  commitments_title: 'प्रतिबद्धताओं',
  total_amount: 'कुल राशि',
  paid_amount: 'चुकाई गई राशि',
  payable_amount: 'भुगतान योग्य राशि',
  full_payment: 'पूर्ण भुगतान',
  make_commitment: 'प्रतिबद्धता बनायें',
  partial_payment: 'आंशिक भुगतान',

  //Buttons Title
  apply: 'आवेदन करना',
  update_profile: 'प्रोफ़ाइल अपडेट करें',

  view_all: 'सभी को देखें',
  ok: 'ठीक है',
  cancel: 'निरस्त करना',
  continue: 'जारी रखना',
  skip: 'छोड़े',
  report: 'प्रतिवेदन',

  //Placeholder
  enter_your_name: 'अपना नाम दर्ज करें',
  enter_your_email: 'अपना ईमेल दर्ज करें',
  enter_your_phone: 'अपना फ़ोन दर्ज करें',
  enter_your_dob: 'अपनी जन्मतिथि दर्ज करें',
  select_address: 'पता चुनें',
  add_edit_pancard_details: 'पैन कार्ड विवरण जोड़ें/संपादित करें',
  enter_mobile_number: 'मोबाइल नंबर दर्ज करें',
  p_search_temple: 'मंदिर खोजें',

  p_home_search: 'खोजें',
  p_commitment_search: 'मंदिर, खोजें',
  p_jinvani_search: 'शास्त्र को खोजें',
  p_jinvani_chapter_search: 'अध्याय का नाम खोजें',

  p_enter_pan_number: 'कार्ड नंबर दर्ज करें',
  p_enter_pan_holder_name: 'PAN धारक का नाम दर्ज करें',

  p_enter_donor_name: 'दान दाता का नाम', //दान दाता का नाम

  p_select_category: 'श्रेणी चुनना',
  p_select_subcategory: 'उपश्रेणी चुनें',

  p_enter_donation_amount: 'दान राशि दर्ज करें',
  p_enter_payable_amount: 'देय राशि दर्ज करें',

  p_enter_trust_name: 'ट्रस्ट का नाम दर्ज करें',

  p_search_event: 'आगामी आयोजन खोजें',
  //No Data message
  no_commitment_found: 'कोई प्रतिबद्धता नहीं मिली',
  no_about_text_found: 'अनुभाग के बारे में अभी जोड़ा जाना बाकी है',
  no_news_found: 'कोई समाचार नहीं जोड़ा गया है',
  no_event_found: 'कोई आयोजन नहीं जोड़ा गया है',
  no_photos_found: 'कोई फ़ोटो नहीं जोड़ा गया है',
  no_facility_found: 'कोई सुविधा नहीं जोड़ी गई है',
  no_punyarjak_found: 'कोई पुण्यर्जक नहीं जोड़ा गया है',
  no_top_donor_found: 'शीर्ष दाता फिलहाल उपलब्ध नहीं है',

  //Alert Messages
  error_enterFullName: 'कृपया पूरा नाम दर्ज करें',
  error_enterMobileNumber: 'कृपया 10 अंकों का मोबाइल नंबर दर्ज करें',
  error_validEmail: 'कृपया एक मान्य ईमेल दर्ज करें',
  error_enterEmail: 'कृपया ईमेल दर्ज करें',
  error_enterDob: 'कृपया जन्मतिथि चुनें',
  error_enterOtp: 'कृपया एक वैध ओटीपी दर्ज करें',
  error_enterTrustDescription: 'कृपया विवरण दर्ज करें',
  error_enter_pan_holdername: 'कृपया कार्डधारक का नाम दर्ज करें',
  error_pan_image_not_found: 'PAN कार्ड का फोटो नहीं मिला',
  error_enter_valid_pan_card_no: 'कृपया वैध PAN कार्ड नंबर दर्ज करें',
  mesg_logout_confirmation: 'क्या आप लॉग आउट करना चाहते हैं?',
  error_already_reported_trust: 'ट्रस्ट को रिपोर्ट किया गया है',

  noInternetMessage:
    'सुनिश्चित करें कि आपका वाईफाई या सेल्युलर डेटा चालू है और पुनः प्रयास करें',

  error_select_category_first: 'सबसे पहले कैटेगरी चुनें',
  error_please_select_category: 'कृपया एक कैटेगरी चयनित करें',
  error_please_enter_donation_amount: 'कृपया दान राशि दर्ज करें',

  error_entered_amount_is_not_valid: 'दर्ज की गई राशि मान्य नहीं है',

  api_error_something_went_wrong:
    'कुछ गलत हो गया है। कृपया बाद में दोबारा प्रयास करें',

  api_error_donation_payment_cancelled: 'दान का भुगतान रद्द कर दिया गया है',

  api_error_commitment_payment_cancelled: 'प्रतिबद्धता को रद्द कर दिया गया है',

  api_success_donation_payment_success: 'दान का भुगतान सफलतापूर्वक हो गया है',
  api_success_commitment_payment_success:
    'प्रतिबद्धता का भुगतान सफलतापूर्वक किया गया है',

  api_success_post_ad:
    'विज्ञापन पोस्ट करने का आपका अनुरोध सफलतापूर्वक सबमिट कर दिया गया है',
  api_success_profile_image_uploaded: 'प्रोफ़ाइल छवि अद्यतन कर दी गई है',
  api_success_profile_details: 'प्रोफ़ाइल विवरण अद्यतन कर दिया गया है',
  api_success_pan_added: 'आपका पैन कार्ड सफलतापूर्वक जोड़ दिया गया है',
  api_success_pan_updated: 'आपका पैन कार्ड सफलतापूर्वक अपडेट हो गया है',

  api_success_get_help: 'आपका अनुरोध भेज दिया गया है',
  api_success_sent_feedback: 'आपकी प्रतिक्रिया के लिए आपका धन्यवाद',

  //After Provided 28-June
  no_data__notice_has_been_added: 'कोई नोटिस नहीं जोड़ा गया है',
  due_amount: 'बकाया राशि',
  btn_title_update_card: 'अपडेट',
  update_pan_card: 'पैन कार्ड अपडेट करें',

  delete_account_alert_msg: 'क्या आप वाकई इस खाते को हटाना चाहते हैं?',
  delete_title: 'हटाए',
  notification_remove_msg: 'सूचनाएं प्रकाशित होने के 24 घंटे बाद हटा दी जाएंगी',

  get_help_title: 'मदद लें',

  description_title: 'विवरण',

  p_enter_title: 'यहां अपना शीर्षक दर्ज करें',
  p_enter_description: 'अपना विवरण यहां लिखें',
  p_enter_your_name: 'अपना नाम दर्ज करें',
  p_enter_business_name: 'व्यवसाय का नाम दर्ज करें',
  p_enter_enter_business_type: 'व्यावसाय क्षेत्र दर्ज करें',

  p_search_trust: 'ट्रस्ट खोजें',
  p_search_news: 'समाचार खोजें',
  p_search_location: 'स्थान खोजें',
  p_search_state: 'राज्य खोजें',
  p_search_city: 'शहर खोजें',
  p_search_audio: 'ऑडियो खोजें',
  //No Data Found // डाटा प्राप्त नहीं हुआ
  no_results_found: 'कोई परिणाम नहीं मिला',
  there_no_trust: 'ट्रस्ट नहीं मिला',
  there_no_news: 'कोई खबर नहीं है',
  no_news_found: 'कोई समाचार नहीं मिला',
  no_location_found: 'कोई स्थान नहीं मिला',
  no_video_available: 'षमा करें, अभी कोई वीडियो उपलब्ध नहीं है',
  no_video_found: 'क्षमा करें, कोई वीडियो नहीं मिला',

  no_audio_available: 'क्षमा करें, अभी कोई ऑडियो उपलब्ध नहीं है',

  btn_title_submit: 'जमा करें',

  give_us_feedback_title: 'हमें प्रतिक्रिया दे',
  title_text: 'शीर्षक',

  star_rating: 'आपकी स्टार रेटिंग',

  customise_mandir: 'मंदिर को अनुकूलित करें',

  select_state_title: 'राज्य खोजें',
  select_city_title: 'शहर चुनें',
  add_more: 'अधिक जोड़ें',
  app_preference: 'ऐप प्राथमिकताएँ',
  no_language: 'कोई भाषा उपलब्ध नहीं है',

  location_permission_message:
    'क्या आप आस-पास के मंदिर, घटनाओं और समाचारों के बारे में जानना चाहेंगे?',
  location_permission_msg_2: 'हमारे साथ अपना स्थान साझा करके शुरुआत करें',
  location_permission_button: 'स्थान सेवाओं की अनुमति दें',
  maybe_later_button: 'शायद बाद में',
  btn_commitment_payment: 'प्रतिबद्धता',
  no_notification_available: 'कोई अधिसूचना उपलब्ध नहीं है',
  tag: 'टैग',

  lanuage_changed_success: 'भाषा सफलतापूर्वक अद्यतन कर दी गई है',
  language_already_selected: 'भाषा पहले से ही चयनित है',
  account_delete_successfully: 'आपका खाता सफलतापूर्वक हटा दिया गया है',

  error_enter_mobile_number: 'कृपया मोबाइल नंबर दर्ज करें',
  get_directions: 'दिशा - निर्देश प्राप्त करें',

  temple_info: 'मंदिर की जानकारी',
  notices: 'नोटिस',
  about: 'के बारे में',
  news: 'समाचार',
  events: 'आयोजन',
  gallery: 'गेलरी',
  facilities: 'सुविधाएँ',
  punyarjak: 'पुण्यर्जक',
  top_Donor: 'शीर्ष दाता',

  change_background: 'पृष्ठिका बदलो',
  add_background: 'पृष्ठभूमि जोड़ें',

  change_god_position: 'भगवान की स्थिति बदलें',
  add_all_gods: 'भगवान को जोड़ें',

  title_address: 'पता',
  title_location: 'जगह',
  title_city: 'शहर',
  title_state: 'राज्य',

  txt_donate: 'दान करे',

  choose_from_gallery_or_take_a_new_one: 'गैलरी से चुनें या कैमरा से नई लें',
  camera: 'कैमरा',
  gallery: 'गेलरी',

  msg_payment_receipt:
    'हमें प्राप्त प्रत्येक दान के साथ, हम अपने लक्ष्य के और भी करीब हो जाते हैं। अपनी करुणा और उदारता से बदलाव लाने के लिए धन्यवाद',

  search_video: 'वीडियो खोजें',
  saved_payment_receipt: 'भुगतान रसीद सफलतापूर्वक सहेजी गई',

  create_account: 'खाता बनाएं',
  by_signing_up: 'साइन अप करके आप इससे सहमत होते हैं',
  privacy_policy: 'गोपनीयता नीति',
  already_have_an_account: 'क्या आपके पास पहले से एक खाता मौजूद है?',
  translate_jinvani: 'शास्त्र का अनुवाद करें',

  DONE: 'Done',
  Recipt: 'Receipt',

  DeleteAccount: 'खाता हटाये',
  deleteAccountConfrimationMesage:
    'क्या आप इस खाते को हटाने के लिए सुनिश्चित हैं? एक बार जब आप अपना खाता हटा देंगे तो सारा डेटा खो जाएगा और पुनर्प्राप्त नहीं किया जाएगा।',

  WhatDoYouWantToDo: 'आप क्या करना चाहते हैं ?',

  p_enter_donar_name: 'दाता का नाम दर्ज करें',
  no_top_doner_found: 'सूची फिलहाल खाली है, कोई दानदाता सूचीबद्ध नहीं है।',
  no_appPreference: 'कोई ऐप प्राथमिकता उपलब्ध नहीं है',
  api_success_added_commitment: 'प्रतिबद्धता सफलतापूर्वक जोड़ दी गई है',
  btn_gallery: 'गैलरी',
  monthly: 'महीने के',
  weekly: 'साप्ताहिक',
  yearly: 'सालाना',
  no_jinvani_found: 'कोई शास्त्र नहीं मिली',
  error_please_select_subcategory: 'कृपया उपश्रेणी चुनें',

  NoSearchEnteredYet: 'अभी तक कोई खोज दर्ज नहीं की गई',
  SorryWeCouldnFindAnyResults: 'क्षमा करें, हमें कोई परिणाम नहीं मिल सका',

  btnNo: 'नहीं',
  btnYes: 'हाँ',

  AppDisplayName: 'अपना धर्म',

  EnterTitle: 'शीर्षक दर्ज करें',
  AddTempleBackground: 'मंदिर पृष्ठभूमि जोड़ें',
  NoItemFound: 'कोई छवि नहीं मिली!',

  MobileVerification: 'मोबाइल सत्यापन',
  EnterTheDigitCodeThatWasSentToYourRegisteredMobileNumber: `आपके पंजीकृत मोबाइल पर भेजा गया 6 अंकों का कोड दर्ज करें संख्या।`,
  Verifying: 'सत्यापन...',
  VerifyMobileNumber: 'मोबाइल नंबर सत्यापित करें',
  ResendCode: 'पुन: कोड भेजे',

  No: 'कोई',
  Found: 'नहीं मिला',

  Published_on: 'प्रकाशित दिनाक: ',
  receipt_url_not_available: 'रसीद उपलब्ध नहीं है कृपया पुनः प्रयास करें',
  DonorName: 'दाता का नाम:',
  SelectBackground: 'पृष्ठभूमि का चयन करें',

  Subcategory: 'उपश्रेणी',

  newUpdateAvailableText: 'नया अपडेट उपलब्ध है',
  newUpdateAvailableMessage:
    'बेहतर अनुभव के लिए कृपया हमारे ऐप को अपडेट करें! यह संस्करण अब समर्थित नहीं है.',
  updateNow: 'अभी अद्यतन करें',
  notNow: 'अभी नहीं',

  verificationEmailHasBeenSentToYourEmail:
    'सत्यापन ईमेल आपके ईमेल पर भेज दिया गया है',
  deleteAccountWarningMessage:
    'क्या आप इस खाते को हटाने के लिए सुनिश्चित हैं?\n\n आप वापस लॉग इन करके 14 दिनों के भीतर अपने खाते को पुनः सक्रिय कर सकते हैं। उसके बाद, आपके सभी खाते विवरण हमारे रिकॉर्ड से स्थायी रूप से हटा दिए जाएंगे।',
};
