import React, { useEffect, useState } from 'react';
import { Card, Alert, Button, Spin } from 'antd';
import { useSearchParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Utils } from '../constants';

const PaymentResponsePage = () => {
  const [searchParams] = useSearchParams();
  const [paymentData, setPaymentData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const processPaymentResponse = async () => {
      try {
        const encData = searchParams.get('encData');
        const merchId = searchParams.get('merchId');
        
        if (!encData) {
          throw new Error('No encrypted data found in URL');
        }

        // Send the encrypted data to your backend for processing
        const response = await axios.post('http://192.168.1.34:3005/v1/app/paymentNew/payment-response', {
          encData,
          merchId
        });

        if (response.data) {
          setPaymentData(response.data);
        } else {
          throw new Error('Invalid response from server');
        }
      } catch (err) {
        console.error('Payment processing error:', err);
        setError(err.message || 'Failed to process payment response');
        Utils.showErrorToast("Error processing payment response");
      } finally {
        setLoading(false);
      }
    };

    processPaymentResponse();
  }, [searchParams]);

  const handleBackToSeva = () => {
    navigate('/seva');
  };

  if (loading) {
    return (
      <div className="min-h-screen p-6 flex items-center justify-center">
        <Spin size="large" />
      </div>
    );
  }

  if (error) {
    return (
      <div className="min-h-screen p-6 flex items-center justify-center">
        <Alert
          message="Payment Processing Error"
          description={error}
          type="error"
          showIcon
          className="w-full max-w-2xl"
          action={
            <Button type="primary" onClick={handleBackToSeva}>
              Back to Seva
            </Button>
          }
        />
      </div>
    );
  }

  return (
    <div className="min-h-screen p-6 flex items-center justify-center">
      <Card 
        className="w-full max-w-2xl" 
        title={`Payment ${paymentData?.status === 'success' ? 'Successful' : 'Failed'}`}
      >
        <Alert
          message={paymentData?.status === 'success' ? "Payment Successful" : "Payment Failed"}
          description={paymentData?.message || "Your payment has been processed"}
          type={paymentData?.status === 'success' ? "success" : "error"}
          showIcon
          className="mb-4"
        />
        
        <div className="bg-gray-100 p-4 rounded mb-4">
          <h4 className="mb-2 font-semibold">Transaction Details:</h4>
          <pre className="text-sm overflow-auto">
            {JSON.stringify(paymentData, null, 2)}
          </pre>
        </div>

        <div className="flex justify-end mt-4">
          <Button type="primary" onClick={handleBackToSeva}>
            Back to Seva
          </Button>
        </div>
      </Card>
    </div>
  );
};

export default PaymentResponsePage;