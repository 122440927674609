import React, { useEffect, useState } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import "../../common.scss";

const CustomCountryMobileField = ({
  label,
  placeholder,
  formik,
  required,
  value,
  defaultCountry = "in",
  onChange,
  onBlur,
  error, // Accept the error message
  touched,
  ...props
}) => {
  const [countryCode, setCountryCode] = useState(value.countryCode||"");
  const [mobileNumber, setMobileNumber] = useState(value.mobileNumber||"");
  
  const handlePhoneChange = (phone, country, e, formattedValue) => {
    // Extract country code and the mobile number (without country code)
    const newCountryCode = country.dialCode;
    const numberWithoutCountryCode = formattedValue.replace(
      `+${newCountryCode}`,
      ""
    );

    // Update state with the new country code and mobile number
    setCountryCode(newCountryCode);
    setMobileNumber(numberWithoutCountryCode);

    // Pass both country code and mobile number to the parent via onChange
    onChange({
      countryCode: newCountryCode,
      mobileNumber: numberWithoutCountryCode,
    });
  };

  return (
    <div className="phonewrapper w-100">
      {label && (
        <label>
          {`${label}`}
          <span className="text-danger">{required && "*"}</span>
        </label>
      )}
      <PhoneInput
        value={`+${countryCode}${mobileNumber}`}
        placeholder={placeholder}
        country={defaultCountry}
        enableSearch
        disableSearchIcon
        onChange={handlePhoneChange}
        onBlur={onBlur}
        {...props}
      />
      {touched && error && <div className="text-danger mt-1">{error}</div>}
    </div>
  );
};

export default CustomCountryMobileField;
