import React, { useMemo } from "react";
import "../App.css";
import Wrapper from "../views/common/commonWrapper";
import { useLocation } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import he from "he";
import { getDetails } from "../apis/getDetails";
function AboutUs() {
  const subDomain = window.location.host.replace(
    process.env.REACT_APP_SUBDOMAIN_REPLACE_VALUE,
    ""
  );
  const location = useLocation();

  const { data, isLoading } = useQuery({
    queryKey: ["details"],
    queryFn: () => getDetails(subDomain),
  });

  const trustDetails = useMemo(() => data?.data?.result ?? null, [data]);
  return (
    <>
      <Wrapper>
        <div className="div_size pb-0" id="about">
          <div className="bg_sub_title">
            <div
              dangerouslySetInnerHTML={{
                __html: he.decode(trustDetails?.about ?? ""),
              }}
            />
          </div>
        </div>
      </Wrapper>
    </>
  );
}

export default AboutUs;
