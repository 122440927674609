export const marathi = {
  //Sign In
  sign_in_with_mobile: 'मोबाइल नंबरने साइन इन करा',
  sign_in: 'साइन इन',
  sign_up: 'साइन अप',
  dont_have_account: `तुमचं खाते नाही का?`,

  //Sign Up
  register_yourself: 'स्वत:ची नोंदणी करा',
  full_name: 'पूर्ण नाव',
  mobile_number: 'मोबाइल नंबर',
  date_of_birth: 'जन्मतारीख',

  //Home
  sunset: 'सूर्यास्त',
  sunrise: 'सूर्योदय',

  thought_of_the_day: 'आजचा विचार',

  donation: 'दान',
  commitment: 'प्रतिज्ञा',
  jinvani: 'शास्त्र',
  apna_mandir: 'आपलं मंदिर',
  videos: 'व्हिडिओ',
  audios: 'ऑडिओ',

  favorites: 'आवडीचे',
  advertisements: 'जाहिराती',
  post_ads: 'जाहिरात पोस्ट करा',

  explore_temples: 'मंदिरे अन्वेषण करा',

  todays_exclusive: 'आजचे विशेष',

  //Search Home
  header_trust: 'ट्रस्ट',
  header_news: 'बातम्या',
  header_event: 'कार्यक्रम',
  header_todayexclusive: 'आजचे विशेष',

  //Donation
  no_donation_found: 'कोणतेही दान आढळले नाही',
  make_donation: 'दान करा',
  all: 'सर्व',

  //Quick Donation
  make_donate_title: 'दान करा',

  //Quick Add trut to Favorites
  add_to_favorites_list_title: 'आवडत्या यादीत जोडा',
  added_fav_trust_success_msg:
    'हा ट्रस्ट तुमच्या आवडत्या यादीत यशस्वीरित्या जोडला गेला आहे',
  removed_fav_trust_success_msg:
    'हा ट्रस्ट तुमच्या आवडत्या यादीतून यशस्वीरित्या काढला गेला आहे',

  //Mandir Details
  btn_donate_title: 'आता दान करा',
  btn_commitments_title: 'प्रतिज्ञा',
  alert_message_payment_pause: 'सध्या यासाठी दान करता येत नाही',
  text_address: 'पत्ता',
  text_contact_number: 'संपर्क क्रमांक',
  text_email: 'ईमेल',

  //Report and Dispute
  report_and_dispute: 'अहवाल आणि वाद',
  payment_report: 'पेमेंट रिपोर्ट',
  trust_name: 'ट्रस्टचे नाव',

  //QuickInsights
  total_donation: 'एकूण दान',
  total_commitment: 'एकूण प्रतिज्ञा',

  //Insights
  title_insights: 'अंतर्दृष्टी',

  //PaidAmountReceipt
  total_amount: 'एकूण रक्कम',
  paid_amount: 'दिलेली रक्कम',
  mode_of_payment: 'पेमेंट मोड',
  donor_name: 'दाता नाव',
  date_and_time: 'तारीख आणि वेळ',
  category: 'श्रेणी',
  download_receipt: 'पावती डाउनलोड करा',

  //Global Event
  no_event_on_selected_date: 'निवडलेल्या तारखेला कोणताही कार्यक्रम नाही',
  no_data_no_event_found: 'कोणताही कार्यक्रम आढळला नाही',
  there_no_event: 'कोणताही कार्यक्रम नाही.',

  //Event Details
  event_details: 'तपशील',
  event_about: 'बद्दल',
  event_location: 'स्थान',
  event_timing: 'वेळ',

  //Success Payment Pop
  anumodnaTitle: 'अनुमोदना',
  successDonationMsg:
    'तुमच्या दानाबद्दल आम्ही कृतज्ञ आहोत, आम्ही इच्छितो की तुम्ही दान देऊन पण्यलाभ मिळवत राहाल.',
  donate_again: 'पुन्हा दान करा',

  //Completed Commitment
  commitments_title: 'प्रतिज्ञा',
  there_is_no_commitment_yet: 'अजून कोणतीही प्रतिज्ञा नाही',

  //Jinvani
  chapters_title: 'अध्याय',

  no_jinvani_chapters_found: 'कोणतेही शास्त्र अध्याय आढळले नाहीत',

  //Jinavni Chapter List
  title_jinvani_chapter: 'शास्त्र अध्याय',
  total_chapters: 'एकूण पृष्ठे',
  total_chapter: 'एकूण पृष्ठ',
  number_of_chapters: 'अध्यायांची संख्या',
  number_of_chapter: 'अध्यायांची संख्या',
  number_of_pages: 'पृष्ठांची संख्या',
  number_of_page: 'पृष्ठांची संख्या',

  //Donation Payment
  donation_payment: 'दान पेमेंट',
  donor_name: 'दाता नाव',
  donation_amount: 'रक्कम प्रविष्ट करा',

  btn_make_payment: 'पेमेंट करा',

  //Profile
  personal_information: 'वैयक्तिक माहिती',
  notifications: 'सूचना',
  quick_links: 'त्वरित लिंक',
  terms_conditions: 'नियम आणि अटी',
  privacy_policy: 'गोपनीयता धोरण',
  contact_support: 'समर्थन संपर्क साधा',
  get_help: 'मदत घ्या',
  give_us_feedback: 'आम्हाला अभिप्राय द्या',
  post_your_ads: 'तुमच्या जाहिराती पोस्ट करा',
  logout: 'बाहेर पडा',

  version: 'आवृत्ती',

  //Profile Details
  name: 'नाव',
  email: 'ईमेल',
  phone: 'फोन',
  select_date_of_birth: 'जन्मतारीख निवडा',
  address: 'पत्ता',
  state: 'राज्य',
  city: 'शहर',
  pan_card: 'PAN कार्ड',

  //Post Ads
  business_name_title: 'व्यवसायाचे नाव',
  business_type_title: 'व्यवसायाचा प्रकार',
  select_business_type: 'व्यवसायाचा प्रकार निवडा',
  enter_business_type: 'तुमचा व्यवसायाचा प्रकार प्रविष्ट करा',

  //Account Preferences
  account_preferences: 'खाते प्राधान्ये',
  allPushNotification: 'सर्व पुश नोटिफिकेशन्स',
  accountNotification: 'खाते सूचना',
  promotionalNotification: 'प्रोमोशनल सूचना',

  appPreference: 'अ‍ॅप प्राधान्य',
  deleteAccount: 'खाते हटवा',

  language_preferences: 'भाषा प्राधान्ये',

  //Add Pan Card
  pancard_security_message:
    'तुमचा वैयक्तिक डेटा आमच्या डेटाबेसमध्ये सुरक्षितपणे आणि एन्क्रिप्ट केलेल्या पद्धतीने संग्रहित केला जातो.',
  pan_card_number: 'PAN कार्ड क्रमांक',
  pan_holder_name: 'कार्ड धारकाचे नाव',
  pan_card_photo: 'PAN कार्ड फोटो',
  add_pan_card: 'PAN कार्ड जोडा',
  btn_title_add_card: 'कार्ड जोडा',

  //Commitment payement
  commitments_title: 'प्रतिज्ञा',
  total_amount: 'एकूण रक्कम',
  paid_amount: 'दिलेली रक्कम',
  payable_amount: 'देय रक्कम',
  full_payment: 'पूर्ण पेमेंट',
  make_commitment: 'प्रतिज्ञा करा',
  partial_payment: 'आंशिक पेमेंट',

  //Buttons Title
  apply: 'अर्ज करा',
  update_profile: 'प्रोफाइल अपडेट करा',

  view_all: 'सर्व पहा',
  ok: 'ठीक आहे',
  cancel: 'रद्द करा',
  continue: 'सुरू ठेवा',
  skip: 'वगळा',
  report: 'अहवाल',

  //Placeholder
  enter_your_name: 'तुमचं नाव प्रविष्ट करा',
  enter_your_email: 'तुमचा ईमेल प्रविष्ट करा',
  enter_your_phone: 'तुमचा फोन नंबर प्रविष्ट करा',
  enter_your_dob: 'तुमची जन्मतारीख प्रविष्ट करा',
  select_address: 'पत्ता निवडा',
  add_edit_pancard_details: 'PAN कार्ड तपशील जोडा/संपादित करा',
  enter_mobile_number: 'मोबाइल नंबर प्रविष्ट करा',
  p_search_temple: 'मंदिर शोधा',

  p_home_search: 'ट्रस्ट, इव्हेंट, बातम्या शोधा..',
  p_commitment_search: 'मंदिर शोधा',
  p_jinvani_search: 'शास्त्र शोधा',
  p_jinvani_chapter_search: 'अध्यायाचे नाव शोधा',

  p_enter_pan_number: 'PAN कार्ड क्रमांक प्रविष्ट करा',
  p_enter_pan_holder_name: 'PAN कार्डवरील नाव प्रविष्ट करा',

  p_enter_donar_name: 'दाता नाव प्रविष्ट करा',

  p_select_category: 'श्रेणी निवडा',
  p_select_subcategory: 'उपश्रेणी निवडा',

  p_enter_donation_amount: 'रक्कम प्रविष्ट करा',
  p_enter_payable_amount: 'देय रक्कम प्रविष्ट करा',

  p_enter_trust_name: 'ट्रस्टचे नाव प्रविष्ट करा',

  p_search_event: 'आगामी कार्यक्रम शोधा',
  //No Data message
  no_commitment_found: 'कोणतीही प्रतिज्ञा सापडली नाही',
  no_about_text_found: 'अजूनपर्यंत माहिती जोडलेली नाही.',
  no_news_found: 'कोणतीही बातमी नाही.',
  no_event_found: 'कोणताही कार्यक्रम जोडलेला नाही.',
  no_photos_found: 'कोणतीही छायाचित्रे जोडलेली नाहीत.',
  no_facility_found: 'कोणतीही सुविधा जोडलेली नाही.',
  no_punyarjak_found: 'कोणताही पुण्यर्जक जोडलेला नाही.',
  no_top_doner_found: 'सध्या यादी रिकामी आहे, कोणतेही दान करणारे नाहीत.',
  no_appPreference: 'कोणत्याही अ‍ॅप प्राधान्ये नाहीत',
  //Alert Messages
  error_enterFullName: 'कृपया पूर्ण नाव प्रविष्ट करा.',
  error_enterMobileNumber: 'कृपया वैध मोबाइल नंबर प्रविष्ट करा.',
  error_validEmail: 'कृपया वैध ईमेल प्रविष्ट करा.',
  error_enterEmail: 'कृपया ईमेल प्रविष्ट करा.',
  error_enterDob: 'कृपया जन्मतारीख निवडा.',
  error_enterOtp: 'कृपया वैध OTP प्रविष्ट करा',
  error_enterTrustDescription: 'कृपया वर्णन प्रविष्ट करा',
  error_enter_pan_holdername: 'कृपया कार्ड धारकाचे नाव प्रविष्ट करा',
  error_pan_image_not_found: 'कृपया PAN कार्ड फोटो अपलोड करा',
  error_enter_valid_pan_card_no: 'कृपया वैध PAN कार्ड क्रमांक प्रविष्ट करा',
  mesg_logout_confirmation: 'तुम्ही खात्रीने लॉगआउट करू इच्छिता का?',
  error_already_reported_trust: 'या ट्रस्टला आधीच अहवाल देण्यात आला आहे',

  noInternetMessage:
    'तुमच्या वायफाय किंवा मोबाइल डेटाचे कनेक्शन चालू असल्याचे सुनिश्चित करा आणि पुन्हा प्रयत्न करा',

  error_select_category_first: 'प्रथम श्रेणी निवडा',
  error_please_select_category: 'कृपया श्रेणी निवडा',
  error_please_select_subcategory: 'कृपया उपश्रेणी निवडा',
  error_please_enter_donation_amount: 'कृपया दानाची रक्कम प्रविष्ट करा',

  error_entered_amount_is_not_valid: 'प्रविष्ट केलेली रक्कम वैध नाही',

  api_error_something_went_wrong:
    'काहीतरी चूक झाली, कृपया नंतर पुन्हा प्रयत्न करा',

  api_error_donation_payment_cancelled: 'दान पेमेंट रद्द करण्यात आले आहे',
  api_error_commitment_payment_cancelled:
    'प्रतिज्ञा पेमेंट रद्द करण्यात आले आहे',

  api_success_donation_payment_success: 'दान पेमेंट यशस्वी झाले आहे',
  api_success_commitment_payment_success: 'प्रतिज्ञा पेमेंट यशस्वी झाले आहे',

  api_success_post_ad:
    'तुमच्या जाहिरात पोस्ट करण्याच्या विनंतीला यशस्वीरित्या सबमिट करण्यात आले आहे.',
  api_success_profile_image_uploaded:
    'प्रोफाइल प्रतिमा यशस्वीरित्या अद्ययावत करण्यात आली आहे',
  api_success_profile_details:
    'प्रोफाइल तपशील यशस्वीरित्या अद्ययावत करण्यात आले आहे',

  api_success_pan_added: 'तुमचा PAN कार्ड यशस्वीरित्या जोडण्यात आले आहे.',
  api_success_pan_updated:
    'तुमचा PAN कार्ड यशस्वीरित्या अद्ययावत करण्यात आला आहे.',

  api_success_get_help: 'तुमची विनंती यशस्वीरित्या पाठविण्यात आली आहे',
  api_success_sent_feedback: 'धन्यवाद! तुमच्या अभिप्रायासाठी.',

  api_success_added_commitment: 'प्रतिज्ञा यशस्वीरित्या जोडण्यात आली आहे',

  //After Provided 28-June
  no_data__notice_has_been_added: 'कोणताही नोटीस जोडलेला नाही.',
  due_amount: 'थकबाकी रक्कम',
  btn_title_update_card: 'अपडेट करा',
  update_pan_card: 'PAN कार्ड अपडेट करा',

  delete_account_alert_msg: 'तुम्ही खात्रीने खाते हटवू इच्छिता का?',
  delete_title: 'हटवा',
  notification_remove_msg:
    'इथे दिसणारे सूचनांचा संदेश मिळाल्यानंतर 24 तासांनी काढून टाकला जाईल.',
  get_help_title: 'मदत घ्या',

  description_title: 'वर्णन',

  p_enter_title: 'तुमचं शीर्षक इथे प्रविष्ट करा',
  p_enter_description: 'तुमचं वर्णन इथे टाइप करा',
  p_enter_your_name: 'तुमचं नाव प्रविष्ट करा',
  p_enter_business_name: 'व्यवसायाचं नाव प्रविष्ट करा',
  p_enter_enter_business_type: 'व्यवसायाचा प्रकार प्रविष्ट करा',
  p_search_trust: 'ट्रस्ट शोधा',
  p_search_news: 'बातम्या शोधा',
  p_search_location: 'स्थान शोधा',
  p_search_state: 'राज्य शोधा',
  p_search_city: 'शहर शोधा',
  p_search_audio: 'ऑडिओ शोधा',
  //No Data Found
  no_results_found: 'कोणतेही निकाल आढळले नाहीत',
  there_no_trust: 'कोणताही ट्रस्ट नाही',
  there_no_news: 'कोणतीही बातमी नाही',
  no_news_found: 'कोणतीही बातमी सापडली नाही',
  no_location_found: 'कोणतेही स्थान सापडले नाही',
  no_video_available: 'कोणताही व्हिडिओ सापडला नाही',
  no_video_found: 'कोणताही व्हिडिओ सापडला नाही',

  no_audio_available: 'कोणताही ऑडिओ सापडला नाही',

  btn_title_submit: 'सबमिट करा',

  give_us_feedback_title: 'आम्हाला अभिप्राय द्या',
  title_text: 'शीर्षक',

  star_rating: 'तुमचं स्टार रेटिंग',

  customise_mandir: 'मंदिर सानुकूलित करा',

  select_state_title: 'राज्य निवडा',
  select_city_title: 'शहर निवडा',
  add_more: 'अधिक जोडा',
  app_preference: 'अ‍ॅप प्राधान्य',
  no_language: 'कोणतीही भाषा उपलब्ध नाही',

  //
  location_permission_message:
    'तुम्ही मंदिर, इव्हेंट्स आणि बातम्या जवळपास शोधू इच्छिता का?',
  location_permission_msg_2: 'आमच्यासह तुमचं स्थान सामायिक करून प्रारंभ करा',
  location_permission_button: 'स्थान सेवा अनुमति द्या',
  maybe_later_button: 'कदाचित नंतर',
  btn_commitment_payment: 'प्रतिज्ञा',
  no_notification_available: 'कोणत्याही सूचना उपलब्ध नाहीत',
  tag: 'टॅग',

  lanuage_changed_success: 'भाषा यशस्वीरित्या अद्ययावत करण्यात आली आहे',
  language_already_selected: 'भाषा आधीच निवडलेली आहे',
  account_delete_successfully: 'तुमचं खाते यशस्वीरित्या हटविण्यात आले आहे',

  error_enter_mobile_number: 'कृपया मोबाइल नंबर प्रविष्ट करा',
  get_directions: 'दिशानिर्देश मिळवा',

  temple_info: 'मंदिर माहिती',
  notices: 'सूचना',
  about: 'बद्दल',
  news: 'बातम्या',
  events: 'कार्यक्रम',
  gallery: 'गॅलरी',
  facilities: 'सुविधा',
  punyarjak: 'पुण्यर्जक',
  top_Donor: 'शीर्ष दानदाता',

  change_background: 'पार्श्वभूमी बदला',
  add_background: 'पार्श्वभूमी जोडा',

  change_god_position: 'देवाची स्थिती बदला',
  add_all_gods: 'सर्व देव जोडा',

  title_address: 'पत्ता',
  title_location: 'स्थान',
  title_city: 'शहर',
  title_state: 'राज्य',

  txt_donate: 'दान करा',

  choose_from_gallery_or_take_a_new_one: 'गॅलरी मधून निवडा किंवा नवीन घ्या',
  camera: 'कॅमेरा',
  btn_gallery: 'गॅलरी',
  msg_payment_receipt:
    'प्रत्येक दानामुळे आम्ही आमच्या उद्दिष्टाच्या अधिक जवळ येतो. तुमच्या सहानुभूती आणि उदारतेबद्दल धन्यवाद',

  monthly: 'मासिक',
  weekly: 'साप्ताहिक',
  yearly: 'वार्षिक',

  no_jinvani_found: 'कोणतीही शास्त्र सापडली नाही',

  search_video: 'व्हिडिओ शोधा',

  saved_payment_receipt: 'पेमेंट रिसीट यशस्वीरित्या जतन केले आहे',

  create_account: 'खाते तयार करा',
  by_signing_up: 'साइन अप करून तुम्ही सहमत आहात',
  privacy_policy: 'गोपनीयता धोरण',
  alread_have_an_account: 'आधीच खाते आहे का?',
  translate_jinvani: 'शास्त्र भाषांतर',

  //
  DONE: 'पूर्ण',
  Recipt: 'पावती',

  DeleteAccount: 'खाते हटवा',

  deleteAccountConfrimationMesage:
    'तुम्ही खात्रीने खाते हटवू इच्छिता का? खाते हटवल्यानंतर सर्व डेटा काढला जाईल आणि पुनर्प्राप्त केला जाणार नाही.',

  deleteAccountWarningMessage:
    'तुम्ही खात्रीने खाते हटवू इच्छिता का?\n\nतुम्ही 14 दिवसांच्या आत पुन्हा लॉगिन करून तुमचं खाते पुन्हा सक्रिय करू शकता. त्यानंतर, तुमचं सर्व खाते तपशील आमच्या नोंदीमधून कायमचे हटवले जातील.',

  WhatDoYouWantToDo: 'तुम्हाला काय करायचं आहे?',

  NoSearchEnteredYet: 'अजूनपर्यंत कोणताही शोध प्रविष्ट केलेला नाही',
  SorryWeCouldnFindAnyResults: 'माफ करा, आम्हाला कोणतेही निकाल मिळाले नाहीत',

  btnNo: 'नाही',
  btnYes: 'होय',

  AppDisplayName: 'आमचा धर्म',

  EnterTitle: 'शीर्षक प्रविष्ट करा',
  AddTempleBackground: 'मंदिर पार्श्वभूमी जोडा',
  NoItemFound: 'कोणताही आयटम सापडला नाही!',
  //
  MobileVerification: 'मोबाइल पडताळणी',
  EnterTheDigitCodeThatWasSentToYourRegisteredMobileNumber: `तुमच्या नोंदणीकृत मोबाइल क्रमांकावर पाठविलेला 6-अंकी कोड प्रविष्ट करा.`,
  Verifying: 'पडताळणी करत आहे...',
  VerifyMobileNumber: 'मोबाइल नंबर पडताळा',
  ResendCode: 'कोड पुन्हा पाठवा',

  No: 'नाही',
  Found: 'सापडले',

  Published_on: 'प्रकाशित: ',
  receipt_url_not_available: 'रसीद उपलब्ध नाही कृपया नंतर प्रयत्न करा',
  DonorName: 'दाता नाव :',
  SelectBackground: 'पार्श्वभूमी निवडा',

  Subcategory: 'उपवर्ग',

  newUpdateAvailableText: 'नवीन अद्यतन उपलब्ध आहे',
  newUpdateAvailableMessage:
    'वर्धित अनुभवासाठी कृपया आमचे अ‍ॅप अपडेट करा! ही आवृत्ती आता समर्थित नाही.',
  updateNow: 'आता अपडेट करा',
  notNow: 'आता नाही',

  verificationEmailHasBeenSentToYourEmail:
    'तुमच्या नोंदणीकृत ईमेलवर पडताळणी ईमेल पाठविण्यात आले आहे',
};
