import React, { useEffect, useMemo, useState } from "react";

import { useQuery } from "@tanstack/react-query";
import he from "he";
import { Col, Container, Row, Spinner } from "reactstrap";
import Swal from "sweetalert2";

import { getDetails } from "../apis/getDetails";
import uploadedPdfIcon from "../assets/iconUploadedPdf.svg";
import ErrorImage from "../assets/icons/error.svg";
import placeholderImage from "../assets/logo/main-logo.png";
import Footer from "../components/footer";
import Nav from "../components/navbar";
import { ConvertFirstLatterToCapital } from "../utility";

import "animate.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-toastify/dist/ReactToastify.css";
import "../App.css";
import { useLocation } from "react-router-dom";
import { Carousel, Image } from "antd";
import { fetchImage } from "../utility/commonDownloadS3Image";
import NewsCarousel from "../utility/commonCarousle";
import { getAllNews } from "../utility/commonApi";
import GoogleMapWidget from "./GoogleMapWidget";

export const CenteredBox = ({ children, height }) => {
  return (
    <div
      style={{ height: height }}
      className="d-flex align-items-center justify-content-center"
    >
      {children}
    </div>
  );
};
const scrollToSection = (hash) => {
  if (hash) {
    const element = document.getElementById(hash.replace("#", ""));
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  }
};
const IntroPage = () => {
  const subDomain = window.location.host.replace(
    process.env.REACT_APP_SUBDOMAIN_REPLACE_VALUE,
    ""
  );
  const location = useLocation();

  const { data, isFetching } = useQuery({
    queryKey: ["details"],
    queryFn: () => getDetails(subDomain),
  });

  const trustDetails = useMemo(() => data?.data?.result ?? null, [data]);
  useEffect(() => {
    if (trustDetails) {
      localStorage.setItem("trustId", trustDetails.id);
    }
  }, [trustDetails]);

  useEffect(() => {
    // Ensure smooth scrolling when navigating to `/`
    if (location.pathname === "/") {
      if (location.hash) {
        // Scroll to a specific section if a hash is present
        scrollToSection(location.hash);
      } else {
        // Scroll to the section with the class `bg_background_intro`
        const targetElement = document.querySelector(".bg_background_intro");
        if (targetElement) {
          targetElement.scrollIntoView({ behavior: "smooth" });
        }
      }
    }
  }, [location.pathname, location.hash]);
  const [imageUrls, setImageUrls] = useState([]);
  useEffect(() => {
    if (
      trustDetails &&
      Array.isArray(trustDetails.heroImages) &&
      trustDetails.heroImages.length > 0
    ) {
      const loadImages = async () => {
        const urls = await Promise.all(
          trustDetails.heroImages.map(async (item) => {
            const url = await fetchImage(item);
            return url;
          })
        );
        setImageUrls(urls);
      };

      loadImages();
    }
  }, [trustDetails]);
  const { data: newsData } = useQuery({
    queryKey: ["newsData"],
    queryFn: () => getAllNews(),
  });
  return (
    <div className="overflow-hidden">
      {isFetching ? (
        <CenteredBox height="100dvh">
          <Spinner
            style={{ height: "3rem ", width: "3rem", color: "#ff8744" }}
          />
        </CenteredBox>
      ) : !trustDetails ? (
        <CenteredBox height="100dvh">
          <div className="w-50 text-center">
            <img src={ErrorImage} className="technical-error-image" alt="" />
            <p className="technical-error-text">
              Please check if URL is correct, If this error persists, please
              contact to{" "}
              <a
                href="mailto:support@apnadharm.com"
                style={{ color: "#ff8744" }}
              >
                support@apnadharm.com
              </a>
            </p>
          </div>
        </CenteredBox>
      ) : (
        <>
          <Nav />

          <div className="main-layout hide-scrollbar">
            <Container>
              <Carousel autoplay>
                {imageUrls && imageUrls.length > 0 ? (
                  imageUrls.map((imageUrl, index) => (
                    <div key={index} className="bg_background_intro">
                      <img
                        src={imageUrl}
                        style={{
                          width: "100%",
                          height: "100%",
                          borderRadius: "10px",
                          objectPosition: "center",
                          objectFit: "cover",
                        }}
                        alt=""
                      />
                    </div>
                  ))
                ) : (
                  <div className="bg_background_intro">
                    <img
                      src={
                        trustDetails?.profilePhoto?.length
                          ? trustDetails?.profilePhoto
                          : placeholderImage
                      }
                      style={{
                        width: "100%",
                        height: "100%",
                        borderRadius: "10px",
                        objectPosition: "top center",
                        objectFit: "cover",
                      }}
                    />
                  </div>
                )}
              </Carousel>
              <div>
                <div className="gold">
                  {ConvertFirstLatterToCapital(trustDetails?.name ?? "")}
                </div>
                <div className="row align-items-center">
                  <Col lg={12} xs={8}>
                    <div className="temple_location">
                      {ConvertFirstLatterToCapital(
                        trustDetails?.location ?? ""
                      )}
                    </div>
                  </Col>
                </div>
                {/* <div className="div_size pb-0" id="about">
                  <div className="heading_div">About Us</div>
                  <div className="bg_sub_title">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: he.decode(trustDetails?.about ?? ""),
                      }}
                    />
                  </div>
                </div> */}
                {/* {trustDetails?.images?.length !== 0 ? (
                  <div className="div_size pb-0" id="galleries">
                    <div className="heading_div">Images</div>
                    <Row className="flex-nowrap overflow-auto imagesScroller">
                      {trustDetails?.images?.map((item, idx) => {
                        return (
                          <Col xs={6} sm={3} key={idx}>
                            <div className="trust_img mt-4 mb-2">
                              <Image
                                src={item?.presignedUrl}
                                alt="Images"
                                width={320}
                                height={180}
                              />
                            </div>
                          </Col>
                        );
                      })}
                    </Row>
                  </div>
                ) : (
                  ""
                )} */}
                {/* trust Documents */}
                {trustDetails?.documents?.length !== 0 ? (
                  <div className="div_size pb-0">
                    <div className="heading_div">Documents</div>
                    <Row className="">
                      {trustDetails?.documents?.map((item, idx) => {
                        return (
                          <Col
                            lg={2}
                            md={4}
                            sm={6}
                            key={idx}
                            className="text-center"
                          >
                            <a
                              href={item?.presignedUrl}
                              target="_blank"
                              rel="noreferrer"
                              className="text-decoration-none"
                            >
                              <div className="">
                                <img src={uploadedPdfIcon} width={50} alt="" />
                              </div>
                              <div className="docFileName mt-2">
                                {item?.name}
                              </div>
                            </a>
                          </Col>
                        );
                      })}
                    </Row>
                  </div>
                ) : (
                  ""
                )}
                {newsData?.data?.results?.length > 0 && (
                  <div style={{ width: "100%" }}>
                    <div className="div_size">
                      <div className="heading_div">Latest Updates</div>
                      <NewsCarousel newsData={newsData.data.results} />
                    </div>
                  </div>
                )}

                {trustDetails?.facilities?.length !== 0 ? (
                  <div className="div_size" id="facilities">
                    <div className="heading_div">Facilities</div>
                    <Row>
                      {trustDetails?.facilities?.map((item, idx) => {
                        return (
                          <Col lg={3} md={4} sm={6} key={idx}>
                            <div className="trust_img">
                              <img src={item?.image} alt="" />
                            </div>
                            <div className="py-1">
                              <div className="temple_name">
                                {ConvertFirstLatterToCapital(item?.name ?? "")}
                              </div>
                              {item?.startTime && item?.endTime && (
                                <div className="temple_time">
                                  Timings : {item?.startTime} to {item?.endTime}
                                </div>
                              )}
                            </div>
                          </Col>
                        );
                      })}
                    </Row>
                  </div>
                ) : (
                  ""
                )}
              </div>
              <GoogleMapWidget/>
            </Container>
            <Footer />
          </div>
        </>
      )}
    </div>
  );
};

export default IntroPage;
