import React from "react";
import { Container } from "reactstrap";
import Wrapper from "../common/commonWrapper";
import { useSelector } from "react-redux";

const PrivacyPolicy = () => {
  const userDetails = useSelector((state) => state.auth);
  const trustData = userDetails ? userDetails?.trustData : null;

  return (
    <Wrapper>
      <Container className="privacy_policy">
        <p>
          This privacy policy ("Policy") relates to the manner{" "}
          {trustData?.name ?? ""} ("we", "us", "our") in which we use, handle,
          and process the data that you provide us in connection with using the
          products or services we offer. This website and the associated IT and
          payment solutions are enabled by DHARM PRABHAVNA TECHNOLOGIES LLP ("IT
          Provider"). By using this website, availing goods or services offered
          by us, or using services facilitated by our IT Provider, you agree to
          the terms and conditions of this Policy and consent to our use,
          storage, disclosure, and transfer of your information or data in the
          manner described in this Policy.
        </p>
        <p>
          We are committed to ensuring that your privacy is protected in
          accordance with applicable laws and regulations. We urge you to
          acquaint yourself with this Policy to familiarize yourself with the
          manner in which your data is being handled by us.
        </p>

        <p>
          {trustData?.name ?? ""} may change this Policy periodically, and we
          urge you to check this page for the latest version of the Policy in
          order to keep yourself updated.
        </p>

        <h3>What Data is Being Collected</h3>

        <p>We may collect the following information from you:</p>

        <ul>
          <li>Name</li>
          <li>Contact information including address and email address</li>
          <li>Demographic information or, preferences or interests</li>
          <li>
            Personal Data or Other information relevant/ required for providing
            the goods or services to you
          </li>
          <li>
            Any additional data that may be collected through IT-enabled
            solutions provided by our IT Provider
          </li>
        </ul>
        <p>
          The meaning of Personal Data will be as defined under relevant Indian
          laws.
        </p>

        <p>
          <b>Note: </b>
          Notwithstanding anything under this Policy, as required under
          applicable Indian laws, we will not store any credit card, debit card,
          or any other similar card data of yours. Please also note that all
          data or information collected from you will be strictly in accordance
          with applicable laws and guidelines.
        </p>

        <h3>What We Do with the Data We Gather</h3>

        <p>
          We require this data to provide you with the goods or services offered
          by us, including but not limited to the following purposes:
        </p>

        <ul>
          <li>Internal record keeping.</li>
          <li>Improving our products or services.</li>
          <li>
            Providing updates to you regarding our products or services
            including any special offers.
          </li>
          <li>Communicating information to you.</li>
          <li>Internal training and quality assurance purposes</li>
          <li>
            Facilitating services through our IT Provider, including IT and
            payment processing solutions.
          </li>
        </ul>

        <h3>Who Do We Share Your Data With</h3>

        <p>We may share your information or data with:</p>

        <ul>
          <li>
            Third parties, including our service providers, to facilitate the
            provision of goods or services to you, carry out your requests,
            respond to your queries, fulfill your orders, or for other
            operational and business reasons.
          </li>
          <li>
            Our IT Provider (DHARM PRABHAVNA TECHNOLOGIES LLP) to enable IT and
            payment processing services for our operations.
          </li>
          <li>Our group companies (to the extent relevant).</li>
          <li>
            Our auditors or advisors to the extent required by them for
            performing their services.
          </li>
          <li>
            Governmental bodies, regulatory authorities, or law enforcement
            authorities pursuant to our legal obligations or compliance
            requirements.
          </li>
        </ul>

        <h3>How We Use Cookies</h3>
        <p>
          We use "cookies" to collect information and to better understand
          customer behavior. You can instruct your browser to refuse all cookies
          or to indicate when a cookie is being sent. However, if you do not
          accept cookies, you may not be able to avail our goods or services to
          the full extent. We do not control the use of cookies by third
          parties. The third-party service providers have their own privacy
          policies addressing how they use such information.
        </p>

        <h3>Your Rights Relating to Your Data</h3>
        <p>
          <b>Right to Review:</b>You can review the data provided by you and can
          request us to correct or amend such data (to the extent feasible, as
          determined by us). However, we will not be responsible for the
          authenticity of the data or information provided by you.
          <br />
          <b>Withdrawal of Your Consent:</b>You can choose not to provide your
          data at any time while availing our goods or services or otherwise
          withdraw your consent provided to us earlier, in writing to our email
          ID: [Insert Email]. In the event you choose not to provide or later
          withdraw your consent, we may not be able to provide you with our
          services or goods. Please note that these rights are subject to our
          compliance with applicable laws.
        </p>

        <h3>How Long Will We Retain Your Information or Data?</h3>
        <p>
          We may retain your information or data (i) for as long as we are
          providing goods and services to you; and (ii) as permitted under
          applicable law, we may also retain your data or information even after
          you terminate the business relationship with us. However, we will
          process such information or data in accordance with applicable laws
          and this Policy.
        </p>

        <h3>Data Security</h3>
        <p>
          We will use commercially reasonable and legally required precautions
          to preserve the integrity and security of your information and data.
        </p>

        <h3>Queries/ Grievance Officer</h3>
        <p>
          For any queries, questions, or grievances about this Policy, please
          contact us using the contact information provided on this website.
        </p>
      </Container>
    </Wrapper>
  );
};

export default PrivacyPolicy;
